import React from "react"
import { createContext, useReducer, useEffect } from "react"


// context for login credentials across website

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch(action.type) {
        case 'LOGIN':
            return { user: action.payload }
        case 'LOGOUT':
            return { user: null }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null
    })

    useEffect(() => {
        let user

        let cookies = document.cookie.split(';')
        cookies.forEach(cookie => {
            if (cookie.includes('user=')) {
                user = JSON.parse(cookie.split('=')[1])
            }
        })

        if (user) {
            // need to verify token here by making a request to the server

            dispatch({type: 'LOGIN', payload: user})
        }
    }, [])

    console.log('AuthContext state: ', state)

    return (
        <AuthContext.Provider value={{...state, dispatch}}>
            { children }
        </AuthContext.Provider>
    )
}