import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import GetAddressFooter from "../components/GetAddressFooter";
import AddPackageDetailsRates from "../components/AccountComponents/MyPackagesComponents/AddPackageDetailsRates";
import { isAlphanumericSpecial } from "../hooks/functions/RegexCheck";
import FedEx_Logo from '../assets/FedEx_Logo.png';
import DHL_Logo from '../assets/DHL_Logo.png';
import '../pages/pages-css/Rates.css'; // Ensure this path is correct

const Rates = () => {
    const { t, i18n } = useTranslation();

    const [state, setState] = useState({
        items: [],
        total: 0,
        number_of_parcels: 1,
        total_weight: 0,
        item_dimensions: [{
            dimensions: {
                length: '',
                width: '',
                height: '',
                weight: '',
            },
        }],
        dimensions_required: true,
    });

    const [selectedCountry, setSelectedCountry] = useState('');
    const [step, setStep] = useState(1);
    const [ratesResult, setRatesResult] = useState({});
    const [isLaptop, setIsLaptop] = useState(window.innerWidth > 768);
    const [parcelError, setParcelError] = useState("");
    const [totalWeight, setTotalWeight] = useState(0);
    const [itemDimensions, setItemDimensions] = useState(state.item_dimensions);
    const [itemName, setItemName] = useState("");
    const [botAnswer, setBotAnswer] = useState("");
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const countries = [
        { value: "Saudi Arabia", label: "Saudi Arabia (المملكة العربية السعودية)" },
        { value: "United Arab Emirates", label: "United Arab Emirates (الإمارات العربية المتحدة)" },
        { value: "Kuwait", label: "Kuwait (الكويت)" },
        { value: "Oman", label: "Oman (عمان)" },
        { value: "Qatar", label: "Qatar (قطر)" },
        { value: "Bahrain", label: "Bahrain (البحرين)" }
    ];

    const rates = {
        "Saudi Arabia": {
            "FedEx Priority": { base: 32, additional: 12 },
            "FedEx Economy": { base: 22, additional: 9.5 },
            "DHL Express": { base: 18, additional: 6.5 }
        },
        "United Arab Emirates": {
            "FedEx Priority": { base: 34, additional: 13 },
            "FedEx Economy": { base: 24, additional: 10 },
            "DHL Express": { base: 19, additional: 7 }
        },
        "Kuwait": {
            "FedEx Priority": { base: 35, additional: 13.5 },
            "FedEx Economy": { base: 24.5, additional: 10.75 },
            "DHL Express": { base: 19.5, additional: 7.25 }
        },
        "Oman": {
            "FedEx Priority": { base: 36, additional: 14 },
            "FedEx Economy": { base: 25, additional: 11 },
            "DHL Express": { base: 20, additional: 7.5 }
        },
        "Qatar": {
            "FedEx Priority": { base: 32, additional: 12.5 },
            "FedEx Economy": { base: 22, additional: 9.75 },
            "DHL Express": { base: 18, additional: 6.75 }
        },
        "Bahrain": {
            "FedEx Priority": { base: 33, additional: 12 },
            "FedEx Economy": { base: 23, additional: 10 },
            "DHL Express": { base: 19, additional: 7 }
        }
    };

    const handlePackageChange = (input, value, index) => {
        const newDimensions = [...state.item_dimensions];
        newDimensions[index].dimensions[input] = value;
        setState({
            ...state,
            item_dimensions: newDimensions
        });
    };

    const handleChangeNumberOfParcels = (e) => {
        const regex = /^[0-9]+$/;
        if (regex.test(e.target.value)) {
            const numberOfParcels = parseInt(e.target.value);
            const item_dimensions = [...state.item_dimensions];

            if (numberOfParcels > item_dimensions.length) {
                for (let i = item_dimensions.length; i < numberOfParcels; i++) {
                    item_dimensions.push({
                        dimensions: {
                            length: '',
                            width: '',
                            height: '',
                            weight: '',
                        },
                    });
                }
            } else {
                item_dimensions.splice(numberOfParcels, item_dimensions.length - numberOfParcels);
            }

            setState({
                ...state,
                number_of_parcels: numberOfParcels,
                item_dimensions
            });
        } else {
            setParcelError(t("enter_valid_number"));
        }
    };

    const calculateWeight = () => {
        let weight = 0;
        state.item_dimensions.forEach(item => {
            weight += parseFloat(item.dimensions.weight || 0);
        });
        setTotalWeight(weight);
    };

    const calculatePrice = () => {
        let total = 0;
        const weight = totalWeight;
        const newRatesResult = {};

        if (selectedCountry && rates[selectedCountry]) {
            const countryRates = rates[selectedCountry];
            Object.keys(countryRates).forEach(service => {
                const rate = countryRates[service];
                const cost = rate.base + (rate.additional * (weight - 1));
                newRatesResult[service] = cost;
                total += cost;
            });
        }
        total = Math.round(total * 100) / 100;
        setState({ ...state, total });
        setRatesResult(newRatesResult);
        return total;
    };

    const askItemDimensions = async (e) => {
        e.preventDefault();

        if (!isAlphanumericSpecial(itemName)) {
            alert('Please enter a valid item name');
            return;
        }
        if (itemName === "") {
            alert('Please enter a valid item name');
            return;
        }

        const response = await fetch('https://www.luminous.delivery/api/openai/dimensions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: itemName,
                language: i18n.language // Include the current language in the request
            })
        });

        const json = await response.json();

        if (response.ok) {
            setBotAnswer(json.bot);
        } else {
            setBotAnswer("Error");
        }
    };

    const fillItemDimensions = (index) => {
        const containsNumbers = (string) => /[0-9]/.test(string);

        if (botAnswer === "" || botAnswer === "Error" || !containsNumbers(botAnswer)) {
            alert(t('could_not_fill_dimensions'));
            return;
        }

        const foundDimensions = [];
        const item = state.item_dimensions[index];
        let dimensionIndex = 0;
        for (let i = 0; i < botAnswer.length; i++) {
            const char = botAnswer[i];
            if (char === 'c' && botAnswer[i + 1] === 'm') {
                let number = "";
                for (let j = i - 2; j >= 0; j--) {
                    const char2 = botAnswer[j];
                    if (char2 === ' ') {
                        break;
                    }
                    number = char2 + number;
                }
                if (number !== "") {
                    foundDimensions[dimensionIndex] = parseFloat(number);
                    dimensionIndex++;
                }
            } else if (char === 'k' && botAnswer[i + 1] === 'g') {
                let number = "";
                for (let j = i - 2; j >= 0; j--) {
                    const char2 = botAnswer[j];
                    if (char2 === ' ') {
                        break;
                    }
                    number = char2 + number;
                }
                if (number !== "") {
                    item.dimensions.weight = parseFloat(number);
                }
            }
        }
        item.dimensions.width = foundDimensions[0];
        item.dimensions.length = foundDimensions[1];
        item.dimensions.height = foundDimensions[2];

        setState({ ...state, item_dimensions: [...state.item_dimensions] });
        calculateWeight();
    };

    const nextStep = () => {
        if (step === 1) {
            calculateWeight();
        }
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let errorFlag = false;
        state.item_dimensions.forEach(item => {
            if (!item.dimensions.weight || !item.dimensions.height || !item.dimensions.width || !item.dimensions.length) {
                errorFlag = true;
            }
        });

        if (!errorFlag) {
            calculatePrice();
            nextStep();
            setOpen(true);
        } else {
            alert(t("fill_all_fields"));
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsLaptop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80vw',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleFillClick = () => {
        if (state.number_of_parcels > 1) {
            setModalOpen(true);
        } else {
            fillItemDimensions(0);
        }
    };

    const handleParcelSelect = (index) => {
        fillItemDimensions(index);
        setModalOpen(false);
    };

    return (
        <div>
            <Navbar />
            <div className='choosedimensions'>
                {step === 1 && (
                    <div>
                        <h1>{t('package_details')}</h1>
                        <form className='cdform'>
                            <label>{t('number_of_parcels')}</label>
                            <br />
                            <input
                                type="number"
                                name="parcels"
                                placeholder={t("number_of_parcels_placeholder")}
                                value={state.number_of_parcels}
                                onChange={handleChangeNumberOfParcels}
                            />
                            {parcelError && <div>{parcelError}</div>}
                        </form>

                        <div>
                            <label>{t('select_country')}</label>
                            <select value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
                                <option value="">{t('select_a_country')}</option>
                                {countries.map(country => (
                                    <option key={country.value} value={country.value}>{country.label}</option>
                                ))}
                            </select>
                        </div>
                        <button className='cdrightbtn' onClick={nextStep}>{t('next')}</button>
                    </div>
                )}

                {step === 2 && (
                    <div>
                        <h1>{t("ask_us_dimension_weight")}</h1>
                        <form>
                            <label>{t("what_is_your_item_name")}</label>
                            <br />
                            <input
                                type="text"
                                name="item_name"
                                placeholder={t("item_name_placeholder")}
                                onChange={(e) => setItemName(e.target.value)}
                            />
                            <button className='cdask' onClick={askItemDimensions}>{t("ask_our_ai")}</button>
                        </form>
                        <div>{botAnswer}</div>
                        <button className='cdask' onClick={handleFillClick}>{t("fill_form")}</button>

                        <div>
                            {state.number_of_parcels > 0 && (
                                <div>
                                    {state.item_dimensions.map((item, index) => (
                                        <AddPackageDetailsRates
                                            key={index}
                                            index={index}
                                            state={state}
                                            handleChange={(input, value) => handlePackageChange(input, value, index)}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>

                        <button className='cdleftbtn' onClick={prevStep}>{t('back')}</button>
                        <button className='cdrightbtn' onClick={handleSubmit}>{t('see_rates')}</button>
                    </div>
                )}

                {step >= 3 && (
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={() => { setOpen(false); setStep(1); }}
                        closeAfterTransition
                    >
                        <Fade in={open}>
                            <Box sx={{ ...style, width: isLaptop ? '60vw' : '80vw' }}>
                                {step === 3 && (
                                    <>
                                        <h1>{t('confirm_number_of_packages')}</h1>
                                        <p>{t('number_of_packages')}: {state.number_of_parcels}</p>
                                        <p>{t('total_weight')}: {totalWeight} {t('kg')}</p>
                                        <button className='cdleftbtn' onClick={prevStep}>{t('back')}</button>
                                        <button className='cdrightbtn' onClick={nextStep}>{t('next')}</button>
                                    </>
                                )}
                                {step === 4 && (
                                    <>
                                        <div>
                                            <img src={FedEx_Logo} alt="FedEx Logo" style={{ width: isLaptop ? '80px' : '60px', height: 'auto' }} />
                                            <p>{t('FedEx Priority')}</p>
                                            <ul>
                                                <li>{t('total')}: {ratesResult['FedEx Priority']} USD</li>
                                                <li>{t('3-6 days')}</li>
                                                <li>{t('door_to_door_tracking')}</li>
                                                <li>{t('weight_limit_68kg')}</li>
                                            </ul>
                                        </div>
                                        <button className='cdleftbtn' onClick={prevStep}>{t('back')}</button>
                                        <button className='cdrightbtn' onClick={nextStep}>{t('next')}</button>
                                    </>
                                )}
                                {step === 5 && (
                                    <>
                                        <div>
                                            <img src={FedEx_Logo} alt="FedEx Logo" style={{ width: isLaptop ? '80px' : '60px', height: 'auto' }} />
                                            <p>{t('FedEx Economy')}</p>
                                            <ul>
                                                <li>{t('total')}: {ratesResult['FedEx Economy']} USD</li>
                                                <li>{t('5-9 days')}</li>
                                                <li>{t('door_to_door_tracking')}</li>
                                                <li>{t('weight_limit_68kg')}</li>
                                            </ul>
                                        </div>
                                        <button className='cdleftbtn' onClick={prevStep}>{t('back')}</button>
                                        <button className='cdrightbtn' onClick={nextStep}>{t('next')}</button>
                                    </>
                                )}
                                {step === 6 && (
                                    <>
                                        <div>
                                            <img src={DHL_Logo} alt="DHL Logo" style={{ width: isLaptop ? '100px' : '80px', height: 'auto' }} />
                                            <p>{t('DHL Express')}</p>
                                            <ul>
                                                <li>{t('total')}: {ratesResult['DHL Express']} USD</li>
                                                <li>{t('2-5 days')}</li>
                                                <li>{t('door_to_door_tracking')}</li>
                                            </ul>
                                        </div>
                                        <button className='cdleftbtn' onClick={prevStep}>{t('back')}</button>
                                        <button className='cdrightbtn' onClick={() => { setOpen(false); setStep(1); }}>{i18n.language === 'ar' ? 'تم' : 'Done'}</button>
                                    </>
                                )}
                            </Box>
                        </Fade>
                    </Modal>
                )}
            </div>
            <GetAddressFooter />

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="select-parcel-title"
                aria-describedby="select-parcel-description"
            >
                <Box className="select-parcel-modal">
                    <h2 id="select-parcel-title">{t('select_parcel_to_fill')}</h2>
                    {state.item_dimensions.map((_, index) => (
                        <button key={index} onClick={() => handleParcelSelect(index)}>
                            {t('parcel')} {index + 1}
                        </button>
                    ))}
                </Box>
            </Modal>
        </div>
    );
};

export default Rates;
