import React from 'react'
import {useAuthContext} from "../../hooks/useAuthContext"
import {Navigate, useNavigate} from "react-router-dom"
import {useEffect, useState} from "react"
import Navbar from "../../components/Navbar"
import Select from "react-select"
import {useUpdateOrders} from "../../hooks/useUpdateOrders"
import addressStringSelect from "../../hooks/functions/AddressStringSelect"
import CardDetails from "../../components/AccountComponents/CardDetails"
import OrderDetails from "../../components/AccountComponents/OrderDetails"
import cardStringSelect from "../../hooks/functions/CardStringSelect"
import Checkout from "../../components/AccountComponents/MyPackagesComponents/Checkout"
import {loadStripe} from "@stripe/stripe-js"
import {Elements} from "@stripe/react-stripe-js"
import ChooseItems from "../../components/AccountComponents/MyPackagesComponents/ChooseItems"
import ChooseAddress from "../../components/AccountComponents/MyPackagesComponents/ChooseAddress"
import ChooseCard from "../../components/AccountComponents/MyPackagesComponents/ChooseCard"
import ConfirmOrder from "../../components/AccountComponents/MyPackagesComponents/ConfirmOrder"
import {useTranslation} from "react-i18next"
import ChooseDimensions from "../../components/AccountComponents/MyPackagesComponents/ChooseDimensions";
import UploadReceipt from "../../components/AccountComponents/MyPackagesComponents/UploadReceipt";
import './account-css/MyPackages.css'
import './../../components/AccountComponents/MyPackagesComponents/mpcomponents-css/confirmorder.css'


// user chooses items (and uploads receipts) and creates new order
// that order is logged to database (to compare with warehouse data if not paid immediately)
// the orders that have not been paid are there, the new order is added, all with prices logged
// the number of times the items are ordered are updated
// the user can go and pay the price and then the order is logged as paid
// then, orders that have been paid but not delivered are displayed
// note: if item is not available in our database, ask user to wait? input weight? input name and weight?
// note continued: probably make user wait until order arrives to wh,
// until we can process receipt to add item data to database

// Make sure to call `loadStripe` outside a component’s render to avoid
// recreating the `Stripe` object on every render.
const pk = 'pk_test_51PP2iV04mzujkqL4DUufHEFfMIRt1h7olShU6FgXL9kB8LHpTH4awvPZTASPqSbpTlgKYsVlufcaRye2JYQvD7fM002aW21TLl'
const stripePromise = loadStripe(pk)

// NOTE: MIGHT MAKE THE COMPONENTS INTO PAGES CAUSE OF BROWSER BACK BUTTON
const MyPackages = () => {

    const {user} = useAuthContext()

    const { t } = useTranslation()

    const navigate = useNavigate()

    // separate MyPackages to component steps
    // 1. choose items
    // 2. upload receipts (not yet)
    // 3. choose delivery address
    // 4. choose payment method
    // 5. checkout

    const [state, setState] = useState(
        {
            // some duplicate data here, but it's ok
            step: 1,
            order: null,
            orderid: '',
            receipt: null,
            items: [],
            total: 0,
            address: null,
            card: null,
            number_of_parcels: 1,
            total_weight: 0,
            item_dimensions: [{
                item_name: null,
                dimensions: {
                    length: null,
                    width: null,
                    height: null,
                    weight: null,
                },
                declared_value: null,
            }],
            dimensions_required: true,
        }
    )
    const [step, setStep] = useState(state.step)

    const backToAccount = () => {
        navigate('/account')
    }

    // go back to previous step
    const prevStep = () => {
        const { step } = state
        const nextStep = step - 1
        state.step = nextStep
        setStep(nextStep)
        setState(state)
    }

    // proceed to the next step
    const nextStep = () => {
        const { step } = state
        const nextStep = step + 1
        state.step = nextStep
        setStep(nextStep)
        setState(state)
        console.log(state)
    }

    const gotoStep = (step) => {
        state.step = step
        setStep(step)
        setState(state)
    }

    // Handle fields change
    const handleChange = (input, value) => {
        state[input] = value
        setState(state)
    }

    // destructure state's fields
    const {
        order, orderid, receipt, items,
        total, address, card,
    } = state

    const values = {
        order, orderid, receipt, items,
        total, address, card,
    }


    const [loadedGet, setLoadedGet] = useState(false)


    // addresses to choose which address to deliver package to
    const [addresses, setAddresses] = useState([])
    const [displayAddresses, setDisplayAddresses] = useState([])

    // payment method info, only basic data
    const [cards, setCards] = useState([])
    const [displayCards, setDisplayCards] = useState([])

    // order details
    // orders paid for but not yet delivered
    const [ordersNotDelivered, setOrdersNotDelivered] = useState([])

    // items from db
    const [itemsDB, setItemsDB] = useState(null)

    // get user's packages and parse the addresses and used cards
    useEffect(() => {
        const fetchPackages = async () => {
            const response = await fetch(`https://www.luminous.delivery/api/user/packages/${user.id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                setAddresses(json.addresses)
                const addressStrings = []
                json.addresses.forEach(function (address, index) {
                    const addressDetail = addressStringSelect(address, index)
                    addressStrings.push(addressDetail)
                })
                setDisplayAddresses(addressStrings)

                setCards(json.cardList)
                const cardStrings = []
                json.cardList.forEach(function (card, index) {
                    const cardString = cardStringSelect(card, index)
                    cardStrings.push(cardString)
                })
                setDisplayCards(cardStrings)

                setOrdersNotDelivered(json.ordersNotDelivered)
                setItemsDB(json.items)

                setLoadedGet(true)
            }
        }

        if (user) {
            fetchPackages()
        }
    }, [user])


    if (!user) {
        return (
            <div>
                <Navigate to='/login' replace="true"/>
            </div>
        )
    }


    switch(step) {
        case 1:
            // orders paid for and register order button
            return (
                <>
                <Navbar/>
                <div className='case1box12345'>
                    <br/>
                    <br/>
                    <br/>
                    <h3 className='case1h3'>{t('register_new_order')}</h3>
                    {/*button that goes to next step to start registering order*/}
                    <button id="addpack" onClick={nextStep}>{t('add_package')}</button>

                    <h4>{t('packages_on_the_way')}</h4>
                    <div className='case1orders'>
                    {ordersNotDelivered && ordersNotDelivered.map((order, index) => (
                        <div>
                            <OrderDetails key={order._id} order={order} index={index+1}/>
                        </div>
                    ))}
                    </div>
                    <button
                        className='cobackbtn'
                        onClick={backToAccount}
                    >
                        {t('back')}
                    </button>
                </div>
                </>
            )
        case 2:
            // choose dimensions component
            return (
                <>
                <Navbar/>
                <div className='case2box'>
                <ChooseDimensions
                    steps={gotoStep}
                    handleChange={handleChange}
                    state={state}
                    user={user}
                />
                </div>
                </>
            )
        case 3:
            // choose delivery address component
            return (
                <>
                <Navbar/>
                <div className='case3box'>
                <ChooseAddress
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    state={state}
                    values={values}
                    addresses={addresses}
                    displayAddresses={displayAddresses}
                />
                </div>
                </>
            )
        case 4:
            // confirm order component
            return (
                <>
                <Navbar/>
                <div className='case4box'>
                <ConfirmOrder
                    nextStep={nextStep}
                    prevStep={prevStep}
                    values={values}
                    handleChange={handleChange}
                    state={state}
                />
                </div>
                </>
            )
        case 5:
            // choose payment method component
            return (
                <>
                <Navbar/>
                <div className='case5box'>
                <ChooseCard
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    state={state}
                    values={values}
                    cards={cards}
                    displayCards={displayCards}
                />
                </div>
                </>
            )
        case 6:
            // checkout component
            return (
                <>
                <Navbar/>
                <div className='case6box'>
                    {loadedGet &&
                        <Elements stripe={stripePromise}>
                            <Checkout
                                prevStep={prevStep}
                                state={state}
                                user={user}
                                ordersNotDelivered={ordersNotDelivered}
                                setOrdersNotDelivered={setOrdersNotDelivered}
                            />
                        </Elements>
                    }
                </div>
                </>
            )
        // case 7:
        //     // choose items component
        //     return (
        //         <ChooseItems
        //             steps={gotoStep}
        //             nextStep={nextStep}
        //             handleChange={handleChange}
        //             state={state}
        //             values={values}
        //             items={itemsDB}
        //         />
        //     )
        // case 8:
        //     // upload receipt option
        //     return (
        //         <UploadReceipt
        //             steps={gotoStep}
        //             prevStep={prevStep}
        //             handleChange={handleChange}
        //             state={state}
        //         />
        //     )
        default:
            //
    }
}

export default MyPackages
