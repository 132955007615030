import React, {useRef, useState} from 'react'
import Button from "@mui/material/Button"
import {useLocaleContext} from "../hooks/useLocaleContext"
import i18n from "i18next"
import "./components-css/LanguageMenu.css"
import {useTranslation} from "react-i18next";
import {useOnHoverOutside} from "../hooks/functions/OnHoverOutside";
import {Nav, NavDropdown} from "react-bootstrap";


// languages put here
const LanguageMenu = () => {
    const { locale } = useLocaleContext()
    const { t } = useTranslation()

    // const dropdownRef = useRef(null) // Create a reference for dropdown container
    // const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false)
    //
    // // Function to close dropdown
    // const closeHoverMenu = () => {
    //     setMenuDropDownOpen(false)
    // }
    //
    // useOnHoverOutside(dropdownRef, closeHoverMenu) // Call the hook

    const changeLocale = (lng) => {
        if (locale !== lng) {
            i18n.changeLanguage(lng)
        }
    }

    return (
        <div className={locale === 'ar' ? 'me-auto' : 'ms-auto'}>
            <div className="dropdown">
                <button className="dropbtn">
                    <span className="dropdown-menu-text">{t('language')}</span>
                </button>
                <div className="dropdown-content">
                    {
                        <div>
                            {locale !== 'en' &&
                                <NavDropdown.Item
                                    className="dropdown-menu-content"
                                    href="#"
                                    onClick={() => changeLocale('en')}
                                >
                                    English
                                </NavDropdown.Item>
                            }
                            {locale !== 'ar' &&
                                <NavDropdown.Item
                                    className="dropdown-menu-content"
                                    href="#"
                                    onClick={() => changeLocale('ar')}
                                >
                                    العربية
                                </NavDropdown.Item>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
        // <div className="dropdown-menu">
        //     <Nav className={locale === 'ar' ? 'me-auto' : 'ms-auto'}>
        //         <NavDropdown
        //             onMouseOver={() => setMenuDropDownOpen(true)}
        //             title={
        //                 <span className="dropdown-menu-text">{t('language')}</span>
        //             }
        //             id="basic-nav-dropdown"
        //         >
        //             {<div>
        //                 {locale !== 'en' &&
        //                     <NavDropdown.Item
        //                         className="dropdown-menu-content"
        //                         href="#"
        //                         onClick={() => changeLocale('en')}
        //                     >
        //                         English
        //                     </NavDropdown.Item>
        //                 }
        //                 {locale !== 'ar' &&
        //                     <NavDropdown.Item
        //                         className="dropdown-menu-content"
        //                         href="#"
        //                         onClick={() => changeLocale('ar')}
        //                     >
        //                         العربية
        //                     </NavDropdown.Item>
        //                 }
        //             </div>
        //             }
        //         </NavDropdown>
        //     </Nav>
        // </div>
    )
}

export default LanguageMenu