import React from 'react'
import AddressDetails from "../AddressDetails"
import OrderDetails from "../OrderDetails"
import {useEffect, useState} from "react"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
// react-bootstrap also has a spinner lol
import {Spinner} from "react-spinners-css"
import {useTranslation} from "react-i18next";
import './mpcomponents-css/confirmorder.css'

const ConfirmOrder = ({ prevStep, nextStep, handleChange, state, values }) => {

    // new logic, dont create order until it is paid!!

    // NOT USED HERE!!
    // to update orders -> used to create new order
    // const {updateOrders, error, isLoading} = useUpdateOrders()

    const { t } = useTranslation()

    const [loadingTotal, setLoadingTotal] = useState(true)

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    useEffect(() => {
        // after 1.2-2.5 seconds, set loading to false
        setTimeout(() => {
            setLoadingTotal(false)
        }, getRndInteger(1200, 2500))
    }, [])


    // create order object to display
    const itemNames = []
    if (!state.dimensions_required) {
        state.items.forEach(function (item) {
            itemNames.push({name: item.label, quantity: item.quantity, item_id: item._id})
        })
    }

    const order = {
        // not showing paid, delivered, card id or stripe id fields here
        itemNames,
        price: state.total,
        address: state.address,
        createdAt: (new Date()).toDateString()
    }

    if (loadingTotal) {
        return (
            // popup spinner that is displayed in a modal
            <div className='TODO CIRCLY THING STYLE'>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={loadingTotal}
                >
                    <Box>
                        {/*loading spinner / roller*/}
                        <Spinner color="black" />
                    </Box>
                </Modal>
            </div>
        )
    }
    else {
        return (
            <div>
                <OrderDetails order={order} priceBool={true} />
                <h2>{t('total')}: {Math.round(order.price * 100) / 100.0} USD</h2>
                {/*back and next buttons*/}
                <button className='confirmorderbtn'
                    onClick={() => {handleChange('order', order); nextStep()}}
                >
                    {t('confirm_and_continue')}
                </button>
                <br/>
                <button
                className='cobackbtn'
                    onClick={prevStep}
                >
                    {t('back')}
                </button>
                <h5>{t('note_order_payment')}</h5>
            </div>
        )
    }
}

export default ConfirmOrder