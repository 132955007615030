import React from "react"
import { createContext, useReducer, useEffect } from "react"


// for demo purposes

export const AccessAuthContext = createContext()

export const accessAuthReducer = (state, action) => {
    switch(action.type) {
        case 'ACCESS':
            return { demo: action.payload }
        default:
            return state
    }
}

export const AccessAuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accessAuthReducer, {
        demo: null
    })

    useEffect(() => {
        let demo

        // search for demo in session storage
        if (sessionStorage.getItem('demo')) {
            demo = JSON.parse(sessionStorage.getItem('demo'))
        }

        if (demo) {
            dispatch({type: 'ACCESS', payload: demo})
        }
    }, [])

    console.log('AccessAuthContext state: ', state)

    return (
        <AccessAuthContext.Provider value={{...state, dispatch}}>
            { children }
        </AccessAuthContext.Provider>
    )
}