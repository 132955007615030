import LocaleContext from "../context/LocaleContext"
import { useContext } from "react"

export const useLocaleContext = () => {
    const context = useContext(LocaleContext)

    if (!context) {
        throw Error('useLocaleContext must be used inside a LocaleContextProvider')
    }

    return context
}