import React, {useState} from "react"
import './SignupComponents-css/Signup.css'
import Navbar from '../Navbar'
// import validator from 'validator'
import { isAlphaSpaces, isMobilePhone, isAlpha } from "../../hooks/functions/RegexCheck";
import {useTranslation} from "react-i18next";
import toast, {Toaster} from "react-hot-toast";
import {Link} from "react-router-dom";
import logo_rtl from "../../assets/logoblackrtl.svg";
import logo from "../../assets/logoblack.svg";
import LanguageMenu from "../LanguageMenu";
import {useLocaleContext} from "../../hooks/useLocaleContext";
import Select from "react-select";
import {areaCodeOptions, areaCodeOptionsAr} from "./AreaCodeOptions"


// enter personal details to signup
const PersonalDetails = ({ prevStep, nextStep, handleChange, values, state }) => {

    // const [areaCode, setAreaCode] = useState("")
    const [menuOpen, setMenuOpen] = useState(false)
    // errors
    const [errors, setErrors] = useState({ name: "", middleName: "", surname: "", mobile: "" })

    const { locale } = useLocaleContext()
    const { t } = useTranslation()

    const areaCodeSelect = locale === 'ar' ? areaCodeOptionsAr : areaCodeOptions

    const Continue = e => {
        e.preventDefault()
        let error = {}

        toast.dismiss()

        if (state.name === "" || state.surname === "" || state.mobile === "" || state.mobileAreaCode === "") {
            toast.error(t('fill_all_fields'))
            return
        }

        // const ignoreSpacesRegex2 = /^[\p{L}\s]+$/u
        const correctName = isAlpha(state.name)
        const correctMiddleName = !state.middleName || isAlphaSpaces(state.middleName)
        const correctSurname = isAlpha(state.surname)
        const correctMobile = isMobilePhone(state.mobile)

        if (correctName && correctMiddleName && correctSurname && correctMobile) {
            // const e = {
            //     target: {
            //         value: areaCode + state.mobile
            //     }
            // }
            // handleChange('mobile')(e)
            nextStep()
        }
        // error checking for entered fields
        else {
            if (!correctName) {
                error.name = "Please enter a valid first name"
                toast.error(t('enter_valid_first_name'))
            }
            if (!correctMiddleName) {
                error.middleName = "Please enter a valid middle name"
                toast.error(t('enter_valid_middle_name'))
            }
            if (!correctSurname) {
                error.surname = "Please enter a valid surname"
                toast.error(t('enter_valid_surname'))
            }
            if (!correctMobile) {
                error.mobile = "Please enter a valid mobile number"
                toast.error(t('enter_valid_mobile'))
            }
            setErrors(error)
        }
    }

    const Previous = e => {
        e.preventDefault()
        prevStep()
    }

    return (
        <>
            <div className='header-signup'>
                <div className='leftItems'>
                    <Link to="/">
                        <img className='logo-login-corner' src={locale === 'ar' ? logo_rtl : logo} />
                    </Link>
                </div>
                <div className='rightItems'>
                    <div className='language-menu-login'>
                        <LanguageMenu />
                    </div>
                    <p className='login-header-text'>{t('already_a_member')}
                        <a href="/login"> {t('log_in')}</a>
                    </p>
                </div>
            </div>
        <div className='signup-div'>
            <div className='login-page'>
                <div className='signup-logo-space'></div>
            <div className='form'>

                <form className='login-form'>
                <h5 className='toptext'>
                    {t('create_an_account')}
                </h5>
                    {/* first name */}
                    <p>{t('first_name')}</p>
                    <input
                        placeholder={t('first_name')}
                        name="name"
                        onChange={handleChange('name')}
                        defaultValue={values.name}
                    />
                    {/*<p className='error'>{errors.name && errors.name}</p>*/}
                    <br/>
                    {/* middle name */}
                    <p>{t('middle_name')}</p>
                    <input
                        placeholder={t('middle_name')}
                        name="middleName"
                        onChange={handleChange('middleName')}
                        defaultValue={values.middleName}
                    />
                    {/*<p className='error'>{errors.middleName && errors.middleName}</p>*/}
                    <br/>
                    {/* last name */}
                    <p>{t('last_name')}</p>
                    <input
                        placeholder={t('last_name')}
                        name="surname"
                        onChange={handleChange('surname')}
                        defaultValue={values.surname}
                    />
                    {/*<p className='error'>{errors.surname && errors.surname}</p>*/}
                    <br/>
                    {/* mobile */}
                    <p>{t('mobile')}</p>
                    {
                        // locale === 'en' ?
                            <span className='mobile-select'>
                                <Select
                                    // isRtl={locale === 'ar'}
                                    styles={{menu: provided => ({ ...provided, width: '300px' })}}
                                    // placeholder={t('area_code')}
                                    placeholder='+'
                                    name="mobileAreaCode"
                                    className='area-code-select'
                                    options={areaCodeSelect}
                                    defaultValue={areaCodeSelect.find(option =>
                                        option.value === values.mobileAreaCode)}
                                    // onChange={handleAreaCode}
                                    onChange={handleChange('mobileAreaCode')}
                                    onMenuOpen={() => setMenuOpen(true)}
                                    onMenuClose={() => setMenuOpen(false)}
                                    getOptionLabel={option => menuOpen ? option.label : '+' + option.value}
                                />
                                <input
                                    placeholder={t('mobile')}
                                    name="mobile"
                                    onChange={handleChange('mobile')}
                                    defaultValue={values.mobile}
                                    type="tel"
                                />
                            </span>
                            // :
                            // <span className='mobile-select'>
                            //     <input
                            //         placeholder={t('mobile')}
                            //         name="mobile"
                            //         onChange={handleChange('mobile')}
                            //         defaultValue={values.mobile}
                            //         type="tel"
                            //     />
                            //     <Select
                            //         isRtl={true}
                            //         styles={{menu: provided => ({ ...provided, width: '300px' })}}
                            //         // placeholder={t('area_code')}
                            //         placeholder='+'
                            //         name="mobileAreaCode"
                            //         className='area-code-select'
                            //         options={locale === 'en' ? areaCodeOptions : areaCodeOptionsAr}
                            //         defaultValue={areaCode}
                            //         onChange={handleAreaCode}
                            //         onMenuOpen={() => setMenuOpen(true)}
                            //         onMenuClose={() => setMenuOpen(false)}
                            //         getOptionLabel={option => menuOpen ? option.label :
                            //             locale === 'en' ? '+' + option.value : option.value + '+'}
                            //     />
                            // </span>
                    }
                    {/*<p className='error'>{errors.mobile && errors.mobile}</p>*/}

                    <div className='buttonsbox'>
                    <button className='b1'
                        onClick={ Previous }
                        type="submit"
                    >
                        {t('previous')}
                    </button>
                    <button className='b2'
                        onClick={ Continue }
                        type="submit"
                    >
                        {t('next')}
                    </button>
                    </div>
                </form>
                </div>
            </div>
            </div>
            <Toaster />
            </>
    )
}

export default PersonalDetails