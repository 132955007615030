import { useState } from "react"

export const useUpdatePassword = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)

    const updatePassword = async (id, password) => {
        setIsLoading(true)
        setError(null)

        // send server request to update password
        const response = await fetch('https://www.luminous.delivery/api/user/update/password', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id, password})
        })
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }
        if (response.ok) {
            setIsLoading(false)
        }
    }

    return { updatePassword, error, isLoading }
}