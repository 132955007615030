import React from 'react'
import Navbar from '../components/Navbar'
import './pages-css/privacy.css'

import GetAddressFooter from "../components/GetAddressFooter";
import {useLocaleContext} from "../hooks/useLocaleContext";

const Privacy = () => {
    const { locale } = useLocaleContext()

    if (locale === 'en') {
        return(
            <>
                <Navbar/>
                <div className='pv'>
                    <h1>Privacy Policy</h1>
                    <p>At Luminous, we care about the privacy of your data and are committed to protecting it. This Privacy Policy explains what information we collect about you and why.</p>
                    <h2>What is the scope of this Privacy Policy?</h2>
                    <p>This Privacy Policy is incorporated into Luminous’ Terms of Service and License Agreement, and applies to the information obtained by us through your use of Luminous’ Site, Software, and Services (“Information”) as described in this Policy. Capitalized terms used in this Policy and not otherwise defined shall have the meanings provided for those terms in the Terms of Service.</p>
                    <h2>Information collection and use</h2>
                    <h3>What Information does Luminous collect about me?</h3>
                    <p>The information we collect about you are divided into two categories: Personal Data and Non-Personal Data. Personal data is the information that could be used to identify you. Non-Personal Data is the information that cannot be linked back to you. We only collect personal data that we need.</p>
                    <h3>How does Luminous use my Information?</h3>
                    <p>Luminous use, process, and store your Information as necessary to power its service. For example, to process transactions, Luminous must collect data such as your name, purchase, and payment information. We also store the information of our users address, so we can ship the packages to their correct destination.</p>
                    <h2>Data security, retention, and deletion</h2>
                    <h3>How secure is my Information?</h3>
                    <p>Luminous is committed to protecting the security of your Information and takes reasonable precautions to protect it. Once we receive your data, we protect it on our servers using a combination of technical, physical, and logical security measures.
                        If Luminous learns of a security system breach, we may attempt to notify you and provide information on protective steps, if available, through the email address that you have provided to us.</p>
                    <h3>How long is Personal Data retained?</h3>
                    <p>You can remove your Personal Data from Luminous at any time by deleting your account as described below. However, we may keep some of your Personal Data for as long as reasonably necessary for our legitimate business interests, including fraud detection and prevention and to comply with our legal obligations including tax, legal reporting, and auditing obligations.</p>
                    <h3>How can I delete my Personal Data from Luminous?</h3>
                    <p>You can remove your Personal Data from Luminous at any time by logging into your account, accessing the Setting page, and then deleting your account.</p>
                    <h2>Cookies</h2>
                    <p>You may be able to disable cookies or other tracking technologies through which we collect information about you by adjusting your browser preferences on your computer; however, doing so may limit your ability to take advantage of all the features of the Website. Luminous does not control the cookies or other tracking technologies used by third parties, including our service providers. You should contact those third parties if you have questions or concerns regarding their use of cookies or other tracking technologies.</p>
                    <h2>Contact Us</h2>
                    <p>If you have any questions regarding this Policy, you may contact us at privacy@luminous.delivery.</p>
                    <p>This Policy is effective and was last updated on February 28, 2024.</p>
                </div>
                <GetAddressFooter/>
            </>
        )
    }
    else if (locale === 'ar') {
        return(
            <>
                <Navbar/>
                <div className='pv'>
                    <h1>سياسة الخصوصية</h1>
                    <p>في لومينوس، نهتم بخصوصية بياناتك ونلتزم بحمايتها. توضح سياسة الخصوصية هذه المعلومات التي نجمعها عنك ولماذا.</p>
                    <h2>ما هو نطاق سياسة الخصوصية هذه؟</h2>
                    <p>تم دمج سياسة الخصوصية هذه في شروط الخدمة واتفاقية الترخيص الخاصة بـ لومينوس، وتنطبق على المعلومات التي حصلنا عليها من خلال استخدامك لموقع لومينوس وبرامجها وخدماتها ("المعلومات") كما هو موضح في هذه السياسة.</p>
                    <h2>جمع المعلومات واستخدامها</h2>
                    <h3>ما هي المعلومات التي تجمعها لومينوس عني؟</h3>
                    <p>المعلومات التي نجمعها عنك مقسمة إلى فئتين:
                        بيانات شخصية
                        البيانات غير الشخصية
                        البيانات الشخصية هي المعلومات التي يمكن استخدامها لتحديد هويتك. البيانات غير الشخصية هي المعلومات التي لا يمكن ربطها بك. نحن نجمع البيانات الشخصية التي نحتاجها فقط.</p>
                    <h3>كيف تستخدم لومينوس معلوماتي؟</h3>
                    <p>لومينوس تستخدم المعلومات الخاصة بك وتعالجها وتخزنها لتشغيل خدمتها عند الضرورة. على سبيل المثال، لمعالجة المعاملات، يجب على لومينوس جمع بيانات مثل اسمك ومعلومات الشراء والدفع. نقوم أيضًا بتخزين معلومات عنوان مستخدمينا، حتى نتمكن من شحن الطرود إلى وجهتها الصحيحة.</p>
                    <h2>أمن البيانات والاحتفاظ بها وحذفها</h2>
                    <h3>ما مدى أمان معلوماتي؟</h3>
                    <p>تلتزم لومينوس بحماية أمان معلوماتك وتتخذ الاحتياطات المعقولة لحمايتها. بمجرد استلامنا لبياناتك، نقوم بحمايتها على خوادمنا باستخدام مجموعة من الإجراءات الأمنية التقنية والمادية والمنطقية.
                        إذا علمت لومينوس بخرق نظام الأمان، فقد نحاول اخطارك وتقديم معلومات حول خطوات الحماية، إن وجدت، من خلال عنوان البريد الإلكتروني الذي قدمته لنا.</p>
                    <h3>ما هي مدة الاحتفاظ بالبيانات الشخصية؟</h3>
                    <p>يمكنك إزالة بياناتك الشخصية من لومينوس في أي وقت عن طريق حذف حسابك كما هو موضح أدناه. ومع ذلك، قد نحتفظ ببعض بياناتك الشخصية طالما كان ذلك ضروريًا بشكل معقول لمصالحنا التجارية المشروعة، بما في ذلك الكشف عن الاحتيال والوقاية منه والامتثال لالتزاماتنا القانونية بما في ذلك التزامات الضرائب والتقارير القانونية والتدقيق.</p>
                    <h3>كيف يمكنني حذف بياناتي الشخصية من لومينوس؟</h3>
                    <p>يمكنك إزالة بياناتك الشخصية من لومينوس في أي وقت عن طريق تسجيل الدخول إلى حسابك، والوصول إلى صفحة الإعدادات، ثم حذف حسابك.</p>
                    <h2>ملفات تعريف الارتباط</h2>
                    <p>قد تتمكن من تعطيل ملفات تعريف الارتباط أو تقنيات التتبع الأخرى التي نجمع من خلالها معلومات عنك عن طريق تعديل تفضيلات المتصفح على جهاز الكمبيوتر الخاص بك؛ ومع ذلك، فإن القيام بذلك قد يحد من قدرتك على الاستفادة من جميع ميزات موقع الويب.
                        لا تتحكم لومينوس في ملفات تعريف الارتباط أو تقنيات التتبع الأخرى التي تستخدمها الجهات الخارجية، بما في ذلك مزودي الخدمة لدينا. يجب عليك الاتصال بهذه الأطراف الثالثة إذا كانت لديك أسئلة أو مخاوف بشأن استخدامها لملفات تعريف الارتباط أو تقنيات التتبع الأخرى.</p>
                    <h2>تواصل معنا</h2>
                    <p>اذا كان لديك أي أسئلة تتعلق بهذه السياسة، يمكنك التواصل معنا على privacy@luminous.delivery.</p>
                    <p>هذه السياسة سارية وتم تحديثها آخر مرة في 28 فبراير 2024.</p>
                </div>
                <GetAddressFooter/>
            </>
        )
    }
}

export default Privacy