import React from "react"
import { faqs } from "./data"
import AccordionItem from "./AccordionItem"
import "./Accordion.css"

const Accordion = ({ data }) => {
  return (
      <div className='accordion-main'>
          <ul className="accordion">
              {data.map((faq, index) => (
                  <AccordionItem key={index} faq={faq} />
              ))}
          </ul>
      </div>
  )
}

export default Accordion