import React from "react"
import {useTranslation} from "react-i18next";


// address info in display format
const AddressDetails = ({ address }) => {
    const { t } = useTranslation()
    return (
        <div className="address-details">
            <p><strong>{t('street_address_1')}: </strong>{ address.address1 }</p>
            {address.address2 && <p><strong>{t('street_address_2')}: </strong>{ address.address2 }</p>}
            <p><strong>{t('city')}: </strong>{ address.city }</p>
            {address.state && <p><strong>{t('state')}: </strong>{ address.state }</p>}
            <p><strong>{t('country')}: </strong>{ address.country }</p>
            <p><strong>{t('postal')}: </strong>{ address.postal }</p>
        </div>
    )
}

export default AddressDetails