// change mobile number
import React from "react"
import {useState} from "react"
import {useUpdateMobile} from "../../../hooks/profileHooks/useUpdateMobile"
import {Navigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import validator from "validator"
import './../../SignupComponents/SignupComponents-css/Signup.css'
import './../../../pages/Account/account-css/account.css'
import './ProfileSettingsComponents-css/ProfileSettingsComponents.css'

// TODO SOME PARTS OF CODE ASSUME MOBILE IS NOT REQUIRED - CLEAR THAT UP

const InfoMobile = ({ id, mobile, prevStep }) => {
    const { t } = useTranslation()

    const [errors, setErrors] = useState({ mobile: "" })

    const {updateMobile, error, isLoading} = useUpdateMobile()

    const [newMobile, setNewMobile] = useState(mobile)
    const [completed, setCompleted] = useState(false)

    const handleChange = e => {
        setNewMobile(e.target.value)
    }

    const update = async (e) => {
        e.preventDefault()
        let error = {}
        const correctMobile = validator.isMobilePhone(newMobile)
        if (correctMobile) {
            // send mobile update request to server
            await updateMobile(id, newMobile)
            setCompleted(true)
        }
        // error checking
        else if (!correctMobile) {
            error.mobile = "Please enter a valid mobile number"
            setErrors(error)
        }
    }

    if (completed) {
        if (error) {
            setCompleted(false)
        }
        else {
            return (
                <div>
                    <Navigate to='/account' replace="false"/>
                </div>
            )
        }
    }

    return (
        <div className='form-container'>
            <br/>
            <form className='login-form'>
                <h5 className='toptext'>
                    {t('mobile')}
                </h5>
                {/* mobile */}
                <input
                    placeholder={t('mobile')}
                    name="mobile"
                    onChange={handleChange}
                    defaultValue={mobile ? mobile : ""}
                />
                <p className='error'>{errors.mobile && errors.mobile}</p>
            </form>
            <div className="buttons-box">
                <button className='change-button'
                        onClick={prevStep}
                        type="submit"
                >
                    {" <  "}
                </button>
                <button className='change-button'
                        disabled={isLoading}
                        onClick={update}
                        type="submit"
                >
                    {t('save')}
                </button>
                {error && <div className="error">{error}</div>}
            </div>
        </div>
    )
}

export default InfoMobile