import { AccessAuthContext } from "../context/AccessAuthContext"
import { useContext } from "react"

export const useAccessAuthContext = () => {
    const context = useContext(AccessAuthContext)

    if (!context) {
        throw Error('useAccessAuthContext must be used inside an AccessAuthContextProvider')
    }

    return context
}