import React from "react"
import './SignupComponents-css/Signup.css'
import Navbar from '../Navbar'

// here, display info input, and then have submit button
// submit button will fire useSignup hook
import { useSignup } from "../../hooks/useSignup"
import {useTranslation} from "react-i18next";
import toast, {Toaster} from "react-hot-toast";
import {Link, useNavigate} from "react-router-dom";
import logo_rtl from "../../assets/logoblackrtl.svg";
import logo from "../../assets/logoblack.svg";
import LanguageMenu from "../LanguageMenu";
import {useLocaleContext} from "../../hooks/useLocaleContext";
import signup_icon from "../../assets/signup_icon.svg";


// display all details entered during signup
const ReviewDetails = ({ prevStep, values }) => {

    const { locale } = useLocaleContext()
    const { t } = useTranslation()

    const navigate = useNavigate()

    const {
        email, password,
        name, surname, middleName, mobile, mobileAreaCode,
        address1, address2, city, country, postal
    } = values

    const displayMobile = mobileAreaCode + mobile

    const load = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    )

    const toastPromise = async () => {
        toast.dismiss()
        toast.success(t('created_account_logging_in'))
        await load(getRndInteger(1000, 2000))
        navigate('/account')
    }

    const {signup, error, isLoading} = useSignup(toastPromise)

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        toast.dismiss()

        try {
            // send the signup request to the server
            await signup(email, password, name, surname, middleName,
                address1, address2, city, /*state,*/ country, postal, displayMobile)
        }
        catch (err) {
            toast.dismiss()
            toast.error(t(err.message))
        }
    }

    const Previous = e => {
        e.preventDefault()
        prevStep()
    }

    return (
        <>
            <div className='header-signup'>
                <div className='leftItems'>
                    <Link to="/">
                        <img className='logo-login-corner' src={locale === 'ar' ? logo_rtl : logo} />
                    </Link>
                </div>
                <div className='rightItems'>
                    <div className='language-menu-login'>
                        <LanguageMenu />
                    </div>
                    <p className='login-header-text'>{t('already_a_member')}
                        <a href="/login"> {t('log_in')}</a>
                    </p>
                </div>
            </div>
        <div className='signup-div'>
        <div className='login-page'>
            <div className='signup-logo-space'></div>
        <div className='form'>
            <div>
                <ul className='reviewdetails-list'>
                <h3>{t('contact_info')}</h3>
                    <li>
                        <span className='address-review-key'>{t('email')}</span>: {email}
                    </li>
                    {/* uppercase? */}
                    <li>
                        <span className='address-review-key'>{t('first_name')}</span>: {name}
                    </li>
                    {middleName &&
                        <li>
                            <span className='address-review-key'>{t('middle_name')}</span>: {middleName}
                        </li>
                    }
                    <li>
                        <span className='address-review-key'>{t('last_name')}</span>: {surname}
                    </li>
                    <li>
                        <span className='address-review-key'>{t('mobile')}</span>
                        : {locale === 'en' ? '+' + displayMobile : displayMobile + '+'}
                    </li>
                    <br />
                <h3>{t('address')}</h3>
                    <li>
                        <span className='address-review-key'>{t('street_address_1')}</span>: {address1}
                    </li>
                    <li>
                        <span className='address-review-key'>{t('street_address_2')}</span>: {address2}
                    </li>
                    <li>
                        <span className='address-review-key'>{t('city')}</span>: {city}
                    </li>
                    <li>
                        <span className='address-review-key'>{t('country')}</span>: {country}
                    </li>
                    <li>
                        <span className='address-review-key'>{t('zipcode')}</span>: {postal}
                    </li>
                </ul>

                <br />
                <div className='buttonsbox'>
                        <button className="b1"
                            onClick={ Previous }
                            type="submit"
                        >
                            {t('previous')}
                        </button>
                        <button className="reviewdetails-b2"
                            onClick={ handleSubmit }
                            type="submit"
                            disabled={isLoading}
                        >
                            {t('sign_up')}
                        </button>
                {/*{error && <div className="error">{error}</div>}*/}
                </div>

                <p className="message">{t('you_agree_to_our')}
                    <a href="/terms"> {t('terms_of_service')}</a> &amp;
                    <a href="/privacy"> {t('privacy_policy')}</a>
                </p>
            </div>
            </div>
            </div>
            </div>
            <Toaster />
            </>
    )
}

export default ReviewDetails