import React, {useState} from "react"
import './SignupComponents-css/Signup.css'
import Navbar from '../Navbar'
// import validator from "validator"
import { isAlphanumericSpaces, isAlphaSpaces } from "../../hooks/functions/RegexCheck";
import {useTranslation} from "react-i18next"
import Select from "react-select";
import toast, {Toaster} from "react-hot-toast";
import {Link} from "react-router-dom";
import logo_rtl from "../../assets/logoblackrtl.svg";
import logo from "../../assets/logoblack.svg";
import LanguageMenu from "../LanguageMenu";
import {useLocaleContext} from "../../hooks/useLocaleContext";

// address1, address2, city, country, postal
const AddressDetails = ({ prevStep, nextStep, handleChange, values, state }) => {

    // errors
    const [errors, setErrors] = useState({ address1: "", address2: "", city: "", country: "", postal: "" })

    const { locale } = useLocaleContext()
    const { t } = useTranslation()

    const Continue = e => {
        e.preventDefault()

        toast.dismiss()

        if (state.address1 === "" || state.city === "" || state.country === "" || state.postal === "") {
            toast.error(t('fill_all_fields'))
            return
        }

        let error = {}
        // latin alphanumeric + arabic alphanumeric + spaces
        // const ignoreSpacesRegex = /^[\p{L}\p{N}\s]+$/u
        // latin alpha + arabic alpha spaces
        // const ignoreSpacesRegex2 = /^[\p{L}\s]+$/u
        const correctAddress1 = isAlphanumericSpaces(state.address1)
        const correctAddress2 = !state.address2 || isAlphanumericSpaces(state.address2)
        const correctCity = isAlphanumericSpaces(state.city)
        const correctCountry = isAlphaSpaces(state.country)
        // const correctPostal = validator.isPostalCode(state.postal)
        const correctPostal = isAlphanumericSpaces(state.postal)

        if (correctAddress1 && correctAddress2 && correctCity && correctCountry && correctPostal) {
            nextStep()
        }
        else {
            // error checking for entered fields
            if (!correctAddress1) {
                error.address1 = 'Please enter a valid address'
                toast.error(t('enter_valid_address'))
            }
            if (!correctAddress2) {
                error.address2 = 'Please enter a valid address'
                toast.error(t('enter_valid_address'))
            }
            if (!correctCity) {
                error.city = 'Please enter a valid city'
                toast.error(t('enter_valid_city'))
            }
            if (!correctCountry) {
                error.country = 'Please enter a valid country'
                toast.error(t('enter_valid_country'))
            }
            if (!correctPostal) {
                error.postal = 'Please enter a valid postal code'
                toast.error(t('enter_valid_postal'))
            }
            setErrors(error)
        }
    }

    const Previous = e => {
        e.preventDefault()
        prevStep()
    }

    const selectStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            // if arabic, right margin of 36px, else left margin of 36px
            margin: locale === 'ar' ? '0 36px 0 0' : '0 0 0 36px',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // if arabic, right margin of 36px, else left margin of 36px
            margin: locale === 'ar' ? '0 36px 0 0' : '0 0 0 36px',
        }),
    }

    // gulf countries
    const countryOptions = [
        { value: 'Saudi Arabia', label: 'Saudi Arabia' },
        { value: 'United Arab Emirates', label: 'United Arab Emirates' },
        { value: 'Kuwait', label: 'Kuwait' },
        { value: 'Oman', label: 'Oman' },
        { value: 'Qatar', label: 'Qatar' },
        { value: 'Bahrain', label: 'Bahrain' },
    ]

    const countryOptionsAr = [
        { value: 'السعودية', label: 'السعودية' },
        { value: 'الإمارات العربية المتحدة', label: 'الإمارات العربية المتحدة' },
        { value: 'الكويت', label: 'الكويت' },
        { value: 'عمان', label: 'عمان' },
        { value: 'قطر', label: 'قطر' },
        { value: 'البحرين', label: 'البحرين' },
    ]

    const countrySelect = locale === 'ar' ? countryOptionsAr : countryOptions

    return (
        <>
            <div className='header-signup'>
                <div className='leftItems'>
                    <Link to="/">
                        <img className='logo-login-corner' src={locale === 'ar' ? logo_rtl : logo} />
                    </Link>
                </div>
                <div className='rightItems'>
                    <div className='language-menu-login'>
                        <LanguageMenu />
                    </div>
                    <p className='login-header-text'>{t('already_a_member')}
                        <a href="/login"> {t('log_in')}</a>
                    </p>
                </div>
            </div>
        <div className='signup-div'>
        <div className='login-page'>
            <div className='signup-logo-space'></div>
        <div className='form'>
            <form className='login-form'>
            <h5 className='toptext'>
                {t('create_an_account')}
            </h5>
                {/* address1 */}
                <p>{t('street_address_1')}</p>
                <input
                    placeholder={t('street_address_1')}
                    name="address1"
                    onChange={handleChange('address1')}
                    defaultValue={values.address1}
                />
                {/*<p className='error'>{errors.address1 && errors.address1}</p>*/}
                <br/>
                {/* address2 */}
                <p>{t('street_address_2')}</p>
                <input
                    placeholder={t('street_address_2')}
                    name="address2"
                    onChange={handleChange('address2')}
                    defaultValue={values.address2}
                />
                {/*<p className='error'>{errors.address2 && errors.address2}</p>*/}
                <br/>
                {/* city */}
                <p>{t('city')}</p>
                <input
                    placeholder={t('city')}
                    name="city"
                    onChange={handleChange('city')}
                    defaultValue={values.city}
                />
                {/*<p className='error'>{errors.city && errors.city}</p>*/}
                <br/>
                {/* country */}
                <p>{t('country')}</p>
                <Select
                    styles={selectStyles}
                    placeholder={t('country')}
                    name="country"
                    options={countrySelect}
                    onChange={handleChange('country')}
                    defaultValue={countrySelect.find(option => option.value === values.country)}
                />
                {/*<p className='error'>{errors.country && errors.country}</p>*/}
                <br/>
                {/* postal */}
                <p>{t('zipcode')}</p>
                <input
                    placeholder={t('zipcode')}
                    name="postal"
                    onChange={handleChange('postal')}
                    defaultValue={values.postal}
                />
                {/*<p className='error'>{errors.postal && errors.postal}</p>*/}
                <div className='buttonsbox'>
                <button className='b1'
                    onClick={ Previous }
                    type="submit"
                >
                    {t('previous')}
                </button>
                <button className='b2'
                    onClick={ Continue }
                    type="submit"
                >
                    {t('next')}
                </button>
                </div>
            </form>
            </div>
        </div>
        </div>
        <Toaster />
        </>
    )
}

export default AddressDetails