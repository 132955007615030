import React, {useState} from 'react'
import login_icon from "../assets/login_icon.svg";
import toast, {Toaster} from "react-hot-toast";
import '../components/SignupComponents/SignupComponents-css/Signup.css'
import { useLocaleContext } from "../hooks/useLocaleContext"
import {useTranslation} from "react-i18next";
import GetAddressFooter from "../components/GetAddressFooter";
import Navbar from "../components/Navbar";
import './pages-css/ContactUs.css'

const ContactUs = () => {

    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [loading, setLoading] = useState(false)

    const { t } = useTranslation()
    const { locale } = useLocaleContext()

    const load = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    )

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        toast.dismiss()

        // process entered email and message
        if (email === "") {
            toast.error(t('enter_valid_email'))
            return
        }

        if (message === "") {
            toast.error(t('fill_all_fields'))
            return
        }

        // currently, message sending does not have a backend
        // wait an arbitrary amount and act like message is received
        try {
            toast.loading(t('loading'))
            setLoading(true)
            await load(getRndInteger(500, 1200))
            setLoading(false)

            setFullName("")
            setEmail("")
            setMessage("")

            toast.dismiss()
            toast.success(t('we_have_received_your_message'))
        }
        catch (err) {
            toast.dismiss()
            toast.error(t(err.message))
        }
    }

    return (
        <div>
            <Navbar/>
            <div className='contact-form-wrapper'>
                <div className='signup-div'>
                    <div className='login-page'>
                        {/*<img src={logo_center} alt="logo" className="logo-login"/>*/}
                        {/*<img src={login_icon} alt="logo" className="logo-login"/>*/}
                        <form className="form" onSubmit={handleSubmit}>
                            <h5 className="toptext">{t('leave_us_message')}</h5>
                            <p>{t('full_name')}</p>
                            <input
                                placeholder={t('full_name')}
                                onChange = {(e) => setFullName(e.target.value)}
                                value={fullName}
                            />

                            <br/>

                            <p>{t('email_address')}*</p>
                            <input
                                placeholder={t('email')}
                                type="email"
                                onChange = {(e) => setEmail(e.target.value)}
                                value={email}
                            />

                            <br/>

                            <p>{t('message')}*</p>
                            <textarea
                                placeholder={t('your_message')}
                                onChange = {(e) => setMessage(e.target.value)}
                                value={message}
                            />

                            <br/>

                            <button disabled={loading}>{t('send_message')}</button>
                            {/*{error && <div className="error">{error}</div>}*/}

                        </form>
                    </div>
                </div>
            </div>
            <GetAddressFooter/>
            <Toaster/>
        </div>
    )
}

export default ContactUs