import React, {useEffect, useState} from "react"
import CardDetails from "../../components/AccountComponents/CardDetails"
import {Link, Navigate} from "react-router-dom"
import {useAuthContext} from "../../hooks/useAuthContext"
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
import AddCardStripe from "../../components/AccountComponents/AddCardStripe"
import { Wrapper,AccNavbar,Content } from "./account.style"
import Navbar from '../../components/Navbar'
import Row from '../../components/AccountComponents/Row'
import {loadStripe} from "@stripe/stripe-js"
import {Elements} from "@stripe/react-stripe-js"
import {useTranslation} from "react-i18next"

// make sure to call `loadStripe` outside a component’s render to avoid
// recreating the `Stripe` object on every render.
const pk = 'pk_test_51PP2iV04mzujkqL4DUufHEFfMIRt1h7olShU6FgXL9kB8LHpTH4awvPZTASPqSbpTlgKYsVlufcaRye2JYQvD7fM002aW21TLl'
const stripePromise = loadStripe(pk)


// cant change card billing address yet
const PaymentMethod = () => {

    // TODO FIGURE OUT APPLE PAY

    const {user} = useAuthContext()

    const { t } = useTranslation()

    // stripe
    const [clientSecret, setClientSecret] = useState('')
    const options = {
        // passing the client secret obtained in step 3
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        appearance: {/*...*/},
    }

    // get user data
    const [cards, setCards] = useState(null)
    // pass in to add card
    const [userData, setUserData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    // make add card popup button
    // pass the isLoaded there
    const handleClick = async () => {
        const response = await fetch(`https://www.luminous.delivery/api/user/profile/${user.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        })

        if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`)
        }

        const json = await response.json()

        if (response.ok) {
            json.id = user.id
            setUserData(json)
        }
    }

    // modal add card
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
        if (!isLoaded) {
            handleClick().then(() => setIsLoaded(true))
        }
    }

    const handleClose = () => setOpen(false)

    // gets only end of card number, provider(card), billing address and name
    useEffect(() => {
        const fetchCards = async () => {
            const response = await fetch(`https://www.luminous.delivery/api/user/payment/${user.id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                setCards(json)
            }
        }

        // stripe sync
        const fetchSecret = async () => {
            const response = await fetch(`https://www.luminous.delivery/api/stripe/create-setup-intent/${user.id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${user.token}`
                },
            })

            const json = await response.json()

            if (response.ok) {
                setClientSecret(json.client_secret)
            }
        }

        if (user) {
            fetchCards()
            fetchSecret()
        }
    }, [user])

    if (!user) {
        return (
            <div>
                <Navigate to='/signup' replace="true"/>
            </div>
        )
    }

    // TODO: make this also delete card from database
    const deleteCard = (cardToDelete) => {
        const newCards = []
        cards.forEach(c => {
            if (c !== cardToDelete) {
                newCards.push(c)
            }
        })
        setCards(newCards)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80vw',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    return (
        <div>
        <Navbar/>
        <Wrapper className="acc">
        <Row/>
        <Content>
            <div className="acc-container" >
                {cards && cards.map((card) => (
                    <div>
                        <CardDetails key={card._id} card={card} />
                        <button className="delete-card" onClick={() => deleteCard(card)} type="submit">
                            {t('remove')}
                        </button>
                    </div>
                ))}
                {/*add new card div*/}
                <div className="acc-box acc-box1" >
                    <div className="acc-desc" >
                        <h3>{t('add_new_payment_method')}</h3>
                        <p>{t('setup_payment_method')}</p>
                        <Button onClick={handleOpen}>{t('add_card')}</Button>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                            >
                                <Fade in={open}>
                                    <Box sx={style}>
                                        {/*<AddCard*/}
                                        {/*    userData={userData}*/}
                                        {/*    cards={cards}*/}
                                        {/*    setCards={setCards}*/}
                                        {/*    isLoaded={isLoaded}*/}
                                        {/*/>*/}
                                        {clientSecret &&
                                            <Elements stripe={stripePromise} options={options}>
                                                <AddCardStripe/>
                                            </Elements>}
                                    </Box>
                                </Fade>
                            </Modal>
                    </div>
                </div>
            </div>
            <Link to="/account" className='back-to-dashboard'>{t('back')}</Link>
        </Content>
        </Wrapper>
        </div>
    )
}

export default PaymentMethod
