// manage delivery addresses
// no delete address yet
// no making current address yet, user will choose one when checking out

import React from "react"
import Select from "react-select"
import {useState} from "react"
import {useUpdateAddresses} from "../../../hooks/profileHooks/useUpdateAddresses"
import {Navigate} from "react-router-dom"
import addressStringSelect from "../../../hooks/functions/AddressStringSelect"
import {useTranslation} from "react-i18next";
import validator from "validator"
import './../../SignupComponents/SignupComponents-css/Signup.css'
import './ProfileSettingsComponents-css/ProfileSettingsComponents.css'
import './../../../pages/Account/account-css/account.css'

const InfoAddresses = ({ id, addresses, prevStep }) => {
    const { t } = useTranslation()

    // errors
    const [errors, setErrors] = useState({ address1: "", address2: "", city: "", country: "", postal: "" })

    // to add new address
    const [newAddress, setNewAddress] = useState(
        {
            address1: '',
            address2: '',
            city: '',
            country: '',
            postal: ''
        }
    )

    // const [displayAddresses, setDisplayAddresses] = useState([])

    // create address info in display format
    const displayAddresses = []
    addresses.forEach(function (address, index) {
        const addressDetail = addressStringSelect(address, index)
        displayAddresses.push(addressDetail)
    })

    const [completed, setCompleted] = useState(false)

    // hook to add
    const {updateAddresses, error, isLoading} = useUpdateAddresses()

    // to delete address or make it current
    // const [inputValue, setInputValue] = useState(null);

    // to add new address
    const handleChange = input => e => {
        newAddress[input] = e.target ? e.target.value : e.value
        setNewAddress(newAddress)
    }

    // add new
    // add error checking, fields cant be empty
    const handleNewAddress = async (e) => {
        e.preventDefault()
        let error = {}
        // spaces and alphanumeric
        const ignoreSpacesRegex = /^[a-zA-Z0-9\s]+$/
        // spaces and alpha
        const ignoreSpacesRegex2 = /^[a-zA-Z\s]+$/
        const correctAddress1 = ignoreSpacesRegex.test(newAddress.address1)
        const correctAddress2 = !newAddress.address2 || ignoreSpacesRegex.test(newAddress.address2)
        const correctCity = ignoreSpacesRegex.test(newAddress.city)
        const correctCountry = ignoreSpacesRegex2.test(newAddress.country)
        // const correctPostal = validator.isPostalCode(state.postal)
        const correctPostal = validator.isAlphanumeric(newAddress.postal)

        if (correctAddress1 && correctAddress2 && correctCity && correctCountry && correctPostal) {
            // send update address request to server
            await updateAddresses(id, newAddress)
            setCompleted(true)
        }
        else {
            // error checking
            if (!correctAddress1) {
                error.address1 = 'Address must contain only letters and numbers'
            }
            if (!correctAddress2) {
                error.address2 = 'Address must contain only letters and numbers'
            }
            if (!correctCity) {
                error.city = 'City must contain only letters'
            }
            if (!correctCountry) {
                error.country = 'Country must contain only letters'
            }
            if (!correctPostal) {
                error.postal = 'Postal code must be valid'
            }
            setErrors(error)
        }
    }

    if (completed) {
        if (error) {
            setCompleted(false)
        }
        else {
            return (
                <div>
                    <Navigate to='/account' replace="false"/>
                </div>
            )
        }
    }

    // to delete
    // const handleChangeSelected = (selected) => {
    //     setInputValue(selected);
    // }

    // to delete
    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //
    //     if (!inputValue) {
    //         throw Error('select an address to delete')
    //     }
    //
    //     // delete address from address list
    //     // delete address from address list in user's database shippingAddresses
    // }

    // gulf countries
    const countryOptions = [
        { value: 'Saudi Arabia', label: 'Saudi Arabia' },
        { value: 'United Arab Emirates', label: 'United Arab Emirates' },
        { value: 'Kuwait', label: 'Kuwait' },
        { value: 'Oman', label: 'Oman' },
        { value: 'Qatar', label: 'Qatar' },
        { value: 'Bahrain', label: 'Bahrain' },
    ]

    return (
        <div className='form-container'>
            {/*add new address!*/}
            <form className='login-form'>
                <h5 className='toptext'>
                    {t('add_new_delivery_address')}
                </h5>
                {/* address1 */}
                <input
                    placeholder={t('street_address_1')}
                    name="address1"
                    onChange={handleChange('address1')}
                />
                <p className='error'>{errors.address1}</p>

                {/* address2 */}
                <input
                    placeholder={t('street_address_1')}
                    name="address2"
                    onChange={handleChange('address2')}
                />
                <p className='error'>{errors.address2}</p>

                {/* city */}
                <input
                    placeholder={t('city')}
                    name="city"
                    onChange={handleChange('city')}
                />
                <p className='error'>{errors.city}</p>

                {/* country */}
                <Select
                    placeholder={t('country')}
                    name="country"
                    options={countryOptions}
                    onChange={handleChange('country')}
                />
                <p className='error'>{errors.country}</p>

                {/* postal */}
                <input
                    placeholder={t('zipcode')}
                    name="postal"
                    onChange={handleChange('postal')}
                />
                <p className='error'>{errors.postal}</p>

                <button className='change-button'
                        disabled={isLoading}
                        onClick={ handleNewAddress }
                        type="submit"
                >
                    {t('add_new_delivery_address')}
                </button>
            </form>

            {/*choosing form NYI*/}
            {/*<form onSubmit={handleSubmit}>*/}
            {/*    <h1 className="addresses-header">Home Addresses</h1>*/}
            {/*    <label>*/}
            {/*        <Select*/}
            {/*            className="addresses"*/}
            {/*            options={addresses && addresses}*/}
            {/*            onChange={handleChangeSelected}*/}
            {/*            isMulti={false}*/}
            {/*        />*/}
            {/*        <input className='delete-button' type="submit" value="Remove Address" />*/}
            {/*    </label>*/}
            {/*</form>*/}

            {/*display addresses as a menu for now*/}
            <br/>
            {/*<Select*/}
            {/*    className="addresses"*/}
            {/*    options={addresses && displayAddresses}*/}
            {/*    // onChange={handleChangeSelected}*/}
            {/*    isMulti={false}*/}
            {/*/>*/}
            <button className='change-button'
                    onClick={prevStep}
                    type="submit"
            >
                {"<"}
            </button>
            {error && <div className="error">{error}</div>}
        </div>
    )
}

export default InfoAddresses