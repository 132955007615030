import React from 'react'
import terms_and_conditions_pdf_ar from "../assets/documents/Translated Terms & Conditions, Arabic - Luminous.pdf"
import terms_and_conditions_pdf_en from "../assets/documents/Luminous' Terms & Conditions.pdf"
import privacy_policy_pdf_ar from "../assets/documents/Translated Privacy Policy, Arabic - Luminous.pdf"
import privacy_policy_pdf_en from "../assets/documents/Luminous' Privacy Policy.pdf"
import {useLocaleContext} from "../hooks/useLocaleContext"
import {useTranslation} from "react-i18next"
import waves from "../assets/waves.svg";
import logo_rtl from "../assets/imagertl.svg";
import logo from "../assets/image.svg";
import Terms from '../pages/Terms'
import { SocialIcon } from 'react-social-icons'

const GetAddressFooter = () => {
    const { locale } = useLocaleContext()
    const { t } = useTranslation()

    return (
        <div>
            <img src={waves} alt="waves" className='footer-waves'></img>

            <div className='logoandline'>
                <img className='footer-logo' src={locale === 'ar' ? logo_rtl : logo} />
                <hr/>
            </div>

            <div className='getaddress-footer'>
                <div className="getaddress-box1">
                    {/*<h1 className="getaddress-footer-header">{t('about_caps')}</h1>*/}
                    {/*<h1 className="getaddress-footer-header">{t('company')}</h1>*/}
                    <div className='useless-box'>
                        <li className="getaddress-footer-list">
                            <a
                                href="/about"
                                className="getaddress-footer-list"
                            >
                                {t('about')}
                            </a>
                        </li>
                        <li className="getaddress-footer-list">
                            <a
                                href="/careers"
                                className="getaddress-footer-list"
                            >
                                {t('careers')}
                            </a>
                        </li>
                        <li className="getaddress-footer-list">
                            <a
                                href="/contact-us"
                                className="getaddress-footer-list"
                            >
                                {t('contact_us')}
                            </a>
                        </li>
                        <li className="getaddress-footer-list">
                            <a
                                href="/faq"
                                className="getaddress-footer-list"
                            >
                                {/*{t('faq_abbr')}*/}
                                {t('faq')}
                            </a>
                        </li>
                        <li className="getaddress-footer-list">
                            <a
                                href="/termsconditions"
                                // {locale === 'ar' ? terms_and_conditions_pdf_ar : terms_and_conditions_pdf_en}
                                // target="_blank"
                                // rel="noreferrer"
                                className="getaddress-footer-list"
                            >
                                {t('terms_of_service')}
                            </a>
                        </li>
                        {/* <li className="getaddress-footer-list">{t('privacy_policy')}</li> */}
                        <li className="getaddress-footer-list">
                            <a
                                href="/privacy"
                                // {locale === 'ar' ? privacy_policy_pdf_ar : privacy_policy_pdf_en}
                                // target="_blank"
                                // rel="noreferrer"
                                // className="getaddress-footer-list"
                                className="getaddress-footer-list"
                            >
                                {t('privacy_policy')}
                            </a>
                        </li>
                        {/*<li className="getaddress-footer-list">{t('press')}</li>*/}
                        {/*<li className="getaddress-footer-list">{t('blog')}</li>*/}
                    </div>
                    <div className="getaddress-footer-social">
                        <SocialIcon
                            url="https://www.instagram.com/appluminous/"
                            bgColor="ffffff"
                            fgColor="0000ff"
                            style={{ height: 28, width: 28, /* padding: 5 */}}
                        />
                        <SocialIcon
                            url="https://www.twitter.com/AppLuminous/"
                            bgColor="ffffff"
                            fgColor="0000ff"
                            style={{ height: 28, width: 28, /* padding: 5,*/ marginLeft: 20, marginRight: 20 }}
                        />
                        <SocialIcon
                            url="https://www.linkedin.com/company/luminous-delivery/"
                            bgColor="ffffff"
                            fgColor="0000ff"
                            style={{ height: 28, width: 28, /* padding: 5 */}}
                        />
                    </div>

                    <hr className='hr-bottom'/>

                    <div className='end-of-footer'>
                        <p className='end-lum-year'>{t('made_with_love')} </p>
                        <p className='end-lum-year'>2024</p>
                    </div>
                </div>

                {/*<div className="getaddress-box2">*/}
                {/*    <h1 className="getaddress-footer-header">{t('contact')}</h1>*/}
                {/*    <div className='useless-box'>*/}
                {/*        <li className="getaddress-footer-list">*/}
                {/*            <a*/}
                {/*                href="/support"*/}
                {/*                className="getaddress-footer-list"*/}
                {/*            >*/}
                {/*                {t('contact_us')}*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*        /!*<li className="getaddress-footer-list">*!/*/}
                {/*        /!*    <a*!/*/}
                {/*        /!*        href="/support"*!/*/}
                {/*        /!*        className="getaddress-footer-list"*!/*/}
                {/*        /!*    >*!/*/}
                {/*        /!*        {t('help')}*!/*/}
                {/*        /!*    </a>*!/*/}
                {/*        /!*</li>*!/*/}
                {/*        <li className="getaddress-footer-list">*/}
                {/*            <a*/}
                {/*                href="/faq"*/}
                {/*                className="getaddress-footer-list"*/}
                {/*            >*/}
                {/*                /!*{t('faq_abbr')}*!/*/}
                {/*                {t('faq')}*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="getaddress-box3">*/}
                {/*    <h1 className="getaddress-footer-header">{t('legal')}</h1>*/}
                {/*    <div className='useless-box'>*/}
                {/*        /!*<Link to='/terms' className="getaddress-footer-list">{t('terms_of_service')}</Link>*!/*/}
                {/*        <li className="getaddress-footer-list">*/}
                {/*            <a*/}
                {/*                href="/termsconditions"*/}
                {/*                // {locale === 'ar' ? terms_and_conditions_pdf_ar : terms_and_conditions_pdf_en}*/}
                {/*                // target="_blank"*/}
                {/*                // rel="noreferrer"*/}
                {/*                className="getaddress-footer-list"*/}
                {/*            >*/}
                {/*                {t('terms_of_service')}*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*        /!* <li className="getaddress-footer-list">{t('privacy_policy')}</li> *!/*/}
                {/*        <li className="getaddress-footer-list">*/}
                {/*            <a*/}
                {/*                href="/privacy"*/}
                {/*                // {locale === 'ar' ? privacy_policy_pdf_ar : privacy_policy_pdf_en}*/}
                {/*                // target="_blank"*/}
                {/*                // rel="noreferrer"*/}
                {/*                // className="getaddress-footer-list"*/}
                {/*                className="getaddress-footer-list"*/}
                {/*            >*/}
                {/*                {t('privacy_policy')}*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default GetAddressFooter;
