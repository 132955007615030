import { useState } from "react"

// only adds new address
export const useUpdateAddresses = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)

    const updateAddresses = async (id, address) => {
        setIsLoading(true)
        setError(null)

        // send server request to update address
        const response = await fetch('https://www.luminous.delivery/api/user/update/addresses', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id, address})
        })
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }
        if (response.ok) {
            setIsLoading(false)
        }
    }

    return { updateAddresses, error, isLoading }
}