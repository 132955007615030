import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { ModalBox, PopupContent } from "./account.style"; // Import the styled components

import { Wrapper, Content } from "./account.style";

// Icons
import HomeIcon from '@mui/icons-material/Home';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PaymentIcon from '@mui/icons-material/Payment';
import BoltIcon from '@mui/icons-material/Bolt';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';

import { useAuthContext } from "../../hooks/useAuthContext";
import { Link, Navigate } from "react-router-dom";
import { useLogout } from "../../hooks/useLogout";
import Navbar from '../../components/Navbar';
import MyDeliveryAddress from "../../components/AccountComponents/MyDeliveryAddress";
import { useTranslation } from "react-i18next";
import Row from "../../components/AccountComponents/Row";

const Account = () => {
    const { logout } = useLogout();
    const { user } = useAuthContext();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleLogout = () => {
        logout();
    };

    if (!user) {
        return (
            <div>
                <Navigate to='/signup' replace="true" />
            </div>
        );
    }

    return (
        <div>
            <Navbar />
            <Wrapper className="acc">
                <Row />
                <Content>
                    <h1>{`${t('welcome')}, ${user.name}`}</h1>
                    <div className="acc-container">
                        <div className="acc-box acc-box1">
                            <div className="acc-desc">
                                <h3>{t('my_packages')}</h3>
                                <p>{t('register_latest_order')}</p>
                                <Link to="/account/my-packages" id='my-packages'>
                                    {t('my_packages')}
                                </Link>
                            </div>
                        </div>

                        <div className="acc-box acc-box2">
                            <div className="acc-desc">
                                <h3>{t('tracking')}</h3>
                                <p>{t('see_your_package')}</p>
                                <Link to="/account/tracking" id='tracking-info'>
                                    {t('tracking_info')}
                                </Link>
                            </div>
                        </div>

                        <div className="acc-box acc-box2">
                            <div className="acc-desc">
                                <h3>{t('my_address')}</h3>
                                <p>{t('view_your_address')}</p>
                                <Link onClick={handleOpen}>{t('my_address')}</Link>
                            </div>
                        </div>
                    </div>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                    >
                        <Fade in={open}>
                            <ModalBox className='accpopup'>
                                <PopupContent>
                                    <h1>{t('your_lum_address')}, {user.name}</h1>
                                    <MyDeliveryAddress />
                                </PopupContent>
                            </ModalBox>
                        </Fade>
                    </Modal>
                </Content>
            </Wrapper>
        </div>
    );
};

export default Account;
