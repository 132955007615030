import React, { useState } from "react";
import "./How.css";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useLocaleContext } from "../../hooks/useLocaleContext";

import laptop from '../../assets/Laptop.png';
import house from '../../assets/House.png';
import airplane from '../../assets/Airplane.png';
import ar_airplane from '../../assets/Airplane_Arabic.png';
import AI from '../../assets/AI.png';

import black_line from '../../assets/Black_Line_Circle_new.png';
import chrome from '../../assets/Chrome_Logo.png';
import stars from '../../assets/stars.png';

const How_en = () => {
  const { t } = useTranslation();
  const [nav, setNav] = useState(false);
  const { locale } = useLocaleContext();

  const handleClick = async () => {
    setNav(true);
  };

  if (nav) {
    return <Navigate to='/extension' replace />;
  }

  return (
      <div>
        <div className="header-container">
          <h1>{t('how_luminous_works')}</h1>
        </div>
        <div className='top-3'>
          <div className='image'>
            <img src={laptop} alt="laptop" />
            <p>{t('sign_up_step')}</p>
          </div>
          <div className='image'>
            <img src={house} alt="house" />
            <p>{t('shop_step')}</p>
          </div>
          <div className='image'>
            <img src={AI} alt="AI image" />
            <p>{t('add_step')}</p>
          </div>
        </div>
        <div className="vertical-container">
          <div className="plane">
            <img src={locale === "en" ? airplane : ar_airplane} alt="plane" />
            <p>{t('luminous_step')}</p>
          </div>
          <div className="black-line">
            <img src={black_line} alt="black line" />
          </div>
          <div className="chrome-logo">
            <img src={chrome} alt="chrome logo" />
            <p>{t('chrome')}</p>
          </div>
          <div className="stars">
            <img src={stars} alt="stars" />
          </div>
          <p className="check-out-exp">
            {t('checkout_exp_line1')}
            <br />
            {t('checkout_exp_line2')}
            <br />
            {t('checkout_exp_line3')}
          </p>
          <div className="button_slide slide_right" onClick={handleClick}>{t('get_extension')}</div>
        </div>
      </div>
  );
};

export default How_en;
