import React from 'react'
import {useEffect, useState} from "react"
import {useElements, useStripe} from "@stripe/react-stripe-js"
import {loadStripe} from "@stripe/stripe-js"
import {Navigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import './MyPackagesComponents/mpcomponents-css/confirmorder.css'
import {useUpdateOrders} from "../../hooks/useUpdateOrders"


// complete the payments initiated from the chrome extension
const ExtensionCheckout = ({ card, orderData }) => {

    const { t } = useTranslation()

    const stripe = useStripe()

    // to update orders -> update paid
    const {updateOrders, error, isLoading} = useUpdateOrders()

    const [paymentSuccessful, setPaymentSuccessful] = useState(false)
    const [message, setMessage] = useState(null)
    const [isLoadingPayment, setIsLoadingPayment] = useState(false)
    const [paymentIntent, setPaymentIntent] = useState(null)
    const [goBack, setGoBack] = useState(false)

    // creates payment intent
    useEffect(() => {
        console.log(card)
        const fetchSecret = async () => {
            const response = await fetch(`https://www.luminous.delivery/api/stripe/create-payment-intent`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    id: orderData.id,
                    cardid: card.id,
                    order: orderData.order,
                    address: orderData.address
                })
            })

            const json = await response.json()
            console.log(json)

            if (response.ok) {
                setPaymentIntent(json)
            }
        }

        if (orderData.id && stripe) {
            fetchSecret()
        }
    }, [stripe])

    const updateOrdersClient = async () => {
        // updates on client because need to wait for confirmation
        const newOrder = orderData.order
        newOrder.stripe_id = paymentIntent.payment_method
        await updateOrders(orderData.id, newOrder, 1, null)
    }

    // makes payment
    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!stripe || !paymentIntent) {
            // payment intent not created yet
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsLoadingPayment(true)

        stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentIntent.payment_method
        }).then(function(result) {
            if (result.error) {
                // Show error to your customer
                setMessage(result.error.message)
                console.log(result.error.message)
                setIsLoadingPayment(false)
            }
            else {
                // this would go to paymnent status page normally
                if (result.paymentIntent.status === 'succeeded') {
                    // The payment is complete!
                    // webhook will NOT be taking care of this because orderid is not a thing, order is not created!
                    // could create a temp order then delete, but that's not the logic right now
                    updateOrdersClient()
                    setMessage(t('payment_successful'))
                    setIsLoadingPayment(false)
                    setPaymentSuccessful(true)
                }
            }
        })
    }

    if (paymentSuccessful) {
        // wait for 5 seconds then redirect to account
        setTimeout(() => {
            setGoBack(true)
        }, 4000)
        if (goBack) {
            return (
                <div>
                    <Navigate to='/account/order-confirmation' replace="true"/>
                </div>
            )
        }
    }

    return (
        <div>
            <h2>{t('checkout')}</h2>
            <form id="payment-form">
                <button className='confirmorderbtn' disabled={isLoadingPayment || paymentSuccessful} onClick={handleSubmit} id="submit">
                    <span id="button-text">
                        {isLoadingPayment ? <div className="spinner" id="spinner"></div> : t('pay_now')}
                    </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
                {paymentSuccessful && <div>{t('taking_you_back')}</div>}
            </form>
        </div>
    )
}

export default ExtensionCheckout