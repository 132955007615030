import React, {useRef} from "react"
import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import {useState, useEffect} from 'react';
import './components-css/Navbar.css';
import logo from '../assets/logoblack.svg'
import logo_rtl from '../assets/logoblackrtl.svg'
import menu from '../assets/menuicon.svg'
import { useLocaleContext } from "../hooks/useLocaleContext"
import {useOnHoverOutside} from "../hooks/functions/OnHoverOutside"
import {useTranslation} from "react-i18next"
import Select from "react-select";
import LanguageMenu from "./LanguageMenu"
import "./components-css/LanguageMenu.css"
import {Nav, NavDropdown} from "react-bootstrap"


// navigation bar
const Navbar = () => {
    const { logout } = useLogout()
    const { user } = useAuthContext()

    const { t } = useTranslation()
    const { locale } = useLocaleContext()

    const handleClick = () => {
        logout()
    }

    const [sidebar, setSidebar] = useState(false)
    
    const openNav = () => {setSidebar(!sidebar)}

    const [navbar, setNavbar] = useState(true)

    const changeBackground = () => {
        // console.log(window.scrollY)
        if (window.scrollY >= 77) {
            setNavbar(false)
        } else {
            setNavbar(true)
        }
    }

    useEffect(() => {
        changeBackground()
        window.addEventListener("scroll", changeBackground)
    }, [])


    return (
        <header>
        <div className={navbar ? "Header" : "HeaderScroll"}>

            <div className='leftItems'>
                <Link to="/">
                    <img className='logo' src={locale === 'ar' ? logo_rtl : logo} />
                </Link>

                <Link to="/about" className='navlink'>
                    {t('about')}
                </Link>

                <Link to="/rates" className='navlink'>
                    {t('rates')}
                </Link>

                <Link to="/support" className='navlink'>
                    {t('support')}
                </Link>

                {/*<div ref={dropdownRef}>*/}
                {/*    <button*/}
                {/*        className="dropdown-menu"*/}
                {/*        onMouseOver={() => setMenuDropDownOpen(true)} //use mouseover event to show dropdown*/}
                {/*    >*/}
                {/*        {t('language')}*/}
                {/*    </button>*/}
                {/*    /!*select from languages*!/*/}
                {/*    {isMenuDropDownOpen && <LanguageMenu />}*/}
                {/*</div>*/}

                <LanguageMenu />

                    {user && (
                        <div id='loggedinrightitems'>
                            {/* <span className='navlink'>{user.email}</span> */}
                            <Link to="/account" id='account' className='navlink'>{t('account')}</Link>

                            <Link to="/">
                                <Link id="logout" className='navlink' class onClick={handleClick}>{t('log_out')}</Link>
                            </Link>
                        </div>
                    )}
                    {!user && (
                        <div id='loginsignup'>
                            <Link to="/login" id='login' className='navlink'>{t('log_in')}</Link>
                            <Link to="/signup" id='signup' className='navlink'>{t('sign_up')}</Link>
                        </div>
                    )}

            </div>

            <div className="logocontainer">

            </div>

            <div className='rightItemsNav'>
                <span className='burgermenudiv' onClick={() => openNav()}>
                    <img className='menuimg' src={menu}/>
                </span>
            </div>

        </div>

        <div className={sidebar ? locale === 'ar' ? 'sidenav-rtl' : 'sidenav' : 'closed'}>
            <a className="closebtn" onClick={() => openNav()}>&times;</a>
            <div className={locale === 'ar' ? 'sidenav-logo-rtl' : 'sidenav-logo' }>
                <Link to="/">
                    <img className='logo' src={locale === 'ar' ? logo_rtl : logo} />
                </Link>
            </div>
            <hr/>
            {user && (
                <ul className={locale === 'ar' ? 'link-list-rtl' :'link-list'}>
                    <li>
                        <Link to="/account">{t('account')}</Link>
                    </li>
                    <li>
                        <Link onClick={handleClick} to="/">{t('log_out')}</Link>
                    </li>
                    <li>
                        <Link to="/about">{t('about')}</Link>
                    </li>
                    <li>
                        <Link to="/rates">{t('rates')}</Link>
                    </li>
                    <li>
                        <Link to="/support">{t('support')}</Link>
                    </li>
                </ul>
            )}
            {!user && (
                <ul className={locale === 'ar' ? 'link-list-rtl' :'link-list'}>
                    <li>
                        <Link to="/login">{t('log_in')}</Link>
                    </li>
                    <li>
                        <Link to="/signup">{t('sign_up')}</Link>
                    </li>
                    <li>
                        <Link to="/about">{t('about')}</Link>
                    </li>
                    <li>
                        <Link to="/rates">{t('rates')}</Link>
                    </li>
                    <li>
                        <Link to="/support">{t('support')}</Link>
                    </li>
                </ul>

            )}
        </div>

        </header>
    )
}

export default Navbar