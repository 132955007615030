import React from 'react'
import Navbar from "../components/Navbar"
import {useLocaleContext} from "../hooks/useLocaleContext";
import {useTranslation} from "react-i18next";
import GetAddressFooter from "../components/GetAddressFooter";
import './pages-css/Careers.css'

const Careers = () => {
    const { t } = useTranslation()
    const { locale } = useLocaleContext()

    return (
        <div>
            <Navbar />
            <div className='careers-container'>
                <div className='careers-text-container'>
                    <div className='getaddress-flexbox'>
                        <div className="left-box">
                            <div className='left-innerbox'>
                                <div className={locale === 'ar' ? 'pages-rtl' : 'pages-ltr'}>
                                    <h1 className='maintext'>{t('jobs')}</h1>
                                    <h1 className='maintext'>{t('at_luminous')}</h1>

                                    <p id='getaddress-paragraph'>{t('no_open_positions')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="space"></div>*/}
            <GetAddressFooter />
        </div>
    )
}

export default Careers