import React from "react"
import addressString from "../../hooks/functions/AddressString"
import {useTranslation} from "react-i18next"
import tn from "../../hooks/functions/NumberToAr"

const OrderDetails = ({ order, index, priceBool }) => {
    const { t } = useTranslation()

    return (
        <div>
            <div className="order-details">
                <h3>{index && `${t('order')}: ${tn(index)}`}</h3>
                {order.itemNames && order.itemNames.map((item, key) => (
                    <div key={key}>
                        {item.name && item.quantity && <p>{item.name} x {item.quantity}</p>}
                    </div>
                ))}
                <p><strong>{t('when')}: </strong>{ order.createdAt }</p>
                <p><strong>{t('where')}: </strong>{ addressString(order.address) }</p>
                {!priceBool && <p><strong>{t('price')}: </strong>{tn(order.price)}</p>}
                {order.delivered && order.deliveredDate}
            </div>
        </div>
    )
}

export default OrderDetails