import React, {useState} from "react"
import UserDetails from "../components/SignupComponents/UserDetails"
import PersonalDetails from "../components/SignupComponents/PersonalDetails"
import AddressDetails from "../components/SignupComponents/AddressDetails"
import ReviewDetails from "../components/SignupComponents/ReviewDetails"
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";

// rsc makes this !
const Signup = () => {

    const { t } = useTranslation()

    const [state, setState] = useState(
        {
            step: 1,
            email: '',
            password: '',
            name: '',
            middleName: '',
            surname: '',
            mobile: '',
            mobileAreaCode: '',
            address1: '',
            address2: '',
            city: '',
            country: '',
            postal: ''
        }
    )
    const [step, setStep] = useState(state.step)

    // go back to previous step
    const prevStep = () => {
        const { step } = state
        const nextStep = step - 1
        state.step = nextStep
        setStep(nextStep)
        setState(state)
    }

    // proceed to the next step
    const nextStep = () => {
        const { step } = state
        const nextStep = step + 1
        state.step = nextStep
        setStep(nextStep)
        setState(state)
    }

    const firstStep = async () => {

        const { email } = state
        let error = false

        const apiUrl = process.env.REACT_APP_API_URL;
        const exists = await fetch(`${apiUrl}/user/email/${email}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            }
        })

        if (!exists.ok) {
            // throw Error('Email already in use')
            toast.error(t('email_already_in_use'))
            error = true
        }

        if (error === false) {
            nextStep()
        }
    }

    // Handle fields change
    // might need debugging here (why ?)
    const handleChange = input => e => {
        // chatgpt recommended this
        // setState({ ...state, [input]: e.target.value })
        state[input] = e.target ? e.target.value.trim() : e.value
        setState(state)
    }

    const {
        email, password,
        name, middleName, surname, mobile, mobileAreaCode,
        address1, address2, city, country, postal
    } = state

    const values = {
        email, password,
        name, middleName, surname, mobile, mobileAreaCode,
        address1, address2, city, country, postal
    }

    switch(step) {
        case 1:
            return (
                <UserDetails
                    nextStep={ firstStep }
                    handleChange={ handleChange }
                    values={ values }
                    state={ state }
                />
            )
        case 2:
            return (
                <PersonalDetails
                    prevStep={ prevStep }
                    nextStep={ nextStep }
                    handleChange={ handleChange }
                    values={ values }
                    state={ state }
                />
            )
        case 3:
            return (
                <AddressDetails
                    prevStep={ prevStep }
                    nextStep={ nextStep }
                    handleChange={ handleChange }
                    values={ values }
                    state={ state }
                />
            )
        case 4:
            return (
                <ReviewDetails
                    prevStep={ prevStep }
                    values={ values }
                />
            )
        default:
        // do nothing
    }
}

export default Signup