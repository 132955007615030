import React, {useContext} from "react"
import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext"
import {Link, Navigate} from "react-router-dom"
import background3 from '../assets/Desktop-Model3.jpeg'
import "./pages-css/GetAddress.css"
import Navbar from '../components/Navbar'
import waves from '../assets/waves.svg'
import stars from '../assets/stars.png'
import john from '../assets/john.png'
import arrow from '../assets/arrow_down.png'
import Accordion from "../components/Accordion/Accordion";
import How_en from "../components/HowComponent/How_en";
import { faqs } from "../components/Accordion/data";
import { faqs_ar } from "../components/Accordion/data-ar"
import logo from '../assets/image.svg'
import logo_rtl from '../assets/imagertl.svg'
import {useTranslation} from "react-i18next"
import LocaleContext from "../context/LocaleContext"
import i18n from "i18next"
import {useLocaleContext} from "../hooks/useLocaleContext"
import "./pages-css/RTL.css"
import faq_pdf_en from '../assets/documents/Luminous\' FAQ.pdf'
import privacy_policy_pdf_en from '../assets/documents/Luminous\' Privacy Policy.pdf'
import terms_and_conditions_pdf_en from '../assets/documents/Luminous\' Terms & Conditions.pdf'
import faq_pdf_ar from '../assets/documents/Translated FAQ, Arabic - Luminous.pdf'
import privacy_policy_pdf_ar from '../assets/documents/Translated Privacy Policy, Arabic - Luminous.pdf'
import terms_and_conditions_pdf_ar from '../assets/documents/Translated Terms & Conditions, Arabic - Luminous.pdf'

// components
import WarehouseDetails from '../components/WarehouseDetails'
import AccordionItem from "../components/Accordion/AccordionItem";
import GetAddressFooter from "../components/GetAddressFooter";

const GetAddress = () => {
    const [prio, setPrio] = useState(null)
    const [warehouseCountry, setWarehouseCountry] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');
    const [nav, setNav] = useState(false)
    const [navAcc, setNavAcc] = useState(false)

    // language
    const { t } = useTranslation()
    const { locale } = useLocaleContext()

    const {user} = useAuthContext()

    const handleClick = async () => {

        // if not user return
        if (!user) {
            setNav(true)
        }
        else {
            setNavAcc(true)
        }
    }

    //     setIsLoading(true)
    //
    //     try {
    //         const response = await fetch('https://www.luminous.delivery/api/warehouses/priority/prio', {
    //             method: 'GET',
    //             headers: {
    //                 Accept: 'application/json',
    //                 Authorization: `Bearer ${user.token}`
    //             }
    //         })
    //
    //         if (!response.ok) {
    //             throw new Error(`Error! status: ${response.status}`)
    //         }
    //
    //         const result = await response.json()
    //
    //         let mostPrio = result[0]
    //
    //         setPrio(mostPrio)
    //         console.log(mostPrio)
    //     }
    //     catch (err) {
    //         setErr(err.message)
    //     }
    //     finally {
    //         setIsLoading(false)
    //     }
    //
    //     if (!err) {
    //         console.log('optimal address found')
    //     }
    //     else {
    //         console.log(err)
    //     }
    // }
    //
    if (nav) {
        return (
            <div>
                <Navigate to='/signup' replace="true"/>
            </div>
        )
    }

    if (navAcc) {
        return (
            <div>
                <Navigate to='/account' replace="true"/>
            </div>
        )
    }

    return (
        <>
        <Navbar/>
        <div>
            <header className="getaddress-header"/>

            <div className='getaddress-flexbox'>

                <div className="left-box">
                    <div className='left-innerbox'>
                        <div className={locale === 'ar' ? 'pages-rtl' : 'pages-ltr'}>
                            <h1 className='maintext'>{t('homepage_main_service')}</h1>

                            <img src={stars} alt="stars" className='getaddress-stars'/>

                            <p id={locale === 'en' ? 'getaddress-paragraph' : ''}>{t('homepage_subtext')}</p>
                        </div>
                    </div>
                </div>

                <div className="right-box">

                    <div className="getaddress-card">
                        <img src={arrow} className={locale === 'ar' ? 'arrow-rtl' :'arrow'}/>
                        {/*<div className="getaddress-container">*/}
                        {/*/!* <h4><b></b></h4> *!/*/}
                        {/*<p>{t('welcome_lum_website')} 👋</p>*/}
                        {/*</div>*/}
                    </div>
                    <br/>
                    <div className="button_container">
                        {/*get address*/}
                        <div className="button_slide slide_right" onClick={handleClick}>{t('get-address')}</div>
                    </div>

                </div>
            </div>

            <img src={waves} alt="waves" className='getaddress-waves'></img>
            <div className='morewaves'/>
            
            
            
            {/* here insert new content*/}

            <How_en/>

            <div className="accordions">
                    <h1 className="accordion-header">{t('faq_caps')}</h1>
                <Accordion data={locale === 'ar' ? faqs_ar.slice(0,8) : faqs.slice(0,8)}/>
            </div>

            <div className='FAQdiv'>
                <span className="FAQ">{t('to_learn_more')}, <Link to='/faq'>{t('visit_faq')}</Link></span>
            </div>

            <GetAddressFooter/>

        </div>
        </>
    )
}

export default GetAddress
