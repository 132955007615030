import React from "react"
import image from '../assets/whatsapp.png'
import './pages-css/Support.css'
import { Fade } from 'react-reveal';
import Navbar from '../components/Navbar'
import GetAddressFooter from "../components/GetAddressFooter";
import {useLocaleContext} from "../hooks/useLocaleContext";

const Support = () => {

    const { locale } = useLocaleContext()

    return (  
        <div>
        <Navbar/>
            <div id="div">
                <div className='centered'>
                    <img className='img' src={image} alt="Logo" />
                    <Fade bottom>
                        <div>
                            <h4 className='bottomtext1'>support@luminous.delivery</h4>
                            <h4 className='bottomtext2'>
                                {locale === 'en' ? "+1 (401) 632-9377" : "632-9377 (401) 1+"}
                            </h4>
                        </div>
                    </Fade>
                </div>
            </div>
            <GetAddressFooter/>
        </div>
    )
}

export default Support