import tn from "./NumberToAr"


// for react-select format
export default function cardStringSelect(card, val) {
    if (val === null) {
        val = card.id
    }
    return {
        value: val,
        label: `${card.name}'s ${card.card} ending in ${card.number}`
    }
}