import React from 'react'
import './../../pages/pages-css/About.css'
import Navbar from "../Navbar"
import sideArrow from "../../assets/arrow_side.png"
import GetAddressFooter from "../GetAddressFooter"
import {useLocaleContext} from "../../hooks/useLocaleContext"
import {useTranslation} from "react-i18next"


const Step = ({ step, body, handleClickForward, handleClickBackward }) => {

    const { locale } = useLocaleContext()
    const { t } = useTranslation()

    return (
        <>
            <Navbar/>
            <div className="about-container">
                <div className="about">
                    <div className="about-body-step">
                        <p>{t("step")} {step}</p>
                    </div>
                    <div className="about-body-step">
                        <p>{body}</p>
                    </div>
                </div>
                <div className="about-progress">
                    <div className="side-arrow">
                        <img
                            src={sideArrow}
                            className={locale === 'en' ? "side-arrow-img-left" : "side-arrow-img"}
                            onClick={handleClickBackward}
                        />
                    </div>
                    <p className="about-progress-num">{locale === 'en' ? `${step + 1}/5` : `${step + 1}/5`}</p>
                    <div className="side-arrow">
                        <img
                            src={sideArrow}
                            className={locale === 'en' ? "side-arrow-img" : "side-arrow-img-left"}
                            onClick={handleClickForward}
                        />
                    </div>
                </div>
            </div>
            <GetAddressFooter/>
        </>
    )
}

export default Step