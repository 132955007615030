// pass change
import React from "react"
import {useUpdatePassword} from "../../../hooks/profileHooks/useUpdatePassword"
import {useState} from "react"
import {Navigate} from "react-router-dom"
import validator from "validator"
import {useTranslation} from "react-i18next";
import './../../SignupComponents/SignupComponents-css/Signup.css'
import './../../../pages/Account/account-css/account.css'
import './ProfileSettingsComponents-css/ProfileSettingsComponents.css'

// TODO make people enter old pass as well to check ?
const InfoPassword = ({ id, prevStep }) => {
    const {updatePassword, error, isLoading} = useUpdatePassword()

    const { t } = useTranslation()

    const [errors, setErrors] = useState({ password: "" })

    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [completed, setCompleted] = useState(false)

    const handleChange = e => {
        setNewPassword(e.target.value)
    }

    const handleChangeConfirm =  e => {
        setConfirmPassword(e.target.value)
    }

    const update = async (e) => {
        e.preventDefault()
        let error = {}
        const correctPassword = validator.isStrongPassword(newPassword)
        const correctConfirmPassword = newPassword === confirmPassword

        if (correctPassword && correctConfirmPassword) {
            // send password update request to server
            await updatePassword(id, newPassword)
            setCompleted(true)
        }
        else {
            // error checking for entered fields
            if (!correctPassword) {
                error.password = "Password is not strong enough"
            }
            else if (!correctConfirmPassword) {
                error.password = "Passwords do not match"
            }
        }
    }

    if (completed) {
        if (error) {
            setCompleted(false)
        }
        else {
            return (
                <div>
                    <Navigate to='/account' replace="false"/>
                </div>
            )
        }
    }

    return (
        <div className='form-container'>
            <br/>
            <form className='login-form'>
                <h5 className='toptext'>
                    {t('password')}
                </h5>
                {/* password */}
                <input
                    placeholder={t('password')}
                    name="password"
                    onChange={handleChange}
                />
                {/*ADD CONFIRM PASSWORD LOGIC*/}
                <h5 className='toptext'>
                    {t('confirm_password')}
                </h5>
                <input
                    placeholder={t('confirm_password')}
                    name="confirm-password"
                    onChange={handleChangeConfirm}
                />
            </form>
            {errors && <p>{errors.password}</p>}
            <div className="buttons-box">
                <button className='change-button'
                        onClick={prevStep}
                        type="submit"
                >
                    {" <  "}
                </button>
                <button className='change-button'
                        disabled={isLoading}
                        onClick={update}
                        type="submit"
                >
                    {t('save')}
                </button>
                {error && <div className="error">{error}</div>}
            </div>
        </div>
    )
}

export default InfoPassword