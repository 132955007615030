import React from "react";
import "./pages-css/Extension.css";
import { useTranslation } from "react-i18next";
import { useLocaleContext } from "../hooks/useLocaleContext";
import Navbar from '../components/Navbar';
import checkmark from '../assets/Checkmark.png';
import { Navigate } from "react-router-dom";
import { useState } from "react";

const Extension = () => {
  const { locale } = useLocaleContext();
  const { t } = useTranslation();
  const [nav, setNav] = useState(false);

  const handleClick = async () => {
    setNav(true);
  };

  if (nav) {
    return <Navigate to='/' replace />;
  }

  return (
      <>
        <Navbar />
        <div className="extension-container" dir={locale === 'ar' ? 'rtl' : 'ltr'}>
          <div className="extension-header">
          </div>
          <div className="extension-content">
            <div className="content-left">
              <h2 className="extension-title">{t("extension_header")}</h2>
              <p className="extension-paragraph">
                {t("seamless_checkout")}
              </p>
              <ul className="extension-list">
                <li className="extension-list-item">
                  <img src={checkmark} alt="Checkmark" className="checkmark-icon" />
                  {t("install")}
                </li>
                <li className="extension-list-item">
                  <img src={checkmark} alt="Checkmark" className="checkmark-icon" />
                  {t("linked")}
                </li>
                <li className="extension-list-item">
                  <img src={checkmark} alt="Checkmark" className="checkmark-icon" />
                  {t("AI_powered")}
                </li>
              </ul>
            </div>
            <div className="content-right">
              <iframe
                  className="youtube-iframe"
                  src="https://www.youtube.com/embed/aRgvj4nAmJg"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="button-container">
            <button className="back-button" onClick={handleClick}>{t("back_b")}</button>
          </div>
        </div>
      </>
  );
}

export default Extension;
