// make this a number wrapper that translates the number to arabic numerals
// if the language is arabic
import {useLocaleContext} from "../useLocaleContext"

// using react hook in function ?
// translate number
export default function tn(num) {
    // const { locale } = useLocaleContext()
    // if (locale === "ar") {
    //     return new Intl.NumberFormat('ar-EG').format(num)
    // }
    return num
}