import { useState } from "react"
import { useAuthContext } from "../useAuthContext"

export const useUpdateName = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()

    const updateName = async (id, name, middlename, surname) => {
        setIsLoading(true)
        setError(null)

        // send server request to update name
        const response = await fetch('https://www.luminous.delivery/api/user/update/name', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id, name, middlename, surname})
        })
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }
        if (response.ok) {
            // update the user's name
            // get the existing data
            let existing = localStorage.getItem('user')
            const prev = localStorage.getItem('user')

            // convert the localStorage string to an array
            existing = JSON.parse(existing)

            // add new data to localStorage Array
            existing["name"] = name

            // Save back to localStorage
            localStorage.setItem('user', JSON.stringify(existing))

            // update the auth context
            dispatch({type: 'LOGIN', payload: existing})

            setIsLoading(false)
        }
    }

    return { updateName, error, isLoading }
}