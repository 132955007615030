import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './account-css/Tracking.css'; // Ensure the path to the CSS file is correct

const Tracking = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); // This will take the user back to the previous page
    };

    return (
        <div className="tracking-container">
            <div className="tracking-content">
                <h1 className="tracking-title">{t('tracking')}</h1>
                <p className="tracking-message">{t('under_construction_message')}</p>
            </div>
            <div className="button-container">
                <button className="back-button" onClick={handleBackClick}>
                    {t("back_b")}
                </button>
            </div>
        </div>
    );
};

export default Tracking;
