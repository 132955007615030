import React, {useState} from 'react'
import {useTranslation} from "react-i18next"
import tn from "../../../hooks/functions/NumberToAr"

const AddPackageDetails = ({ index, state, handleChange, item }) => {
    const { t } = useTranslation()

    const [errors, setErrors] = useState({
        weight: "",
        height: "",
        width: "",
        length: "",
        declared_value: ""
    })

    const handleChangeDimensions = (field, val) => {
        // number regex that supports decimal numbers latin and arabic
        // const numberRegex = /^[0-9]+$/
        const numberRegex = /^[0-9]+(\.[0-9]+)?$/
        if (numberRegex.test(val)) {
            const item_dimensions = [...state.item_dimensions]
            const value = parseFloat(val)
            // if field is declared_value, set it to the value
            if (field === "declared_value") {
                item_dimensions[index] = {...item_dimensions[index], declared_value: value}
            }
            else if (field === "item_name") {
                // item_dimensions[index] = {...item_dimensions[index], item_name: value}
            }
            // else, set the field to the value in the dimensions object
            else {
                item_dimensions[index].dimensions = {...state.item_dimensions[index].dimensions, [field]: value}
            }
            handleChange("item_dimensions", item_dimensions)
            setErrors({ ...errors, [field]: "" })
        }
        else {
            setErrors({ ...errors, [field]: t("enter_valid_number") })
        }
    }

    return (
        <div>
            <h1>{t('item_details')}</h1>
            <h1>{tn(index + 1)}: {t('enter_weight_and_dimensions')}</h1>
            {/*form that lets the user enter the declared value of the item*/}
            <form>
                <label>{t('item_name')}</label>
                <br/>
                <input
                        type="text"
                        name="item_name"
                        placeholder={t('item_name_placeholder')}
                        defaultValue={state.item_dimensions[index].item_name}
                        // do rest of logic
                    />
                <br/>
                <p></p>

                <label>{t('declared_value')}</label>
                <br/>
                <input
                    type="number"
                    name="declared_value"
                    placeholder={t('enter_declared_value_placeholder')}
                    defaultValue={tn(state.item_dimensions[index].declared_value)}
                    onChange={(e) => handleChangeDimensions("declared_value", e.target.value)}
                />
                <p>{errors.declared_value}</p>
            </form>

            {/*form that lets the user enter the weight, width, length, height of the item*/}
            <form>
                <label>{t('weight')}</label>
                <br/>
                <input
                    type="number"
                    name="weight"
                    placeholder={t('enter_weight_placeholder')}
                    defaultValue={tn(state.item_dimensions[index].dimensions.weight)}
                    onChange={(e) => handleChangeDimensions("weight", e.target.value)}
                />
                <p>{errors.weight}</p>

                <label>{t('width')}</label>
                <br/>
                <input
                    type="number"
                    name="width"
                    placeholder={t('enter_width_placeholder')}
                    defaultValue={tn(state.item_dimensions[index].dimensions.width)}
                    onChange={(e) => handleChangeDimensions("width", e.target.value)}
                />
                <p>{errors.width}</p>

                <label>{t('length')}</label>
                <br/>
                <input
                    type="number"
                    name="length"
                    placeholder={t('enter_length_placeholder')}
                    defaultValue={tn(state.item_dimensions[index].dimensions.length)}
                    onChange={(e) => handleChangeDimensions("length", e.target.value)}
                />
                <p>{errors.length}</p>

                <label>{t('height')}</label>
                <br/>
                <input
                    type="number"
                    name="height"
                    placeholder={t('enter_height_placeholder')}
                    defaultValue={tn(state.item_dimensions[index].dimensions.height)}
                    onChange={(e) => handleChangeDimensions("height", e.target.value)}
                />
                <p className='errors'>{errors.height}</p>
            </form>

        </div>
    )
}

export default AddPackageDetails