import { useState } from "react"
import { useAuthContext } from "./useAuthContext"
import {useTranslation} from "react-i18next";

export const useLogin = (toastPromise = null) => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { dispatch } = useAuthContext()

    const { t } = useTranslation()

    const login = async (email, password) => {
        setIsLoading(true)
        setError(null)

        // login request to server with given credentials
        const response = await fetch('https://www.luminous.delivery/api/user/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email, password})
        })
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error || t('an_error_occurred'))
            throw new Error(json.error || t('an_error_occurred'))
        }
        if (response.ok) {
            // save the user to cookies
            const expires = new Date()
            expires.setDate(expires.getDate() + 7) // expires in 7 days
            document.cookie = `user=${JSON.stringify(json)}; expires=${expires.toUTCString()}; path=/`

            if (toastPromise) {
                await toastPromise()
            }

            // update the auth context
            dispatch({type: 'LOGIN', payload: json})

            setIsLoading(false)
        }
    }

    return { login, error, isLoading }
}