import React from "react"
import {useTranslation} from "react-i18next";
import tn from "../../hooks/functions/NumberToAr"


// card info in display format
const CardDetails = ({ card }) => {
    const { t } = useTranslation()
    return (
        <div className="acc-box acc-box1" >
            <div className="acc-desc" >
                <h3>{`${card.card} ${t('ending_in')} ${tn(card.number)}`}</h3>
                <p>{card.name}</p>
                <p>{card.billingAddress}</p>
            </div>
        </div>
    )
}

export default CardDetails