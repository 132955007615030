import React from "react"
import { useState } from "react";
import { useAccessLogin} from "../hooks/useAccessLogin";
import '../components/SignupComponents/SignupComponents-css/Signup.css'
import logo from '../assets/logoblack.svg'
import './pages-css/Demo.css'

const Demo = () => {
    const [password, setPassword] = useState('')

    const {accessLogin, error, isLoading} = useAccessLogin()

    const handleSubmit = async (e) => {
        e.preventDefault()

        await accessLogin(password)
    }

    return (
        <>
            <div className='signup-div'>
                <div className='login-page-demo'>
                    <img src={logo} alt="luminous-logo" className="demo-logo"></img>
                    <form className="form-demo" onSubmit={handleSubmit}>
                        <input
                            type="password"
                            placeholder="Enter Demo Password"
                            onChange = {(e) => setPassword(e.target.value)}
                            value={password}
                        />

                        <button disabled={isLoading}>Submit</button>
                        {error && <div className="error">{error}</div>}
                    </form>
                </div>
            </div>
        </>
    )
}

export default Demo