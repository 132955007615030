import React from "react"
import { useState } from "react"
import { useRef } from "react"

const AccordionItem = ({ faq }) => {

  const [clicked, setClicked] = useState(false)
  const contentEl = useRef()

  const { question, answer, link, rest } = faq

  const finalAnswer = link ? <span>{answer} {link} {rest}</span> : <span>{answer}</span>


  const handleToggle = () => {
    setClicked((prev) => !prev)
  }

  return (
    <li className={`accordion_item ${clicked ? "active" : ""}`}>
      <button className="button" onClick={handleToggle}>
        {question}
        <span className="control">{clicked ? "—" : "+"} </span>
      </button>

      <div
        ref={contentEl}
        className="answer_wrapper"
        style={
          clicked
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="answer">{finalAnswer}</div>
      </div>
    </li>
  )
}

export default AccordionItem