import { useState } from "react"

// state:
// 0 -> new
// 1 -> paid
// 2 -> delivered

// orderid:
// null if state == 0
// else, id of the order
export const useUpdateOrders = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)

    const updateOrders = async (id, order, state, orderid) => {
        setIsLoading(true)
        setError(null)

        // if (state === 0) {
        //     order.paid = false
        //     order.delivered = false
        // }
        // else if (state === 1) {
        //     order.paid = true
        //     order.delivered = false
        // }
        // else if (state === 2) {
        //     order.paid = true
        //     order.delivered = true
        // }

        // post the new order to the server
        const response = await fetch('https://www.luminous.delivery/api/user/update/orders', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id, order, state, orderid})
        })
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
            return 0
        }
        if (response.ok) {
            setIsLoading(false)
            return json.idOrder
        }
    }

    return { updateOrders, error, isLoading }
}