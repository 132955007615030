import React from "react"
import { useState } from "react";
import { useLogin} from "../hooks/useLogin";
import '../components/SignupComponents/SignupComponents-css/Signup.css'
import Navbar from '../components/Navbar'
import {useTranslation} from "react-i18next"
import logo_center from '../assets/blacklogo.svg'
import {Link, Navigate, useNavigate} from "react-router-dom";
import logo from '../assets/logoblack.svg'
import logo_rtl from '../assets/logoblackrtl.svg'
import './../components/components-css/Navbar.css'
import { useLocaleContext } from "../hooks/useLocaleContext"
import LanguageMenu from "../components/LanguageMenu"
import toast, {Toaster} from "react-hot-toast"
import {useAuthContext} from "../hooks/useAuthContext";
import login_icon from '../assets/login_icon.svg'
import box_icon from '../assets/box_logo.png'


const Login = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const load = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    )

    const toastPromise = async () => {
        toast.dismiss()
        toast.success(t('logged_in_successfully'))
        await load(getRndInteger(1000, 2000))
        navigate('/account')
    }

    // double check isLoading vs isloading
    const {login, error, isLoading} = useLogin(toastPromise)

    // translate
    const { t } = useTranslation()
    const { locale } = useLocaleContext()

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        toast.dismiss()

        // toast.loading(t('loading'))
        // await load(getRndInteger(500, 1200))

        // document.documentElement.style.zoom = '100%'

        try {
            await login(email, password)
        }
        catch (err) {
            toast.dismiss()
            toast.error(t(err.message))
        }
    }

    return (
        <>
        {/*<Navbar/>*/}
            <div className='header-signup'>
                <div className='leftItems'>
                    <Link to="/">
                        <img className='logo-login-corner' src={locale === 'ar' ? logo_rtl : logo} />
                    </Link>
                </div>
                <div className='rightItems'>
                    <div className='language-menu-login'>
                        <LanguageMenu />
                    </div>
                    <p className='login-header-text'>{t('not_a_member_yet')}
                        <a href="/signup"> {t('join')}</a>
                    </p>
                </div>
            </div>
            <div className='signup-div'>
                <div className='login-page'>
                    {/*<img src={logo_center} alt="logo" className="logo-login"/>*/}
                    {/*<img src={login_icon} alt="logo" className="logo-login"/>*/}
                    <img src={box_icon} alt="logo" className="logo-login"/>
                    <form className="form" onSubmit={handleSubmit}>
                        <h5 className="toptext">{t('log_in')}</h5>
                        <p>{t('email')}</p>
                        <input
                            placeholder={t('email_address')}
                            type="email"
                            onChange = {(e) => setEmail(e.target.value)}
                            value={email}
                        />

                        <br/>

                        <p>{t('password')}</p>
                        <input
                            placeholder={t('password')}
                            type="password"
                            onChange = {(e) => setPassword(e.target.value)}
                            value={password}
                        />

                        <button disabled={isLoading}>{t('log_in')}</button>
                        {/*{error && <div className="error">{error}</div>}*/}

                        <p className="message">{t('dont_have_account')}
                                <a href="/signup"> {t('sign_up')}</a>
                        </p>
                    </form>
                </div>
            </div>
            <Toaster/>
        </>
    )
}

export default Login