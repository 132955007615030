import React, { useState } from "react"
import './pages-css/About.css'
import Navbar from '../components/Navbar'
import sideArrow from '../assets/arrow_side.png'
import Step from '../components/AboutComponents/Step'
import GetAddressFooter from "../components/GetAddressFooter"
import {useLocaleContext} from "../hooks/useLocaleContext"
import {useTranslation} from "react-i18next"

const About = () => {

    const { locale } = useLocaleContext()
    const { t } = useTranslation()

    const [step, setStep] = useState(0)

    let handleClickForward = () => {
        const nextStep = step + 1
        if (nextStep <= 4) {
            setStep(nextStep)
        }
        else {
            setStep(0)
        }
    }

    let handleClickBackward = () => {
        const prevStep = step - 1
        if (prevStep >= 0) {
            setStep(prevStep)
        }
        else {
            setStep(4)
        }
    }

    if (locale === 'ar') {
        const temp = handleClickForward
        handleClickForward = handleClickBackward
        handleClickBackward = temp
    }

    // loop through steps
    switch(step) {
        case 0:
            return (
                <>
                    <Navbar/>
                    <div className="about-container">
                        <div className="about">
                            <div className="about-header">
                                <p>{t("about_header")}</p>
                            </div>
                            <div className="about-body">
                                <p>{t("about_body")}</p>
                            </div>
                        </div>
                        <div className="about-progress">
                            <div className="side-arrow">
                                <img
                                    src={sideArrow}
                                    className={locale === 'en' ? "side-arrow-img-left" : "side-arrow-img"}
                                    onClick={handleClickBackward}
                                />
                            </div>
                            <p className="about-progress-num">{locale === 'en' ? `1/5` : `1/5`}</p>
                            <div className="side-arrow">
                                <img
                                    src={sideArrow}
                                    className={locale === 'en' ? "side-arrow-img" : "side-arrow-img-left"}
                                    onClick={handleClickForward}
                                />
                            </div>
                        </div>
                    </div>
                    <GetAddressFooter/>
                </>
            )
        case 1:
            return <Step
                step={step}
                body={t("about_body_step_1")}
                handleClickForward={handleClickForward}
                handleClickBackward={handleClickBackward}
            />
        case 2:
            return <Step
                step={step}
                body={t("about_body_step_2")}
                handleClickForward={handleClickForward}
                handleClickBackward={handleClickBackward}
            />
        case 3:
            return <Step
                step={step}
                body={t("about_body_step_3")}
                handleClickForward={handleClickForward}
                handleClickBackward={handleClickBackward}
            />
        case 4:
            return <Step
                step={step}
                body={t("about_body_step_4")}
                handleClickForward={handleClickForward}
                handleClickBackward={handleClickBackward}
            />

    }

}

export default About