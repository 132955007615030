import React, {useState} from "react"
import {useTranslation} from "react-i18next";
import './../../SignupComponents/SignupComponents-css/Signup.css'
import './ProfileSettingsComponents-css/ProfileSettingsComponents.css'
import './../../../pages/Account/account-css/account.css'
// add extra email but no change ?
// add secondary email -> later feature

// import {useUpdateEmail} from "../../../hooks/profileHooks/useUpdateEmail"
// import {useState} from "react"


// NYI
const InfoEmail = ({ email, prevStep }) => {
    const { t } = useTranslation()

    const [errors, setErrors] = useState({ email: ""})

    // const {updateEmail, error, isLoading} = useUpdateEmail()
    //
    // const [newEmail, setNewEmail] = useState('')
    //
    // const handleChange = e => {
    //     setNewEmail(e.target.value)
    // }
    //
    // const update = async (e) => {
    //     e.preventDefault()
    //
    //     await updateEmail(id, newEmail)
    // }

    return (
        <div className='form-container'>
            <br/>
            <h5 className='toptext'>
                {t('lum_account_email')}:
            </h5>
            <p className='display-text'>
                {email}
            </p>

            {/*<form className='login-form'>*/}
            {/*    <h5 className='toptext'>*/}
            {/*        Add Secondary Email*/}
            {/*    </h5>*/}
            {/*    /!* email *!/*/}
            {/*    <input*/}
            {/*        placeholder="Email"*/}
            {/*        name="email"*/}
            {/*        onChange={handleChange}*/}
            {/*    />*/}
            {/*    <br/>*/}
            {/*</form>*/}

            <div className="buttons-box">
                <button className='change-button'
                        onClick={prevStep}
                        type="submit"
                >
                    {" <  "}
                </button>

                {/*<button className='save'*/}
                {/*        onClick={update}*/}
                {/*        type="submit"*/}
                {/*>*/}
                {/*    Save*/}
                {/*</button>*/}

            </div>
        </div>
    )
}

export default InfoEmail