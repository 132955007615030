import React, {Suspense, useState} from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useAuthContext } from "./hooks/useAuthContext"
import {useAccessAuthContext} from "./hooks/useAccessAuthContext"
import i18n from './language/i18n'

// pages and components
import GetAddress from './pages/GetAddress'
import About from './pages/About'
import Rates from './pages/Rates'
import Login from "./pages/Login"
import Signup from "./pages/Signup"
import LiveChat from "./components/LiveChat"
import Support from "./pages/Support"
import Account from './pages/Account/account'
import Footer from "./components/Footer"
import ProfileSettings from "./pages/Account/ProfileSettings"
import PaymentMethod from "./pages/Account/PaymentMethod"
import OrderHistory from "./pages/Account/OrderHistory"
import MyAddress from "./archive/MyAddress"
import MyPackages from "./pages/Account/MyPackages"
import SetupStatus from "./pages/Account/SetupStatus"
import LocaleContext from "./context/LocaleContext"
import Loading from "./components/Loading"
import {Helmet} from "react-helmet"
import {ThemeProvider} from "react-bootstrap"
import Faqs from "./pages/FAQs"
import Terms from "./pages/Terms"
import TermsConditions from "./pages/TermsConditions"
import Privacy from "./pages/Privacy"
import Demo from "./pages/Demo"
import Careers from "./pages/Careers"
import ContactUs from "./pages/ContactUs"
import Extension from "./pages/Extension"
import CheckoutExtensionOrder from "./pages/Account/CheckoutExtensionOrder"
import OrderConfirmation from "./pages/Account/OrderConfirmation"
import Tracking from './pages/Account/Tracking'; // Import the Tracking component



function App() {
    const { user } = useAuthContext()
    let { demo } = useAccessAuthContext()
    demo = !null

    const [locale, setLocale] = useState(i18n.language)
    if (locale !== 'ar' && locale !== 'en') {
        setLocale('en')
    }
    i18n.on('languageChanged', (lng) => setLocale(i18n.language))

    if (!demo) {
        return (
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="/demo" element={<Demo />} />
                        <Route path="/" element={<Navigate to="/demo" />} />
                    </Routes>
                </BrowserRouter>
            </div>
        )
    }

    return (
    <div className="App">
        <LocaleContext.Provider value={{locale, setLocale}}>
            <Suspense fallback={<Loading />}>
                <Helmet htmlAttributes={{
                    lang: locale,
                    dir: locale === 'ar' ? 'rtl' : 'ltr'
                }}/>
                <ThemeProvider dir={locale === 'ar' ? 'rtl' : 'ltr'}>
      <BrowserRouter>
        <div className="pages">
          <Routes>
              <Route
                  path="/demo"
                  element={!demo ? <Demo /> : <Navigate to="/" />}
              />
              <Route
                  // change path when have homepage
              path="/"
              element={<GetAddress />}
              />
              <Route
                  path="/about"
                  element={<About />}
              />
              <Route
                  path="/rates"
                  element={<Rates />}
              />
              <Route
                  path="/support"
                  element={<Support />}
              />
              <Route
                  path="/careers"
                  element={<Careers />}
              />
              <Route
                  path="/contact-us"
                  element={<ContactUs />}
              />
              <Route
                  path="/login"
                  element={!user ? <Login /> : <Navigate to="/" />}
              />
              <Route
                  path="/account"
                  // double safety cause page also has this
                  // element={user ? <Account/> : <Navigate to="/login" />}
                  element={<Account/> }
              />
              <Route
                  path="/account/profile-settings"
                  // element={user ? <ProfileSettings/> : <Navigate to="/login" />}
                  element={<ProfileSettings/>}
              />
              <Route
                  path="/account/payment-method"
                  // element={user ? <PaymentMethod/> : <Navigate to="/login" />}
                  element={<PaymentMethod/>}
              />
              <Route
                  path="/account/payment-method/payment-status"
                  element={user ? <SetupStatus/> : <Navigate to="/login" />}
              />
              <Route
                  path="/account/order-history"
                  // element={user ? <OrderHistory/> : <Navigate to="/login" />}
                  element={<OrderHistory/>}
              />
              {/*<Route*/}
              {/*    path="/account/my-address"*/}
              {/*    element={user ? <MyAddress/> : <Navigate to="/login" />}*/}
              {/*/>*/}
              <Route
                  path="/account/my-packages"
                  // element={user ? <MyPackages/> : <Navigate to="/login" />}
                  element={<MyPackages/>}
              />
              <Route
                  path="/account/checkout-order"
                  element={<CheckoutExtensionOrder/>}
              />
              <Route
                  path="/account/order-confirmation"
                  element={<OrderConfirmation />}
              />
              <Route
                  path="/signup"
                  element={!user ? <Signup /> : <Navigate to="/" />}
              />
              <Route
                  path="/faq"
                  element={<Faqs />}
              />
              <Route
                  path="/terms"
                  element={<Terms />}
              />
              <Route
                  path="/termsconditions"
                  element={<TermsConditions />}
              />
              <Route
                  path="/privacy"
                  element={<Privacy />}
              />

                <Route
                  path="/extension"
                  element={<Extension />}
              />
              <Route
                  path="/account/tracking"
                  element={<Tracking />}
              />


          </Routes>
        </div>
          {/*{<LiveChat />}*/}
          {/* <Footer/>*/}
      </BrowserRouter>
                </ThemeProvider>
            </Suspense>
        </LocaleContext.Provider>
    </div>
  )
}

export default App
