import React from "react"
import './pages-css/FAQs.css'
// import video from '../assets/cityscape.mp4'
import Navbar from '../components/Navbar'
import Accordion from '../components/Accordion/Accordion'
import {faqs} from '../components/Accordion/data'
import {faqs_ar} from '../components/Accordion/data-ar'
import {useTranslation} from "react-i18next"
import {useLocaleContext} from "../hooks/useLocaleContext"
import GetAddressFooter from "../components/GetAddressFooter";

const Faqs = () => {
    const { t } = useTranslation()
    const { locale } = useLocaleContext()

    return (
    <>
    <Navbar/>
        <div>
            <div className='faqs-headerbox'>
                <h1 className="faqs-header">{t('faq')}</h1>
                {/*will need to change faq sources when this and main page faq data is different*/}
                <Accordion data={locale === 'ar' ? faqs_ar : faqs}/>
            </div>

            <GetAddressFooter/>
        </div>
    </>
    )
}

export default Faqs
