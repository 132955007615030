import React from 'react'
import './pages-css/terms.css'
import Navbar from '../components/Navbar'
import Accordion from '../components/Accordion/Accordion'
import {faqs} from '../components/Accordion/data3'
import {useTranslation} from "react-i18next"

const Terms = () => {
    const { t } = useTranslation()

    return (
    <>
    <Navbar/>
        <div>
            <div className='terms-headerbox'>
                <h1 className="terms-header">{t('terms_and_conditions')}</h1>
                <Accordion data={faqs}/>
            </div>


        </div>
    </>
    )
}

export default Terms