import React, {useEffect, useState} from 'react'
import OrderDetails from "../../components/AccountComponents/OrderDetails";
import {Elements} from "@stripe/react-stripe-js";
import ExtensionCheckout from "../../components/AccountComponents/ExtensionCheckout";
import {loadStripe} from "@stripe/stripe-js";
import './account-css/MyPackages.css'
import cardStringSelect from "../../hooks/functions/CardStringSelect";
import Select from "react-select";


const pk = 'pk_test_51PP2iV04mzujkqL4DUufHEFfMIRt1h7olShU6FgXL9kB8LHpTH4awvPZTASPqSbpTlgKYsVlufcaRye2JYQvD7fM002aW21TLl'
const stripePromise = loadStripe(pk)

const CheckoutExtensionOrder = () => {

    // should only be opened if the user is redirected by the extension
    // validate the data in the url
    // make sure url has valid data and the timestamp is not too old
    // if the data is valid, show the user the order details with a stripe element wrapper and ask them to confirm
    // format of encoded data to get from the url:
    // const data = {
    //     id: user.id,
    //     cardid: state.card.id,
    //     order: state.order,
    //     address: state.address,
    //     orderTime: time
    // }

    const [orderData, setOrderData] = useState(null)
    const [error, setError] = useState('Confirming order...')

    const [cards, setCards] = useState([])
    const [displayCards, setDisplayCards] = useState([])
    const [loadedGet, setLoadedGet] = useState(false)
    const [inputValueCard, setInputValueCard] = useState(null)
    const [selectedCard, setSelectedCard] = useState(null)

    const handleChangeSelectedCard = (selected) => {
        setInputValueCard(selected)
        setSelectedCard(cards[selected.value])
    }

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const data = JSON.parse(decodeURIComponent(urlParams.get('data')))
        setOrderData(data)

        console.log(data)

        // validate the data
        // time
        const timeDiff = Date.now() - data.orderTime
        // more than 30 minutes
        if (timeDiff > 1800000) {
            // error
            setError('The order data is too old. Please try again.')
            return
        }

        // user id
        // ping the server to check if the user id is valid

        // card id with the user id
        const fetchPackages = async () => {
            const response = await fetch(`https://www.luminous.delivery/api/user/packages/${data.id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${data.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                // setAddresses(json.addresses)
                // const addressStrings = []
                // json.addresses.forEach(function (address, index) {
                //     const addressDetail = addressStringSelect(address, index)
                //     addressStrings.push(addressDetail)
                // })
                // setDisplayAddresses(addressStrings)

                setCards(json.cardList)
                const cardStrings = []
                json.cardList.forEach(function (card, index) {
                    const cardString = cardStringSelect(card, index)
                    cardStrings.push(cardString)
                })
                setDisplayCards(cardStrings)

                setLoadedGet(true)
            }
        }

        fetchPackages()

        setError(null)

    }, [])

    if (error) {
        return (
            <div>
                <h1>{error}</h1>
            </div>
        )
    }
    return (
        <div className='case6box'>
            <h1>Checkout Extension Order</h1>
            <OrderDetails order={orderData.order} />
            <h2>Payment Method</h2>
            <Select
                className="cards"
                options={displayCards && displayCards}
                onChange={handleChangeSelectedCard}
                defaultValue={inputValueCard}
                isMulti={false}
                placeholder="Select card"
            />
            <div>
                {loadedGet && inputValueCard && selectedCard &&
                <Elements stripe={stripePromise}>
                    <ExtensionCheckout card={selectedCard} orderData={orderData} />
                </Elements>}
            </div>
        </div>
    )
}

export default CheckoutExtensionOrder
