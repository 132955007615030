import React, {useState} from 'react'
import {useTranslation} from "react-i18next"
import tn from "../../../hooks/functions/NumberToAr"
import { isNumeric } from "../../../hooks/functions/RegexCheck";

const AddPackageDetailsRates = ({ index, state, handleChange, item }) => {
    const { t } = useTranslation()

    const [errors, setErrors] = useState({
        weight: "",
        height: "",
        width: "",
        length: "",
    })

    const handleChangeDimensions = (field, val) => {
        // number regex that supports decimal numbers
        // const numberRegex = /^[0-9]+$/
        const numberRegex = /^[0-9]+(\.[0-9]+)?$/
        if (numberRegex.test(val)) {
            const item_dimensions = [...state.item_dimensions]
            const value = parseFloat(val)
            // update the relevant field in the item's dimension struct
            item_dimensions[index].dimensions = {...state.item_dimensions[index].dimensions, [field]: value}
            handleChange("item_dimensions", item_dimensions)

            setErrors({ ...errors, [field]: "" })
        }
        else {
            setErrors({ ...errors, [field]: t("enter_valid_number") })
        }
    }

    return (
        <div>
            <h1>{t('item_details')}</h1>
            <h1>{tn(index + 1)}: {t('enter_weight_and_dimensions')}</h1>
            {/*form that lets the user enter the weight, width, length, height of the item*/}
            <form>
                <label>{t('weight')}</label>
                <br/>
                <input
                    type="number"
                    name="weight"
                    placeholder={t('enter_weight_placeholder')}
                    defaultValue={tn(state.item_dimensions[index].dimensions.weight)}
                    onChange={(e) => handleChangeDimensions("weight", e.target.value)}
                />
                <p>{errors.weight}</p>

                <label>{t('width')}</label>
                <br/>
                <input
                    type="number"
                    name="width"
                    placeholder={t('enter_width_placeholder')}
                    defaultValue={tn(state.item_dimensions[index].dimensions.width)}
                    onChange={(e) => handleChangeDimensions("width", e.target.value)}
                />
                <p>{errors.width}</p>

                <label>{t('length')}</label>
                <br/>
                <input
                    type="number"
                    name="length"
                    placeholder={t('enter_length_placeholder')}
                    defaultValue={tn(state.item_dimensions[index].dimensions.length)}
                    onChange={(e) => handleChangeDimensions("length", e.target.value)}
                />
                <p>{errors.length}</p>

                <label>{t('height')}</label>
                <br/>
                <input
                    type="number"
                    name="height"
                    placeholder={t('enter_height_placeholder')}
                    defaultValue={tn(state.item_dimensions[index].dimensions.height)}
                    onChange={(e) => handleChangeDimensions("height", e.target.value)}
                />
                <p className='errors'>{errors.height}</p>
            </form>

        </div>
    )
}

export default AddPackageDetailsRates