import React from "react"
import {useAuthContext} from "../../hooks/useAuthContext"
import {useEffect, useState} from "react"

import InfoEmail from "../../components/AccountComponents/ProfileSettingsComponents/InfoEmail"
import InfoName from "../../components/AccountComponents/ProfileSettingsComponents/InfoName"
import InfoAddresses from "../../components/AccountComponents/ProfileSettingsComponents/InfoAddresses"
import InfoMobile from "../../components/AccountComponents/ProfileSettingsComponents/InfoMobile"
import InfoPassword from "../../components/AccountComponents/ProfileSettingsComponents/InfoPassword"
import {Link, Navigate} from "react-router-dom"

import { Wrapper,AccNavbar,Content } from "./account.style"
import Row from '../../components/AccountComponents/Row.js'
import Navbar from "../../components/Navbar"

import './account-css/account.css'
import addressString from "../../hooks/functions/AddressString";
import {useTranslation} from "react-i18next";

const ProfileSettings = () => {

    const {user} = useAuthContext()

    const { t } = useTranslation()

    const [step, setStep] = useState(0)

    const [userData, setUserData] = useState(null)

    const [isLoaded, setIsLoaded] = useState(false)

    // gets middlename, surname, mobile, shipping addresses
    useEffect(() => {
        const fetchUserData = async () => {
            const response = await fetch(`https://www.luminous.delivery/api/user/profile/${user.id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                setUserData(json)
            }
        }

        if (user) {
            fetchUserData().then(r => setIsLoaded(true))
        }
    }, [user])

    if (!user) {
        return (
            <div>
                <Navigate to='/signup' replace="true"/>
            </div>
        )
    }

    const back = () => {
        setStep(0)
    }

    if (isLoaded) {
        const currentAddress = addressString(userData.shippingAddresses[0])

        // switch between initial profile page and other account info pages
        switch(step) {
            case 0:
                return (
                    <div>
                    <Navbar/>
                    <Wrapper className="acc">
                    <Row/>
                    <Content>
                        <div className="basic-info">
                            <h1>{t('basic_info')}</h1>
                            <span>
                            {userData.middlename ?
                                <button
                                    className='change-button'
                                    onClick={() => setStep(1)}
                                    type="submit"
                                >
                                    {`${t('name')}:  ${user.name} ${userData.middlename} ${userData.surname} >`}
                                </button>
                                :
                                <button
                                    className='change-button'
                                    onClick={() => setStep(1)}
                                    type="submit"
                                >
                                    {`${t('name')}:  ${user.name} ${userData.surname} >`}
                                </button>}
                        </span>
                        </div>
                        <div className="contact-info">
                            <h1>{t('contact_info')}</h1>
                            <span>
                                <button
                                    className='change-button'
                                    onClick={() => setStep(2)}
                                    type="submit"
                                >
                                    {`${t('email_address')}:  ${user.email}  >`}
                                </button>
                            </span>

                            <br/>
                            <br/>

                            <span>
                            <button
                                className='change-button'
                                onClick={() => setStep(3)}
                                type="submit"
                            >
                                {`${t('mobile')}:  ${userData.mobile}  >`}
                            </button>
                            </span>
                        </div>
                        <div className="addresses-info">
                            <h1>{t('addresses')}</h1>
                            <span>
                            <button
                                className='change-button'
                                onClick={() => setStep(4)}
                                type="submit"
                            >
                                {/*may need to parse the address string or address details component to display*/}
                                {/*probably later*/}
                                {/*also, logic: when user chooses an address as current, put it*/}
                                {/*in the beggining of the address list*/}
                                {/*NYI*/}
                                {`Currently Active Address:  ${currentAddress}  >`}
                            </button>
                        </span>
                        </div>
                        <div className="password-info">
                            <h1>{t('password')}</h1>
                            <span>
                            <button
                                className='change-button'
                                onClick={() => setStep(5)}
                                type="submit"
                            >
                                {`${t('change_password')} >`}
                            </button>
                        </span>
                        </div>
                        <br/>
                        <Link to="/account" className='back-to-dashboard'>{t('back')}</Link>

                        {/*TODO: BRANDON frontend*/}
                        {/*TODO: EMRE backend*/}
                        {/*<div>*/}
                        {/*    <button*/}
                        {/*        className='change-button'*/}
                        {/*        // className='delete-account'*/}
                        {/*        // onClick={}*/}
                        {/*        type="submit"*/}
                        {/*    >*/}
                        {/*        {t('permanently_delete_account')}*/}
                        {/*    </button>*/}
                        {/*</div>*/}

                        </Content>
                        </Wrapper>
                        </div>
                )

            case 1:
                return (
                    <div>
                        <InfoName
                            id={user.id}
                            prevStep={ back }
                            name={user.name}
                            middlename={userData.middleName}
                            surname={userData.surname}
                        />
                    </div>
                )
            case 2:
                return (
                    <InfoEmail
                        prevStep={ back }
                        email={user.email}
                    />
                )
            case 3:
                return (
                    <InfoMobile
                        id={user.id}
                        prevStep={ back }
                        mobile={userData.mobile}
                    />
                )
            case 4:
                return (
                    <InfoAddresses
                        id={user.id}
                        prevStep={ back }
                        addresses={userData.shippingAddresses}
                    />
                )
            case 5:
                return (
                    <InfoPassword
                        id={user.id}
                        prevStep={ back }
                    />
                )
            default:
            //
        }
    }
    else {
        return (
            <div>
                <h1>Loading</h1>
            </div>
        )
    }
}

export default ProfileSettings