// name change, not sure if should be allowed, google lets u do it
import React from "react"
import {useState} from "react"
import {useUpdateName} from "../../../hooks/profileHooks/useUpdateName"
import {Navigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import validator from "validator";
import './../../SignupComponents/SignupComponents-css/Signup.css'
import './../../../pages/Account/account-css/account.css'
import './ProfileSettingsComponents-css/ProfileSettingsComponents.css'


const InfoName = ({ id, prevStep, name, middlename, surname }) => {
    const { t } = useTranslation()

    const [errors, setErrors] = useState({ name: "", middleName: "", surname: "" })

    const {updateName, error, isLoading} = useUpdateName()
    const [completed, setCompleted] = useState(false)

    const [newName, setNewName] = useState(
        {
            name: name,
            middlename: middlename,
            surname: surname
        }
    )

    const handleChange = input => e => {
        newName[input] = e.target.value
        setNewName(newName)
    }

    const update = async (e) => {
        e.preventDefault()
        // const error?
        let error = {}
        const ignoreSpacesRegex2 = /^[a-zA-Z\\s]+$/
        const correctName = validator.isAlpha(newName.name)
        const correctMiddleName = !newName.middleName || ignoreSpacesRegex2.test(newName.middleName)
        const correctSurname = validator.isAlpha(newName.surname)

        if (correctName && correctMiddleName && correctSurname) {
            // send name update request to server
            await updateName(id, newName.name, newName.middlename, newName.surname)
            setCompleted(true)
        }
        else {
            // error checking
            if (!correctName) {
                error.name = 'Name must contain only letters'
            }
            if (!correctMiddleName) {
                error.middleName = 'Middle name must contain only letters'
            }
            if (!correctSurname) {
                error.surname = 'Surname must contain only letters'
            }
            setErrors(error)
        }
    }

    if (completed) {
        if (error) {
            setCompleted(false)
        }
        else {
            return (
                <div>
                    <Navigate to='/account' replace="false"/>
                </div>
            )
        }
    }

    return (
        <div className='form-container'>
            <br/>
            <form className='login-form'>
                <h5 className='toptext'>
                    {t('name')}
                </h5>
                {/* first name */}
                <input
                    placeholder={t('first_name')}
                    name="name"
                    onChange={handleChange('name')}
                    defaultValue={name}
                />
                <p className='error'>{errors.name && errors.name}</p>
                <br/>
                {middlename && (
                    <div>
                        <h5 className='toptext'>
                            {t('middle_name')}
                        </h5>
                        {/* middle name */}
                        <input
                            placeholder={t('middle_name')}
                            name="middlename"
                            onChange={handleChange('middlename')}
                            defaultValue={middlename}
                        />
                        <p className='error'>{errors.middlename && errors.middleName}</p>
                        <br/>
                    </div>
                )}
                <h5 className='toptext'>
                    {t('last_name')}
                </h5>
                {/* last name */}
                <input
                    placeholder={t('last_name')}
                    name="surname"
                    onChange={handleChange('surname')}
                    defaultValue={surname}
                />
                <p className='error'>{errors.surname && errors.surname}</p>
            </form>

            <div className="buttons-box">
                <button className='change-button'
                        onClick={prevStep}
                        type="submit"
                >
                    {" <  "}
                </button>
                {/*is isLoading an issue?*/}
                <button className='change-button'
                        disabled={isLoading}
                        onClick={update}
                        type="submit"
                >
                    {t('save')}
                </button>
                {error && <div className="error">{error}</div>}
            </div>
        </div>
    )
}

export default InfoName