import React, {useEffect, useState} from 'react'
import {useAuthContext} from "../../hooks/useAuthContext"
import {Link, Navigate} from "react-router-dom"
import WarehouseDetails from "../WarehouseDetails"
import {useTranslation} from "react-i18next"

// maybe add address as a prop
const MyDeliveryAddress = () => {
    const {user} = useAuthContext()

    const { t } = useTranslation()

    // get wh data
    const [warehousePrio, setWarehousePrio] = useState(null)
    // const [isLoading, setIsLoading] = useState(false);

    // gets past orders
    useEffect(() => {
        const fetchPrioWarehouse = async () => {
            const response = await fetch('https://www.luminous.delivery/api/warehouses/priority/one', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })

            // add error checking to others?
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`)
            }

            const json = await response.json()

            if (response.ok) {
                setWarehousePrio(json[0])
            }
        }

        if (user) {
            fetchPrioWarehouse()
        }
    }, [user])

    if (!user) {
        return (
            <div>
                <Navigate to='/login' replace="true"/>
            </div>
        )
    }

    return (
        <div>
            {warehousePrio ?
                <WarehouseDetails
                    warehouse={warehousePrio}
                    nameBool={true}
                    user={user}
                /> : <h1>{t('generating_your_address')}</h1>}
        </div>
    )
}

export default MyDeliveryAddress