import React from "react"
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js'
import {useState} from "react"
import {useUpdateCards} from "../../hooks/useUpdateCards"
import {useTranslation} from "react-i18next";

const AddCardStripe = ({ id }) => {
    const stripe = useStripe()
    const elements = useElements()

    const [errorMessage, setErrorMessage] = useState(null)

    const { t } = useTranslation()

    const handleSubmit = async (e) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        e.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }


        // handle promise returned by confirmSetup
        const {error} = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            // make this the status page when public
            confirmParams: {
                // redirect
                return_url: "https://www.luminous.delivery/account/payment-method",
            },
        })


        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message)
        }
        else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <button disabled={!stripe}>{t('submit')}</button>
                {/* Show error message to your customers */}
                {errorMessage && <div>{errorMessage}</div>}
            </form>
        </div>
    )
}

export default AddCardStripe