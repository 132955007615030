import React from 'react'
import Navbar from '../components/Navbar'
import './pages-css/termsconditions.css'

import GetAddressFooter from "../components/GetAddressFooter";
import {useLocaleContext} from "../hooks/useLocaleContext";

const TermsConditions = () => {
    const { locale } = useLocaleContext()

    if (locale === 'en') {
        return(
            <>
                <Navbar/>
                <div className='tc'>
                    <h1>Terms & Conditions</h1>
                    <p>These terms allow us to carefully outline our relationship with the user and our various partners. They guide our transactions and allow us to give third parties an informed, clear perspective on how Luminous does business.</p>
                    <h2>Introduction</h2>
                    <p>These terms and conditions apply to your  use of Luminous, including any use of the services or content. By accessing or using this website, you accept all the terms and conditions specified below. If Luminous makes any such changes or modifications, your use of Luminous or reading of these terms and conditions afterward constitutes your acceptance of those changes and revisions. Additional terms and conditions may apply for using particular functions, features, data, and applications made available through Luminous and may be provided elsewhere on the website.</p>
                    <h2>Disclaimer of Warranty</h2>
                    <p>Luminous makes no guarantees that Luminous, its content, or any service it provides will satisfy all of your requirements, or that they will operate without interruption or error, or that any defects in Luminous, their content, or any Luminous services will be fixed.</p>
                    <h2>Limitation of Liability</h2>
                    <p>Under no circumstances will any of the Luminous parties be liable for any loss or damage caused by your reliance on information obtained through this site. It is your responsibility to evaluate the accuracy, completeness, or usefulness of this site. In no event shall any of the Luminous parties be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out or relating to the site, the content or this agreement, whether based on warranty, contract, tort, or any other legal theory.</p>
                    <p>In order to claim for damage to shipped items, you must identify all packages as “Fragile” upon order from Luminous.</p>
                    <h2>Termination of Use</h2>
                    <p>Even if access is still granted to other users, Luminous has the right to block, cancel, or suspend your access along with any other user’s access to Luminous at any time without prior notice and for any reason at its sole discretion.</p>
                    <br></br>
                    <h1>Service Agreement</h1>
                    <h2>Payment</h2>
                    <p>The user will be charged for all Luminous shipping orders. Acceptable methods of payment are Credit Card, Debit Card, and Apple Pay. There is a Luminous Service Fee for each order successfully processed. For each delivery, Luminous will charge 5 USD, on top of the regular shipping expense.</p>
                    <h2>Indemnity</h2>
                    <p>You agree to indemnify and hold Luminous, its officers, directors, shareholders, predecessors, successors in interest, employees, agents, subsidiaries and affiliates, harmless from any demands, loss, liability, claims or expenses (including attorneys’ fees), made against Luminous by any third party due to or arising out of or in connection with your use of the website.</p>
                    <h2>Export</h2>
                    <p>You accept full responsibility for adhering to all applicable laws and regulations of the United States, including but not limited to the International Traffic in Arms Regulations, the U.S. Export Administration Regulations, and the economic sanctions programs managed by the U.S. Treasury Department's Office of Foreign Assets Control, as well as those of any other country from which you may access Luminous.</p>
                    <h2>Complete Agreement</h2>
                    <p>These Terms & Conditions represent the parties' final understanding with regard to the subject matter, and they replace any and all prior or subsequent talks, statements, understandings, representations, or agreements, whether written or oral
                        Without Luminous's explicit approval, no modification or alteration to this Agreement shall be enforceable against Luminous.</p>
                    <h2>Contact Us</h2>
                    <p>If you have any questions regarding these Terms, you may contact us at terms@luminous.delivery.</p>
                    <p>These Terms are effective and were last updated on February 28, 2024.</p>
                </div>
                <GetAddressFooter/>
            </>
        )
    }
    else if (locale === 'ar') {
        return(
            <>
                <Navbar/>
                <div className='tc'>
                    <h1>ضوابط وشروط الإستخدام</h1>
                    <p>تتيح لنا هذه الشروط وضوابط الإستخدام تحديد علاقتنا مع المستخدم و مختلف شركائنا بعناية. تقوم هذه الشروط بتوجيه معاملاتنا وتسمح لنا بإعطاء الأطراف الثالثة منظورًا مستنيرًا وواضحًا حول كيفية قيام لومينوس بالأعمال.</p>
                    <h2>المقدمة</h2>
                    <p>تنطبق هذه الشروط والأحكام على استخدامك لـ لومينوس ، بما في ذلك أي استخدام للخدمات أو المحتوى. من خلال الوصول إلى هذا الموقع أو استخدامه، فإنك تقبل جميع الشروط والأحكام المحددة أدناه. إذا قامت لومينوس بإجراء أي تغييرات أو تعديلات من هذا القبيل، فإن استخدامك لـ لومينوس أو قراءة هذه الشروط والأحكام بعد ذلك يشكل موافقتك على هذه التغييرات والمراجعات. قد يتم تطبيق شروط وأحكام إضافية لاستخدام وظائف وميزات وبيانات وتطبيقات معينة متاحة من خلال لومينوس ويمكن توفيرها في مكان آخر على موقع الويب.</p>
                    <h2>تنويه من الضمان</h2>
                    <p>لا تقدم لومينوس أي ضمانات بأن لومينوس أو محتواها أو أي خدمة تقدمها ستلبي جميع متطلباتك، أو أنها ستعمل دون انقطاع أو خطأ، أو أنه سيتم إصلاح أي عيوب في لومينوس أو محتواها أو أي خدمات تقدمها لومينوس.</p>
                    <h2>تحديد المسؤولية</h2>
                    <p>تحت أي ظرف من الظروف، لن يكون أي من الأطراف في لومينوس مسؤولاً عن أي خسارة أو ضرر ناتج عن اعتمادك على المعلومات التي تم الحصول عليها من خلال هذا الموقع. تقع على عاتقك مسؤولية تقييم دقة هذا الموقع واكتماله وفائدته. لن يكون أي من الأطراف في لومينوس مسؤولاً بأي حال من الأحوال عن أي أضرار مباشرة أو غير مباشرة أو عقابية أو عرضية أو خاصة أو تبعية تنشأ عن أو تتعلق بالموقع أو المحتوى أو هذه الاتفاقية، سواء استنادًا إلى الضمان أو العقد أو المسؤولية التقصيرية أو أي أضرار نظرية قانونية أخرى.</p>
                    <p>للمطالبة بالتعويض عن الأضرار التي لحقت بالعناصر المشحونة، يجب تحديد جميع الطرود على أنها "هشة" عند الطلب من لومينوس.</p>
                    <h2>إنهاء الاستخدام</h2>
                    <p>حتى إذا كان الوصول لا يزال ممنوحًا للمستخدمين الآخرين، فإن لومينوس لها الحق في حظر أو إلغاء أو تعليق وصولك إلى جانب وصول أي مستخدم آخر إلى لومينوس في أي وقت دون إشعار مسبق ولأي سبب وفقًا لتقديرها الخاص.</p>
                    <br></br>
                    <h1>إتفاقية الخدمة</h1>
                    <h2>الدفع</h2>
                    <p>سيتم فرض رسوم على المستخدم لجميع طلبات الشحن عن طريق لومينوس. طرق الدفع المقبولة هي بطاقة الائتمان و خدمة آبل باي. هناك رسوم خدمة خاصة بـ لومينوس لكل طلب تتم معالجته بنجاح. لكل عملية طلب ، سوف تتقاضى لومينوس 5 دولارات أمريكية، بالإضافة إلى مصاريف الشحن العادية.</p>
                    <h2>التعويض</h2>
                    <p>أنت توافق على تعويض شركة لومينوس ومسؤوليها ومديريها ومساهميها وأسلافها وخلفائها في المصلحة والموظفين والوكلاء والشركات التابعة والشركات التابعة لها واحتجازهم من أي مطالب أو خسارة أو مسؤولية أو مطالبات أو نفقات (بما في ذلك أتعاب المحاماة) المقدمة ضد لومينوس من قبل أي طرف ثالث بسبب أو الناشئة عن أو فيما يتعلق باستخدامك للموقع.</p>
                    <h2>التصدير</h2>
                    <p>أنت تقبل المسؤولية الكاملة عن الالتزام بجميع القوانين واللوائح المعمول بها في الولايات المتحدة، بما في ذلك على سبيل المثال لا الحصر لوائح التجارة الدولية في الأسلحة، ولوائح إدارة التصدير الأمريكية، وبرامج العقوبات الاقتصادية التي يديرها مكتب الأصول الأجنبية التابع لوزارة الخزانة الأمريكية، بالإضافة إلى تلك الموجودة في أي دولة أخرى يمكنك من خلالها الوصول إلى لومينوس.</p>
                    <h2>إتفاق كامل</h2>
                    <p>تمثل شروط الاستخدام هذه فهم الأطراف النهائي فيما يتعلق بالموضوع، وهي تحل محل أي وجميع المحادثات السابقة أو اللاحقة أو البيانات أو التفاهمات أو الإقرارات أو الاتفاقات، سواء كانت مكتوبة أو شفهية. بدون موافقة لومينوس الصريحة، لن يكون أي تعديل أو تغيير في هذه الاتفاقية قابلاً للتنفيذ ضد لومينوس.</p>
                    <h2>تواصل معنا</h2>
                    <p>إذا كان لديك أي أسئلة بخصوص هذه الشروط ، يمكنك التواصل معنا على terms@luminous.delivery.</p>
                    <p>هذه الشروط سارية وتم تحديثها آخر مرة في 28 فبراير 2024.</p>
                </div>
                <GetAddressFooter/>
            </>
        )
    }
}

export default TermsConditions