import React, {useState} from 'react'
import Select from "react-select"
import {useTranslation} from "react-i18next";
import './mpcomponents-css/chooseaddress.css' //this file has the same css so i decided to be lazy and reuse it

const ChooseCard = ({ prevStep, nextStep, handleChange, state, values,
                    cards, displayCards} ) => {

    const { t } = useTranslation()

    const [inputValueCard, setInputValueCard] = useState(null)

    const handleChangeSelectedCard = (selected) => {
        setInputValueCard(selected)
        handleChange('card', cards[selected.value])
    }

    return (
        <div>
            <h4>{t('payment_method')}</h4>
            <Select
                className="cards"
                options={displayCards && displayCards}
                onChange={handleChangeSelectedCard}
                defaultValue={inputValueCard}
                isMulti={false}
                placeholder={t('select_card')}
            />
            {/*back and next buttons*/}
            <button className='caleftbtn'
                onClick={prevStep}
            >
                {t('back')}
            </button>
            <button className='carightbtn'
                disabled={!state.card}
                onClick={nextStep}
            >
                {t('continue')}
            </button>
            
        </div>
    )
}

export default ChooseCard