export const areaCodeOptions = [
    // Gulf Countries
    { value: '966', label: '+966 Saudi Arabia' },
    { value: '971', label: '+971 United Arab Emirates' },
    { value: '965', label: '+965 Kuwait' },
    { value: '968', label: '+968 Oman' },
    { value: '974', label: '+974 Qatar' },
    { value: '973', label: '+973 Bahrain' },

    // Other Countries
    { value: '93', label: '+93 Afghanistan' },
    { value: '355', label: '+355 Albania' },
    { value: '213', label: '+213 Algeria' },
    { value: '1-684', label: '+1-684 American Samoa' },
    { value: '376', label: '+376 Andorra' },
    { value: '244', label: '+244 Angola' },
    { value: '1-264', label: '+1-264 Anguilla' },
    { value: '672', label: '+672 Antarctica' },
    { value: '1-268', label: '+1-268 Antigua and Barbuda' },
    { value: '54', label: '+54 Argentina' },
    { value: '374', label: '+374 Armenia' },
    { value: '297', label: '+297 Aruba' },
    { value: '61', label: '+61 Australia' },
    { value: '43', label: '+43 Austria' },
    { value: '994', label: '+994 Azerbaijan' },
    { value: '1-242', label: '+1-242 Bahamas' },
    { value: '880', label: '+880 Bangladesh' },
    { value: '1-246', label: '+1-246 Barbados' },
    { value: '375', label: '+375 Belarus' },
    { value: '32', label: '+32 Belgium' },
    { value: '501', label: '+501 Belize' },
    { value: '229', label: '+229 Benin' },
    { value: '1-441', label: '+1-441 Bermuda' },
    { value: '975', label: '+975 Bhutan' },
    { value: '591', label: '+591 Bolivia' },
    { value: '387', label: '+387 Bosnia and Herzegovina' },
    { value: '267', label: '+267 Botswana' },
    { value: '55', label: '+55 Brazil' },
    { value: '246', label: '+246 British Indian Ocean Territory' },
    { value: '1-284', label: '+1-284 British Virgin Islands' },
    { value: '673', label: '+673 Brunei' },
    { value: '359', label: '+359 Bulgaria' },
    { value: '226', label: '+226 Burkina Faso' },
    { value: '257', label: '+257 Burundi' },
    { value: '855', label: '+855 Cambodia' },
    { value: '237', label: '+237 Cameroon' },
    { value: '1', label: '+1 Canada' },
    { value: '238', label: '+238 Cape Verde' },
    { value: '1-345', label: '+1-345 Cayman Islands' },
    { value: '236', label: '+236 Central African Republic' },
    { value: '235', label: '+235 Chad' },
    { value: '56', label: '+56 Chile' },
    { value: '86', label: '+86 China' },
    { value: '61', label: '+61 Christmas Island' },
    { value: '61', label: '+61 Cocos Islands' },
    { value: '57', label: '+57 Colombia' },
    { value: '269', label: '+269 Comoros' },
    { value: '682', label: '+682 Cook Islands' },
    { value: '506', label: '+506 Costa Rica' },
    { value: '385', label: '+385 Croatia' },
    { value: '53', label: '+53 Cuba' },
    { value: '599', label: '+599 Curacao' },
    { value: '357', label: '+357 Cyprus' },
    { value: '420', label: '+420 Czech Republic' },
    { value: '243', label: '+243 Democratic Republic of the Congo' },
    { value: '45', label: '+45 Denmark' },
    { value: '253', label: '+253 Djibouti' },
    { value: '1-767', label: '+1-767 Dominica' },
    { value: '1-809', label: '+1-809, 1-829, 1-849 Dominican Republic' },
    { value: '670', label: '+670 East Timor' },
    { value: '593', label: '+593 Ecuador' },
    { value: '20', label: '+20 Egypt' },
    { value: '503', label: '+503 El Salvador' },
    { value: '240', label: '+240 Equatorial Guinea' },
    { value: '291', label: '+291 Eritrea' },
    { value: '372', label: '+372 Estonia' },
    { value: '251', label: '+251 Ethiopia' },
    { value: '500', label: '+500 Falkland Islands' },
    { value: '298', label: '+298 Faroe Islands' },
    { value: '679', label: '+679 Fiji' },
    { value: '358', label: '+358 Finland' },
    { value: '33', label: '+33 France' },
    { value: '689', label: '+689 French Polynesia' },
    { value: '241', label: '+241 Gabon' },
    { value: '220', label: '+220 Gambia' },
    { value: '995', label: '+995 Georgia' },
    { value: '49', label: '+49 Germany' },
    { value: '233', label: '+233 Ghana' },
    { value: '350', label: '+350 Gibraltar' },
    { value: '30', label: '+30 Greece' },
    { value: '299', label: '+299 Greenland' },
    { value: '1-473', label: '+1-473 Grenada' },
    { value: '1-671', label: '+1-671 Guam' },
    { value: '502', label: '+502 Guatemala' },
    { value: '44-1481', label: '+44-1481 Guernsey' },
    { value: '224', label: '+224 Guinea' },
    { value: '245', label: '+245 Guinea-Bissau' },
    { value: '592', label: '+592 Guyana' },
    { value: '509', label: '+509 Haiti' },
    { value: '504', label: '+504 Honduras' },
    { value: '852', label: '+852 Hong Kong' },
    { value: '36', label: '+36 Hungary' },
    { value: '354', label: '+354 Iceland' },
    { value: '91', label: '+91 India' },
    { value: '62', label: '+62 Indonesia' },
    { value: '98', label: '+98 Iran' },
    { value: '964', label: '+964 Iraq' },
    { value: '353', label: '+353 Ireland' },
    { value: '44', label: '+44 Isle of Man' },
    { value: '972', label: '+972 Israel' },
    { value: '39', label: '+39 Italy' },
    { value: '225', label: '+225 Ivory Coast' },
    { value: '1-876', label: '+1-876 Jamaica' },
    { value: '81', label: '+81 Japan' },
    { value: '44', label: '+44 Jersey' },
    { value: '962', label: '+962 Jordan' },
    { value: '7', label: '+7 Kazakhstan' },
    { value: '254', label: '+254 Kenya' },
    { value: '686', label: '+686 Kiribati' },
    { value: '383', label: '+383 Kosovo' },
    { value: '996', label: '+996 Kyrgyzstan' },
    { value: '856', label: '+856 Laos' },
    { value: '371', label: '+371 Latvia' },
    { value: '961', label: '+961 Lebanon' },
    { value: '266', label: '+266 Lesotho' },
    { value: '231', label: '+231 Liberia' },
    { value: '218', label: '+218 Libya' },
    { value: '423', label: '+423 Liechtenstein' },
    { value: '370', label: '+370 Lithuania' },
    { value: '352', label: '+352 Luxembourg' },
    { value: '853', label: '+853 Macao' },
    { value: '389', label: '+389 Macedonia' },
    { value: '261', label: '+261 Madagascar' },
    { value: '265', label: '+265 Malawi' },
    { value: '60', label: '+60 Malaysia' },
    { value: '960', label: '+960 Maldives' },
    { value: '223', label: '+223 Mali' },
    { value: '356', label: '+356 Malta' },
    { value: '692', label: '+692 Marshall Islands' },
    { value: '222', label: '+222 Mauritania' },
    { value: '230', label: '+230 Mauritius' },
    { value: '262', label: '+262 Mayotte' },
    { value: '52', label: '+52 Mexico' },
    { value: '691', label: '+691 Micronesia' },
    { value: '373', label: '+373 Moldova' },
    { value: '377', label: '+377 Monaco' },
    { value: '976', label: '+976 Mongolia' },
    { value: '382', label: '+382 Montenegro' },
    { value: '1-664', label: '+1-664 Montserrat' },
    { value: '212', label: '+212 Morocco' },
    { value: '258', label: '+258 Mozambique' },
    { value: '95', label: '+95 Myanmar' },
    { value: '264', label: '+264 Namibia' },
    { value: '674', label: '+674 Nauru' },
    { value: '977', label: '+977 Nepal' },
    { value: '31', label: '+31 Netherlands' },
    { value: '599', label: '+599 Netherlands Antilles' },
    { value: '687', label: '+687 New Caledonia' },
    { value: '64', label: '+64 New Zealand' },
    { value: '505', label: '+505 Nicaragua' },
    { value: '227', label: '+227 Niger' },
    { value: '234', label: '+234 Nigeria' },
    { value: '683', label: '+683 Niue' },
    { value: '850', label: '+850 North Korea' },
    { value: '1-670', label: '+1-670 Northern Mariana Islands' },
    { value: '47', label: '+47 Norway' },
    { value: '92', label: '+92 Pakistan' },
    { value: '680', label: '+680 Palau' },
    { value: '970', label: '+970 Palestinian Territory' },
    { value: '507', label: '+507 Panama' },
    { value: '675', label: '+675 Papua New Guinea' },
    { value: '595', label: '+595 Paraguay' },
    { value: '51', label: '+51 Peru' },
    { value: '63', label: '+63 Philippines' },
    { value: '64', label: '+64 Pitcairn' },
    { value: '48', label: '+48 Poland' },
    { value: '351', label: '+351 Portugal' },
    { value: '1-787', label: '+1-787, 1-939 Puerto Rico' },
    { value: '242', label: '+242 Republic of the Congo' },
    { value: '262', label: '+262 Reunion' },
    { value: '40', label: '+40 Romania' },
    { value: '7', label: '+7 Russia' },
    { value: '250', label: '+250 Rwanda' },
    { value: '590', label: '+590 Saint Barthelemy' },
    { value: '290', label: '+290 Saint Helena' },
    { value: '1-869', label: '+1-869 Saint Kitts and Nevis' },
    { value: '1-758', label: '+1-758 Saint Lucia' },
    { value: '590', label: '+590 Saint Martin' },
    { value: '508', label: '+508 Saint Pierre and Miquelon' },
    { value: '1-784', label: '+1-784 Saint Vincent and the Grenadines' },
    { value: '685', label: '+685 Samoa' },
    { value: '378', label: '+378 San Marino' },
    { value: '239', label: '+239 Sao Tome and Principe' },
    { value: '221', label: '+221 Senegal' },
    { value: '381', label: '+381 Serbia' },
    { value: '248', label: '+248 Seychelles' },
    { value: '232', label: '+232 Sierra Leone' },
    { value: '65', label: '+65 Singapore' },
    { value: '1-721', label: '+1-721 Sint Maarten' },
    { value: '421', label: '+421 Slovakia' },
    { value: '386', label: '+386 Slovenia' },
    { value: '677', label: '+677 Solomon Islands' },
    { value: '252', label: '+252 Somalia' },
    { value: '27', label: '+27 South Africa' },
    { value: '82', label: '+82 South Korea' },
    { value: '211', label: '+211 South Sudan' },
    { value: '34', label: '+34 Spain' },
    { value: '94', label: '+94 Sri Lanka' },
    { value: '249', label: '+249 Sudan' },
    { value: '597', label: '+597 Suriname' },
    { value: '47', label: '+47 Svalbard and Jan Mayen' },
    { value: '268', label: '+268 Swaziland' },
    { value: '46', label: '+46 Sweden' },
    { value: '41', label: '+41 Switzerland' },
    { value: '963', label: '+963 Syria' },
    { value: '886', label: '+886 Taiwan' },
    { value: '992', label: '+992 Tajikistan' },
    { value: '255', label: '+255 Tanzania' },
    { value: '66', label: '+66 Thailand' },
    { value: '228', label: '+228 Togo' },
    { value: '690', label: '+690 Tokelau' },
    { value: '676', label: '+676 Tonga' },
    { value: '1-868', label: '+1-868 Trinidad and Tobago' },
    { value: '216', label: '+216 Tunisia' },
    { value: '90', label: '+90 Turkey' },
    { value: '993', label: '+993 Turkmenistan' },
    { value: '1-649', label: '+1-649 Turks and Caicos Islands' },
    { value: '688', label: '+688 Tuvalu' },
    { value: '1-340', label: '+1-340 U.S. Virgin Islands' },
    { value: '256', label: '+256 Uganda' },
    { value: '380', label: '+380 Ukraine' },
    { value: '44', label: '+44 United Kingdom' },
    { value: '1', label: '+1 United States' },
    { value: '598', label: '+598 Uruguay' },
    { value: '998', label: '+998 Uzbekistan' },
    { value: '678', label: '+678 Vanuatu' },
    { value: '379', label: '+379 Vatican' },
    { value: '58', label: '+58 Venezuela' },
    { value: '84', label: '+84 Vietnam' },
    { value: '681', label: '+681 Wallis and Futuna' },
    { value: '212', label: '+212 Western Sahara' },
    { value: '967', label: '+967 Yemen' },
    { value: '260', label: '+260 Zambia' },
    { value: '263', label: '+263 Zimbabwe' },
]

export const areaCodeOptionsAr = [
    // gulf countries
    { value: '966', label: '+966 السعودية' },
    { value: '971', label: '+971 الإمارات' },
    { value: '965', label: '+965 الكويت' },
    { value: '968', label: '+968 عمان' },
    { value: '974', label: '+974 قطر' },
    { value: '973', label: '+973 البحرين' },

    // other countries
    { value: '93', label: '+93 أفغانستان' },
    { value: '355', label: '+355 ألبانيا' },
    { value: '213', label: '+213 الجزائر' },
    { value: '1-684', label: '+1-684 ساموا الأمريكية' },
    { value: '376', label: '+376 أندورا' },
    { value: '244', label: '+244 أنغولا' },
    { value: '1-264', label: '+1-264 أنغويلا' },
    { value: '672', label: '+672 أنتاركتيكا' },
    { value: '1-268', label: '+1-268 أنتيغوا وبربودا' },
    { value: '54', label: '+54 الأرجنتين' },
    { value: '374', label: '+374 أرمينيا' },
    { value: '297', label: '+297 أروبا' },
    { value: '61', label: '+61 أستراليا' },
    { value: '43', label: '+43 النمسا' },
    { value: '994', label: '+994 أذربيجان' },
    { value: '1-242', label: '+1-242 الباهاما' },
    { value: '880', label: '+880 بنغلاديش' },
    { value: '1-246', label: '+1-246 باربادوس' },
    { value: '375', label: '+375 روسيا البيضاء' },
    { value: '32', label: '+32 بلجيكا' },
    { value: '501', label: '+501 بليز' },
    { value: '229', label: '+229 بنين' },
    { value: '1-441', label: '+1-441 برمودا' },
    { value: '975', label: '+975 بوتان' },
    { value: '591', label: '+591 بوليفيا' },
    { value: '387', label: '+387 البوسنة والهرسك' },
    { value: '267', label: '+267 بوتسوانا' },
    { value: '55', label: '+55 البرازيل' },
    { value: '246', label: '+246 المحيط البريطاني الهندي' },
    { value: '1-284', label: '+1-284 جزر فيرجن البريطانية' },
    { value: '673', label: '+673 بروناي' },
    { value: '359', label: '+359 بلغاريا' },
    { value: '226', label: '+226 بوركينا فاسو' },
    { value: '257', label: '+257 بوروندي' },
    { value: '855', label: '+855 كمبوديا' },
    { value: '237', label: '+237 الكاميرون' },
    { value: '1', label: '+1 كندا' },
    { value: '238', label: '+238 الرأس الأخضر' },
    { value: '1-345', label: '+1-345 جزر كايمان' },
    { value: '236', label: '+236 جمهورية أفريقيا الوسطى' },
    { value: '235', label: '+235 تشاد' },
    { value: '56', label: '+56 تشيلي' },
    { value: '86', label: '+86 الصين' },
    { value: '61', label: '+61 جزيرة كريسماس' },
    { value: '61', label: '+61 جزر كوكوس' },
    { value: '57', label: '+57 كولومبيا' },
    { value: '269', label: '+269 جزر القمر' },
    { value: '682', label: '+682 جزر كوك' },
    { value: '506', label: '+506 كوستاريكا' },
    { value: '385', label: '+385 كرواتيا' },
    { value: '53', label: '+53 كوبا' },
    { value: '599', label: '+599 كوراساو' },
    { value: '357', label: '+357 قبرص' },
    { value: '420', label: '+420 جمهورية التشيك' },
    { value: '243', label: '+243 جمهورية الكونغو الديمقراطية' },
    { value: '45', label: '+45 الدنمارك' },
    { value: '253', label: '+253 جيبوتي' },
    { value: '1-767', label: '+1-767 دومينيكا' },
    { value: '1-809', label: '+1-809 جمهورية الدومينيكان' },
    { value: '670', label: '+670 تيمور الشرقية' },
    { value: '593', label: '+593 الإكوادور' },
    { value: '20', label: '+20 مصر' },
    { value: '503', label: '+503 السلفادور' },
    { value: '240', label: '+240 غينيا الاستوائية' },
    { value: '291', label: '+291 إريتريا' },
    { value: '372', label: '+372 إستونيا' },
    { value: '251', label: '+251 إثيوبيا' },
    { value: '500', label: '+500 جزر فوكلاند' },
    { value: '298', label: '+298 جزر فارو' },
    { value: '679', label: '+679 فيجي' },
    { value: '358', label: '+358 فنلندا' },
    { value: '33', label: '+33 فرنسا' },
    { value: '594', label: '+594 غويانا الفرنسية' },
    { value: '689', label: '+689 بولينيزيا الفرنسية' },
    { value: '241', label: '+241 الغابون' },
    { value: '220', label: '+220 غامبيا' },
    { value: '995', label: '+995 جورجيا' },
    { value: '49', label: '+49 ألمانيا' },
    { value: '233', label: '+233 غانا' },
    { value: '350', label: '+350 جبل طارق' },
    { value: '30', label: '+30 اليونان' },
    { value: '299', label: '+299 غرينلاند' },
    { value: '1-473', label: '+1-473 غرينادا' },
    { value: '1-671', label: '+1-671 غوام' },
    { value: '502', label: '+502 غواتيمالا' },
    { value: '44-1481', label: '+44-1481 جيرنزي' },
    { value: '224', label: '+224 غينيا' },
    { value: '245', label: '+245 غينيا-بيساو' },
    { value: '592', label: '+592 غيانا' },
    { value: '509', label: '+509 هايتي' },
    { value: '504', label: '+504 هندوراس' },
    { value: '852', label: '+852 هونغ كونغ' },
    { value: '36', label: '+36 المجر' },
    { value: '354', label: '+354 أيسلندا' },
    { value: '91', label: '+91 الهند' },
    { value: '62', label: '+62 إندونيسيا' },
    { value: '98', label: '+98 إيران' },
    { value: '964', label: '+964 العراق' },
    { value: '353', label: '+353 أيرلندا' },
    { value: '44-1624', label: '+44-1624 جزيرة مان' },
    { value: '972', label: '+972 إسرائيل' },
    { value: '39', label: '+39 إيطاليا' },
    { value: '225', label: '+225 ساحل العاج' },
    { value: '1-876', label: '+1-876 جامايكا' },
    { value: '81', label: '+81 اليابان' },
    { value: '44-1534', label: '+44-1534 جيرسي' },
    { value: '962', label: '+962 الأردن' },
    { value: '7', label: '+7 كازاخستان' },
    { value: '254', label: '+254 كينيا' },
    { value: '686', label: '+686 كيريباتي' },
    { value: '383', label: '+383 كوسوفو' },
    { value: '996', label: '+996 قيرغيزستان' },
    { value: '856', label: '+856 لاوس' },
    { value: '371', label: '+371 لاتفيا' },
    { value: '961', label: '+961 لبنان' },
    { value: '266', label: '+266 ليسوتو' },
    { value: '231', label: '+231 ليبيريا' },
    { value: '218', label: '+218 ليبيا' },
    { value: '423', label: '+423 ليختنشتاين' },
    { value: '370', label: '+370 ليتوانيا' },
    { value: '352', label: '+352 لوكسمبورغ' },
    { value: '853', label: '+853 ماكاو' },
    { value: '389', label: '+389 مقدونيا' },
    { value: '261', label: '+261 مدغشقر' },
    { value: '265', label: '+265 مالاوي' },
    { value: '60', label: '+60 ماليزيا' },
    { value: '960', label: '+960 جزر المالديف' },
    { value: '223', label: '+223 مالي' },
    { value: '356', label: '+356 مالطا' },
    { value: '692', label: '+692 جزر مارشال' },
    { value: '596', label: '+596 مارتينيك' },
    { value: '222', label: '+222 موريتانيا' },
    { value: '230', label: '+230 موريشيوس' },
    { value: '262', label: '+262 ريونيون' },
    { value: '52', label: '+52 المكسيك' },
    { value: '691', label: '+691 ميكرونيزيا' },
    { value: '373', label: '+373 مولدوفا' },
    { value: '377', label: '+377 موناكو' },
    { value: '976', label: '+976 منغوليا' },
    { value: '382', label: '+382 الجبل الأسود' },
    { value: '1-664', label: '+1-664 مونتسيرات' },
    { value: '212', label: '+212 المغرب' },
    { value: '258', label: '+258 موزمبيق' },
    { value: '95', label: '+95 ميانمار' },
    { value: '264', label: '+264 ناميبيا' },
    { value: '674', label: '+674 ناورو' },
    { value: '977', label: '+977 نيبال' },
    { value: '31', label: '+31 هولندا' },
    { value: '599', label: '+599 جزر الأنتيل الهولندية' },
    { value: '687', label: '+687 نيو كاليدونيا' },
    { value: '64', label: '+64 نيوزيلندا' },
    { value: '505', label: '+505 نيكاراجوا' },
    { value: '227', label: '+227 النيجر' },
    { value: '234', label: '+234 نيجيريا' },
    { value: '683', label: '+683 نيوي' },
    { value: '672', label: '+672 جزيرة نورفوك' },
    { value: '850', label: '+850 كوريا الشمالية' },
    { value: '1-670', label: '+1-670 جزر ماريانا الشمالية' },
    { value: '47', label: '+47 النرويج' },
    { value: '92', label: '+92 باكستان' },
    { value: '680', label: '+680 بالاو' },
    { value: '970', label: '+970 فلسطين' },
    { value: '507', label: '+507 بنما' },
    { value: '675', label: '+675 بابوا غينيا الجديدة' },
    { value: '595', label: '+595 باراغواي' },
    { value: '51', label: '+51 بيرو' },
    { value: '63', label: '+63 الفلبين' },
    { value: '64', label: '+64 بيتكيرن' },
    { value: '48', label: '+48 بولندا' },
    { value: '351', label: '+351 البرتغال' },
    { value: '1-787', label: '+1-787 بورتوريكو' },
    { value: '242', label: '+242 الكونغو الديمقراطية' },
    { value: '262', label: '+262 ريونيون' },
    { value: '40', label: '+40 رومانيا' },
    { value: '7', label: '+7 روسيا' },
    { value: '250', label: '+250 رواندا' },
    { value: '590', label: '+590 سانت بارتيليمي' },
    { value: '290', label: '+290 سانت هيلانة' },
    { value: '1-869', label: '+1-869 سانت كيتس ونيفيس' },
    { value: '1-758', label: '+1-758 سانت لوسيا' },
    { value: '590', label: '+590 سانت مارتن' },
    { value: '508', label: '+508 سانت بيير وميكلون' },
    { value: '1-784', label: '+1-784 سانت فينسنت وجزر غرينادين' },
    { value: '685', label: '+685 ساموا' },
    { value: '378', label: '+378 سان مارينو' },
    { value: '239', label: '+239 ساو تومي وبرينسيبي' },
    { value: '221', label: '+221 السنغال' },
    { value: '381', label: '+381 صربيا' },
    { value: '248', label: '+248 سيشل' },
    { value: '232', label: '+232 سيرا ليون' },
    { value: '65', label: '+65 سنغافورة' },
    { value: '421', label: '+421 سلوفاكيا' },
    { value: '386', label: '+386 سلوفينيا' },
    { value: '677', label: '+677 جزر سليمان' },
    { value: '252', label: '+252 الصومال' },
    { value: '27', label: '+27 جنوب أفريقيا' },
    { value: '82', label: '+82 كوريا الجنوبية' },
    { value: '211', label: '+211 جنوب السودان' },
    { value: '34', label: '+34 إسبانيا' },
    { value: '94', label: '+94 سريلانكا' },
    { value: '249', label: '+249 السودان' },
    { value: '597', label: '+597 سورينام' },
    { value: '47', label: '+47 سفالبارد وجان ماين' },
    { value: '268', label: '+268 سوازيلاند' },
    { value: '46', label: '+46 السويد' },
    { value: '41', label: '+41 سويسرا' },
    { value: '963', label: '+963 سوريا' },
    { value: '886', label: '+886 تايوان' },
    { value: '992', label: '+992 طاجيكستان' },
    { value: '255', label: '+255 تنزانيا' },
    { value: '66', label: '+66 تايلاند' },
    { value: '228', label: '+228 توغو' },
    { value: '690', label: '+690 توكيلاو' },
    { value: '676', label: '+676 تونغا' },
    { value: '1-868', label: '+1-868 ترينيداد وتوباغو' },
    { value: '216', label: '+216 تونس' },
    { value: '90', label: '+90 تركيا' },
    { value: '993', label: '+993 تركمانستان' },
    { value: '1-649', label: '+1-649 جزر توركس وكايكوس' },
    { value: '688', label: '+688 توفالو' },
    { value: '1-340', label: '+1-340 جزر العذراء الأمريكية' },
    { value: '256', label: '+256 أوغندا' },
    { value: '380', label: '+380 أوكرانيا' },
    { value: '44', label: '+44 المملكة المتحدة' },
    { value: '598', label: '+598 أوروغواي' },
    { value: '998', label: '+998 أوزبكستان' },
    { value: '678', label: '+678 فانواتو' },
    { value: '39', label: '+39 الفاتيكان' },
    { value: '58', label: '+58 فنزويلا' },
    { value: '84', label: '+84 فيتنام' },
    { value: '681', label: '+681 واليس وفوتونا' },
    { value: '212', label: '+212 الصحراء الغربية' },
    { value: '967', label: '+967 اليمن' },
    { value: '260', label: '+260 زامبيا' },
    { value: '263', label: '+263 زيمبابوي' },

    // // gulf countries
    // { value: '966', label: '+966 السعودية' },
    // { value: '971', label: '+971 الإمارات' },
    // { value: '965', label: '+965 الكويت' },
    // { value: '968', label: '+968 عمان' },
    // { value: '974', label: '+974 قطر' },
    // { value: '973', label: '+973 البحرين' },
    //
    // // other countries
    // { value: '93', label: '+93 أفغانستان' },
    // { value: '355', label: '+355 ألبانيا' },
    // { value: '213', label: '+213 الجزائر' },
    // { value: '1-684', label: '+1-684 ساموا الأمريكية' },
    // { value: '376', label: '+376 أندورا' },
    // { value: '244', label: '+244 أنغولا' },
    // { value: '1-264', label: '+1-264 أنغويلا' },
    // { value: '672', label: '+672 أنتاركتيكا' },
    // { value: '1-268', label: '+1-268 أنتيغوا وبربودا' },
    // { value: '54', label: '+54 الأرجنتين' },
    // { value: '374', label: '+374 أرمينيا' },
    // { value: '297', label: '+297 أروبا' },
    // { value: '61', label: '+61 أستراليا' },
    // { value: '43', label: '+43 النمسا' },
    // { value: '994', label: '+994 أذربيجان' },
    // { value: '1-242', label: '+1-242 الباهاما' },
    // { value: '880', label: '+880 بنغلاديش' },
    // { value: '1-246', label: '+1-246 باربادوس' },
    // { value: '375', label: '+375 روسيا البيضاء' },
    // { value: '32', label: '+32 بلجيكا' },
    // { value: '501', label: '+501 بليز' },
    // { value: '229', label: '+229 بنين' },
    // { value: '1-441', label: '+1-441 برمودا' },
    // { value: '975', label: '+975 بوتان' },
    // { value: '591', label: '+591 بوليفيا' },
    // { value: '387', label: '+387 البوسنة والهرسك' },
    // { value: '267', label: '+267 بوتسوانا' },
    // { value: '55', label: '+55 البرازيل' },
    // { value: '246', label: '+246 المحيط البريطاني الهندي' },
    // { value: '1-284', label: '+1-284 جزر فيرجن البريطانية' },
    // { value: '673', label: '+673 بروناي' },
    // { value: '359', label: '+359 بلغاريا' },
    // { value: '226', label: '+226 بوركينا فاسو' },
    // { value: '257', label: '+257 بوروندي' },
    // { value: '855', label: '+855 كمبوديا' },
    // { value: '237', label: '+237 الكاميرون' },
    // { value: '1', label: '+1 كندا' },
    // { value: '238', label: '+238 الرأس الأخضر' },
    // { value: '1-345', label: '+1-345 جزر كايمان' },
    // { value: '236', label: '+236 جمهورية أفريقيا الوسطى' },
    // { value: '235', label: '+235 تشاد' },
    // { value: '56', label: '+56 تشيلي' },
    // { value: '86', label: '+86 الصين' },
    // { value: '61', label: '+61 جزيرة كريسماس' },
    // { value: '61', label: '+61 جزر كوكوس' },
    // { value: '57', label: '+57 كولومبيا' },
    // { value: '269', label: '+269 جزر القمر' },
    // { value: '682', label: '+682 جزر كوك' },
    // { value: '506', label: '+506 كوستاريكا' },
    // { value: '385', label: '+385 كرواتيا' },
    // { value: '53', label: '+53 كوبا' },
    // { value: '599', label: '+599 كوراساو' },
    // { value: '357', label: '+357 قبرص' },
    // { value: '420', label: '+420 جمهورية التشيك' },
    // { value: '243', label: '+243 جمهورية الكونغو الديمقراطية' },
    // { value: '45', label: '+45 الدنمارك' },
    // { value: '253', label: '+253 جيبوتي' },
    // { value: '1-767', label: '+1-767 دومينيكا' },
    // { value: '1-809', label: '+1-809 جمهورية الدومينيكان' },
    // { value: '670', label: '+670 تيمور الشرقية' },
    // { value: '593', label: '+593 الإكوادور' },
    // { value: '20', label: '+20 مصر' },
    // { value: '503', label: '+503 السلفادور' },
    // { value: '240', label: '+240 غينيا الاستوائية' },
    // { value: '291', label: '+291 إريتريا' },
    // { value: '372', label: '+372 إستونيا' },
    // { value: '251', label: '+251 إثيوبيا' },
    // { value: '500', label: '+500 جزر فوكلاند' },
    // { value: '298', label: '+298 جزر فارو' },
    // { value: '679', label: '+679 فيجي' },
    // { value: '358', label: '+358 فنلندا' },
    // { value: '33', label: '+33 فرنسا' },
    // { value: '594', label: '+594 غويانا الفرنسية' },
    // { value: '689', label: '+689 بولينيزيا الفرنسية' },
    // { value: '241', label: '+241 الغابون' },
    // { value: '220', label: '+220 غامبيا' },
    // { value: '995', label: '+995 جورجيا' },
    // { value: '49', label: '+49 ألمانيا' },
    // { value: '233', label: '+233 غانا' },
    // { value: '350', label: '+350 جبل طارق' },
    // { value: '30', label: '+30 اليونان' },
    // { value: '299', label: '+299 غرينلاند' },
    // { value: '1-473', label: '+1-473 غرينادا' },
    // { value: '1-671', label: '+1-671 غوام' },
    // { value: '502', label: '+502 غواتيمالا' },
    // { value: '44-1481', label: '+44-1481 جيرنزي' },
    // { value: '224', label: '+224 غينيا' },
    // { value: '245', label: '+245 غينيا-بيساو' },
    // { value: '592', label: '+592 غيانا' },
    // { value: '509', label: '+509 هايتي' },
    // { value: '504', label: '+504 هندوراس' },
    // { value: '852', label: '+852 هونغ كونغ' },
    // { value: '36', label: '+36 المجر' },
    // { value: '354', label: '+354 أيسلندا' },
    // { value: '91', label: '+91 الهند' },
    // { value: '62', label: '+62 إندونيسيا' },
    // { value: '98', label: '+98 إيران' },
    // { value: '964', label: '+964 العراق' },
    // { value: '353', label: '+353 أيرلندا' },
    // { value: '44-1624', label: '+44-1624 جزيرة مان' },
    // { value: '972', label: '+972 إسرائيل' },
    // { value: '39', label: '+39 إيطاليا' },
    // { value: '225', label: '+225 ساحل العاج' },
    // { value: '1-876', label: '+1-876 جامايكا' },
    // { value: '81', label: '+81 اليابان' },
    // { value: '44-1534', label: '+44-1534 جيرسي' },
    // { value: '962', label: '+962 الأردن' },
    // { value: '7', label: '+7 كازاخستان' },
    // { value: '254', label: '+254 كينيا' },
    // { value: '686', label: '+686 كيريباتي' },
    // { value: '383', label: '+383 كوسوفو' },
    // { value: '996', label: '+996 قيرغيزستان' },
    // { value: '856', label: '+856 لاوس' },
    // { value: '371', label: '+371 لاتفيا' },
    // { value: '961', label: '+961 لبنان' },
    // { value: '266', label: '+266 ليسوتو' },
    // { value: '231', label: '+231 ليبيريا' },
    // { value: '218', label: '+218 ليبيا' },
    // { value: '423', label: '+423 ليختنشتاين' },
    // { value: '370', label: '+370 ليتوانيا' },
    // { value: '352', label: '+352 لوكسمبورغ' },
    // { value: '853', label: '+853 ماكاو' },
    // { value: '389', label: '+389 مقدونيا' },
    // { value: '261', label: '+261 مدغشقر' },
    // { value: '265', label: '+265 مالاوي' },
    // { value: '60', label: '+60 ماليزيا' },
    // { value: '960', label: '+960 جزر المالديف' },
    // { value: '223', label: '+223 مالي' },
    // { value: '356', label: '+356 مالطا' },
    // { value: '692', label: '+692 جزر مارشال' },
    // { value: '596', label: '+596 مارتينيك' },
    // { value: '222', label: '+222 موريتانيا' },
    // { value: '230', label: '+230 موريشيوس' },
    // { value: '262', label: '+262 ريونيون' },
    // { value: '52', label: '+52 المكسيك' },
    // { value: '691', label: '+691 ميكرونيزيا' },
    // { value: '373', label: '+373 مولدوفا' },
    // { value: '377', label: '+377 موناكو' },
    // { value: '976', label: '+976 منغوليا' },
    // { value: '382', label: '+382 الجبل الأسود' },
    // { value: '1-664', label: '+1-664 مونتسيرات' },
    // { value: '212', label: '+212 المغرب' },
    // { value: '258', label: '+258 موزمبيق' },
    // { value: '95', label: '+95 ميانمار' },
    // { value: '264', label: '+264 ناميبيا' },
    // { value: '674', label: '+674 ناورو' },
    // { value: '977', label: '+977 نيبال' },
    // { value: '31', label: '+31 هولندا' },
    // { value: '599', label: '+599 جزر الأنتيل الهولندية' },
    // { value: '687', label: '+687 نيو كاليدونيا' },
    // { value: '64', label: '+64 نيوزيلندا' },
    // { value: '505', label: '+505 نيكاراجوا' },
    // { value: '227', label: '+227 النيجر' },
    // { value: '234', label: '+234 نيجيريا' },
    // { value: '683', label: '+683 نيوي' },
    // { value: '672', label: '+672 جزيرة نورفوك' },
    // { value: '850', label: '+850 كوريا الشمالية' },
    // { value: '1-670', label: '+1-670 جزر ماريانا الشمالية' },
    // { value: '47', label: '+47 النرويج' },
    // { value: '92', label: '+92 باكستان' },
    // { value: '680', label: '+680 بالاو' },
    // { value: '970', label: '+970 فلسطين' },
    // { value: '507', label: '+507 بنما' },
    // { value: '675', label: '+675 بابوا غينيا الجديدة' },
    // { value: '595', label: '+595 باراغواي' },
    // { value: '51', label: '+51 بيرو' },
    // { value: '63', label: '+63 الفلبين' },
    // { value: '64', label: '+64 بيتكيرن' },
    // { value: '48', label: '+48 بولندا' },
    // { value: '351', label: '+351 البرتغال' },
    // { value: '1-787', label: '+1-787 بورتوريكو' },
    // { value: '242', label: '+242 الكونغو الديمقراطية' },
    // { value: '262', label: '+262 ريونيون' },
    // { value: '40', label: '+40 رومانيا' },
    // { value: '7', label: '+7 روسيا' },
    // { value: '250', label: '+250 رواندا' },
    // { value: '590', label: '+590 سانت بارتيليمي' },
    // { value: '290', label: '+290 سانت هيلانة' },
    // { value: '1-869', label: '+1-869 سانت كيتس ونيفيس' },
    // { value: '1-758', label: '+1-758 سانت لوسيا' },
    // { value: '590', label: '+590 سانت مارتن' },
    // { value: '508', label: '+508 سانت بيير وميكلون' },
    // { value: '1-784', label: '+1-784 سانت فينسنت وجزر غرينادين' },
    // { value: '685', label: '+685 ساموا' },
    // { value: '378', label: '+378 سان مارينو' },
    // { value: '239', label: '+239 ساو تومي وبرينسيبي' },
    // { value: '221', label: '+221 السنغال' },
    // { value: '381', label: '+381 صربيا' },
    // { value: '248', label: '+248 سيشل' },
    // { value: '232', label: '+232 سيرا ليون' },
    // { value: '65', label: '+65 سنغافورة' },
    // { value: '421', label: '+421 سلوفاكيا' },
    // { value: '386', label: '+386 سلوفينيا' },
    // { value: '677', label: '+677 جزر سليمان' },
    // { value: '252', label: '+252 الصومال' },
    // { value: '27', label: '+27 جنوب أفريقيا' },
    // { value: '82', label: '+82 كوريا الجنوبية' },
    // { value: '211', label: '+211 جنوب السودان' },
    // { value: '34', label: '+34 إسبانيا' },
    // { value: '94', label: '+94 سريلانكا' },
    // { value: '249', label: '+249 السودان' },
    // { value: '597', label: '+597 سورينام' },
    // { value: '47', label: '+47 سفالبارد وجان ماين' },
    // { value: '268', label: '+268 سوازيلاند' },
    // { value: '46', label: '+46 السويد' },
    // { value: '41', label: '+41 سويسرا' },
    // { value: '963', label: '+963 سوريا' },
    // { value: '886', label: '+886 تايوان' },
    // { value: '992', label: '+992 طاجيكستان' },
    // { value: '255', label: '+255 تنزانيا' },
    // { value: '66', label: '+66 تايلاند' },
    // { value: '228', label: '+228 توغو' },
    // { value: '690', label: '+690 توكيلاو' },
    // { value: '676', label: '+676 تونغا' },
    // { value: '1-868', label: '+1-868 ترينيداد وتوباغو' },
    // { value: '216', label: '+216 تونس' },
    // { value: '90', label: '+90 تركيا' },
    // { value: '993', label: '+993 تركمانستان' },
    // { value: '1-649', label: '+1-649 جزر توركس وكايكوس' },
    // { value: '688', label: '+688 توفالو' },
    // { value: '1-340', label: '+1-340 جزر العذراء الأمريكية' },
    // { value: '256', label: '+256 أوغندا' },
    // { value: '380', label: '+380 أوكرانيا' },
    // { value: '44', label: '+44 المملكة المتحدة' },
    // { value: '598', label: '+598 أوروغواي' },
    // { value: '998', label: '+998 أوزبكستان' },
    // { value: '678', label: '+678 فانواتو' },
    // { value: '39', label: '+39 الفاتيكان' },
    // { value: '58', label: '+58 فنزويلا' },
    // { value: '84', label: '+84 فيتنام' },
    // { value: '681', label: '+681 واليس وفوتونا' },
    // { value: '212', label: '+212 الصحراء الغربية' },
    // { value: '967', label: '+967 اليمن' },
    // { value: '260', label: '+260 زامبيا' },
    // { value: '263', label: '+263 زيمبابوي' },
]

// laziness
areaCodeOptionsAr.forEach((areaCode) => {
    areaCode.label = areaCode.label.replace('+', '')
    const split = areaCode.label.split(' ')
    areaCode.label = `${split[0]}+ ${split[1]}`
})