import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from "./context/AuthContext";
import {AccessAuthContextProvider} from "./context/AccessAuthContext";





const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
      <AccessAuthContextProvider>
          <AuthContextProvider>
              <App />
          </AuthContextProvider>
      </AccessAuthContextProvider>
  </React.StrictMode>
);