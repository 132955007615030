import React, { useEffect, useState } from 'react';
import './account-css/OrderConfirmation.css';

const OrderConfirmation = () => {
    const [confirmationNumber, setConfirmationNumber] = useState('');

    useEffect(() => {
        // Generate a unique 8-digit confirmation number
        const generateConfirmationNumber = () => {
            return Math.floor(10000000 + Math.random() * 90000000).toString();
        };
        setConfirmationNumber(generateConfirmationNumber());
    }, []);

    return (
        <div className="order-confirmation">
            <h1>Order Confirmation: {confirmationNumber}</h1>
            <p>Thank you for choosing Luminous!</p>
            <p>Your order is complete, and we appreciate you choosing Luminous to handle your packages.
                We will send you an email with tracking information
                as soon as your package is shipped from our warehouse.
            </p>
            <p>In the meantime, feel free to manage your packages and explore additional
                options on your Luminous Dashboard:
            </p>
            <span>
                <h4>Package Consolidation:</h4>
                <p>Luminous can hold and consolidate multiple packages for you until you're ready to ship them,
                    helping you save on shipping costs. You can manage this option through the "My Packages"
                    section in your Luminous Dashboard.
                </p>
            </span>
            <span>
                <h4>Flexible Storage Choices:</h4>
                <p>We offer a range of storage options, from short-term to longer-term solutions,
                    providing you with the flexibility to choose based on your needs.
                    If you wish to store your package for up to 6 months, you can indicate this
                    preference in your Luminous Dashboard.
                </p>
            </span>
            <p>We hope your experience using Luminous has been great. If you have any questions or issues with your
                order, feel free to reach out to us at support@luminous.delivery.
            </p>
            <p>Best regards,</p>
            <p>Luminous Team</p>
        </div>
    );
}

export default OrderConfirmation;
