import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AddPackageDetails from "./AddPackageDetails";
import tn from "../../../hooks/functions/NumberToAr";
import './mpcomponents-css/choosedimensions.css'
import { isAlphanumericSpecial, isNumeric } from "../../../hooks/functions/RegexCheck";

const ChooseDimensions = ({ steps, handleChange, state, user }) => {
    const { t } = useTranslation()

    const [totalWeight, setTotalWeight] = useState(0)
    const [totalDeclaredValue, setTotalDeclaredValue] = useState(0)
    const [parcelError, setParcelError] = useState("")
    const [itemDimensions, setItemDimensions] = useState(state.item_dimensions)
    const [itemName, setItemName] = useState("")
    const [botAnswer, setBotAnswer] = useState("")

    // on submit, check if all fields are filled and if they are numbers and if they are positive
    const handleSubmit = (e) => {
        e.preventDefault()

        let errorFlag = false
        // loop through every field of every item in the item_dimensions array and check if any field is zero
        for (let i = 0; i < state.item_dimensions.length; i++) {
            const item = state.item_dimensions[i]
            if (item.dimensions.weight === 0 || item.dimensions.height === 0 ||
                item.dimensions.width === 0 || item.dimensions.length === 0 ||
                item.dimensions.weight === null || item.dimensions.height === null ||
                item.dimensions.width === null || item.dimensions.length === null ||
                item.declared_value === 0 || item.declared_value === null) {
                errorFlag = true
                break
            }
        }

        if (!errorFlag) {
            calculateWeight()
            handleOpen()
        }
        else {
            alert(t("fill_all_fields"))
        }
    }

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const calculatePrice = () => {
        let sizeCalculation = 0
        let taxPrice = 0
        let totalWeight = 0
        for (let i = 0; i < state.item_dimensions.length; i++) {
            const item = state.item_dimensions[i]
            // calculate the size of the item
            totalWeight += item.dimensions.weight
            const size = (item.dimensions.height + item.dimensions.width + item.dimensions.length) * 0.05
                + item.dimensions.weight * 12.9
            // add the size of the item to the total size
            sizeCalculation += size
            // calculate the tax price of the item
            // taxPrice += item.declared_value * 0.18
        }
        // sizeCalculation = sizeCalculation + taxPrice
        sizeCalculation = Math.round(sizeCalculation * 100) / 100 + 10
        if (sizeCalculation < 14.72) {
            sizeCalculation = 14.72
        }
        return sizeCalculation
    }

    const calculateWeight = () => {
        let weight = 0
        let declaredValue = 0
        state.item_dimensions.forEach(item => {
            weight += item.dimensions.weight
            declaredValue += item.declared_value
        })
        setTotalWeight(weight)
        setTotalDeclaredValue(declaredValue)
    }

    const resizeItemDimensions = (size) => {
        // if undefined or the same, don't do anything
        if (size === undefined || size === state.item_dimensions.length) {
            return
        }
        // change the size of the item_dimensions array
        const item_dimensions = [...state.item_dimensions]
        const value = parseInt(size, 10)
        // if the value is greater than the current length of the array, add new items to the array
        if (value > item_dimensions.length) {
            for (let i = item_dimensions.length; i < value; i++) {
                item_dimensions.push({
                    dimensions: {
                        weight: '',
                        height: '',
                        width: '',
                        length: ''
                    },
                    declared_value: ''
                })
            }
        }
        // if less, remove items from the array
        else {
            item_dimensions.splice(value, item_dimensions.length - value)
        }
        setItemDimensions(item_dimensions)
        handleChange("item_dimensions", item_dimensions)
    }

    // set how many items can be entered
    const handleChangeNumberOfParcels = (e) => {
        // check if the value is a number with regex
        const regex = /^[0-9]+$/
        if (regex.test(e.target.value)) {
            handleChange("number_of_parcels", e.target.value)
            resizeItemDimensions(e.target.value)
            setParcelError("")
        }
        else if (e.target.value === "") {
            handleChange("number_of_parcels", e.target.value)
            setParcelError("")
        }
        else {
            setParcelError(t("enter_valid_number"))
        }
    }

    // openai
    // ask item dimensions
    const askItemDimensions = async (e) => {
        e.preventDefault()

        // check if the value is alphanumeric with spaces and special characters
        // const regex = /^[a-zA-Z0-9\s\-\_\.\,\!\?]+$/
        if (!isAlphanumericSpecial(itemName)) {
            alert('Please enter a valid item name')
            return
        }
        // check if the value is empty
        if (itemName === "") {
            alert('Please enter a valid item name')
            return
        }

        // make a post request to the openai route in the server
        const response = await fetch('https://www.luminous.delivery/api/openai/dimensions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            },
            body: JSON.stringify({
                name: itemName
            })
        })

        const json = await response.json()

        if (response.ok) {
            setBotAnswer(json.bot)
        }
        else {
            setBotAnswer("Error")
        }
    }

    const fillItemDimensions = () => {
        const containsNumbers = (string) => {
            const regex = /[0-9]/
            return regex.test(string)
        }
        // check if the bot answer is not empty or an error or does not contain numbers
        if (botAnswer === "" || botAnswer === "Error" || !containsNumbers(botAnswer)) {
            alert(t('could_not_fill_dimensions'))
            return
        }
        // take 3 numbers before cm, and 1 number before kg
        const foundDimensions = []
        const index = state.number_of_parcels === 0 ? 0 : state.number_of_parcels - 1

        // parse the answer to extract item information
        for (let i = 0; i < botAnswer.length; i++) {
            const char = botAnswer[i]
            if (char === 'c' && botAnswer[i + 1] === 'm') {
                let number = ""
                for (let j = i - 2; j >= 0; j--) {
                    const char2 = botAnswer[j]
                    if (char2 === ' ') {
                        break
                    }
                    number = char2 + number
                }
                if (number !== "") {
                    foundDimensions.push(parseFloat(number))
                }
            }
            else if (char === 'k' && botAnswer[i + 1] === 'g') {
                let number = ""
                for (let j = i - 2; j >= 0; j--) {
                    const char2 = botAnswer[j]
                    if (char2 === ' ') {
                        break
                    }
                    number = char2 + number
                }
                if (number !== "") {
                    const item_dimensions = [...state.item_dimensions]
                    item_dimensions[index].dimensions.weight = parseFloat(number)
                    setItemDimensions(item_dimensions)
                    handleChange("item_dimensions", item_dimensions)
                }
            }
        }

        // set item info
        const item_dimensions = [...state.item_dimensions]
        item_dimensions[index].item_name = itemName
        item_dimensions[index].dimensions.width = parseFloat(foundDimensions[0])
        item_dimensions[index].dimensions.length = parseFloat(foundDimensions[1])
        item_dimensions[index].dimensions.height = parseFloat(foundDimensions[2])
        setItemDimensions(item_dimensions)
        handleChange("item_dimensions", item_dimensions)
    }

    // TODO: BRANDON!!
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80vw',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    return (
        <div className='choosedimensions'>
            <h1>{t('hi')} {user.name}, #U{user.personalID}</h1>
            <h1>{t('package_details')}</h1>
            <form className='cdform'>
                <label>{t('number_of_parcels')}</label>
                <br/>
                <input
                    type="number"
                    name="parcels"
                    placeholder={t("number_of_parcels_placeholder")}
                    defaultValue={tn(state.number_of_parcels)}
                    onChange={(e) => {
                        handleChangeNumberOfParcels(e)
                    }}
                />
                {parcelError && <div>{parcelError}</div>}
            </form>

            <div>
                <h1>{t("ask_us_dimension_weight")}</h1>
                <form>
                    <label>{t("what_is_your_item_name")}</label>
                    <br />
                    <input
                        type="text"
                        name="item_name"
                        placeholder={t('item_name_placeholder')}
                        onChange={(e) => setItemName(e.target.value)}
                    />
                    <button className='cdask' onClick={askItemDimensions}>{t("ask_our_ai")}</button>
                </form>
                <div>{botAnswer}</div>
                <button className='cdask' onClick={fillItemDimensions}>{t("fill_form")}</button>
            </div>

            {/*render AddPackageDetails as many times as number_of_parcels*/}
            <div>
                {state.number_of_parcels > 0 && (
                    <div>
                        {itemDimensions.map((item, index) => (
                            <AddPackageDetails
                                key={index}
                                index={index}
                                state={state}
                                handleChange={handleChange}
                            />
                        ))}
                    </div>
                )}
            </div>

            {/*pop-up to confirm price*/}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {/*display the input fields to confirm the dimensions*/}
                        <h1>{t('confirm_number_of_packages')}</h1>
                        <p>{t('number_of_packages')}: {tn(state.number_of_parcels)}</p>
                        <p>{t('total_weight')}: {tn(totalWeight)} {t('kg')}</p>
                        <p>{t('total_declared_value')}: ${tn(totalDeclaredValue)} USD</p>
                        <button className='cdmodalbtn' onClick={() => {
                            handleChange('total', calculatePrice())
                            steps(3)}
                        }>
                            {t('confirm_and_continue')}
                        </button>
                    </Box>
                </Fade>
            </Modal>

            {/*don't know dimensions*/}
            {/*<button className='idkbtn' onClick={() => {*/}
            {/*    // set dimensions required to false*/}
            {/*    const dimensions = { ...state.dimensions_required, required: false }*/}
            {/*    handleChange("dimensions", dimensions)*/}
            {/*    steps(7)}*/}
            {/*}>*/}
            {/*    {t('Don\'t know your item\'s dimensions?')}*/}
            {/*</button>*/}
            <br/>

            {/*back and next buttons*/}
            <button className='cdleftbtn'
                onClick={() => steps(1)}
            >
                {t('back')}
            </button>
            <button className='cdrightbtn'
                disabled={open}
                onClick={handleSubmit}
            >
                {t('continue')}
            </button>
        </div>
    )
}

export default ChooseDimensions