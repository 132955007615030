import React from 'react'
import OrderDetails from "../OrderDetails"
import CardDetails from "../CardDetails"
import {useEffect, useState} from "react"
import AddressDetails from "../AddressDetails"
import {useElements, useStripe} from "@stripe/react-stripe-js"
import {loadStripe} from "@stripe/stripe-js"
import {useUpdateOrders} from "../../../hooks/useUpdateOrders"
import {Navigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import './mpcomponents-css/confirmorder.css' //this file has the same css so i decided to be lazy and reuse it

// here, create payment intent and pay when pressed pay
// if success, create and update the orders on database and client and send email
// maybe pass in values instead of state? should be the same
const Checkout = ({ prevStep, state,
                      user, ordersNotDelivered, setOrdersNotDelivered }) => {

    const { t } = useTranslation()

    const stripe = useStripe()
    // const elements = useElements()

    // to update orders -> update paid
    const {updateOrders, error, isLoading} = useUpdateOrders()

    const [paymentSuccessful, setPaymentSuccessful] = useState(false)
    const [message, setMessage] = useState(null)
    const [isLoadingPayment, setIsLoadingPayment] = useState(false)
    const [paymentIntent, setPaymentIntent] = useState(null)
    const [goBack, setGoBack] = useState(false)

    // creates payment intent
    useEffect(() => {
        const fetchSecret = async () => {
            const response = await fetch(`https://www.luminous.delivery/api/stripe/create-payment-intent`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    id: user.id,
                    cardid: state.card.id,
                    order: state.order,
                    address: state.address
                })
            })

            const json = await response.json()

            if (response.ok) {
                setPaymentIntent(json)
            }
        }

        if (user && stripe) {
            fetchSecret()
        }
    }, [user, stripe])


    // when the payment is successful,
    // this both creates the order in the db and
    // updates the paid field of the order
    const updateOrdersClient = async () => {
        // updates on client because need to wait for confirmation
        const newOrder = state.order
        newOrder.stripe_id = paymentIntent.payment_method
        await updateOrders(user.id, newOrder, 1, null)
        // add the order in the state to the ordersNotDelivered
        // and set the state of the ordersNotDelivered
        const updatedOrdersNotDelivered = [...ordersNotDelivered]
        updatedOrdersNotDelivered.push(state.order)
        setOrdersNotDelivered(updatedOrdersNotDelivered)
    }

    // makes payment
    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!stripe || !paymentIntent) {
            // payment intent not created yet
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsLoadingPayment(true)

        stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentIntent.payment_method
        }).then(function(result) {
            if (result.error) {
                // Show error to your customer
                setMessage(result.error.message)
                console.log(result.error.message)
                setIsLoadingPayment(false)
            }
            else {
                // this would go to paymnent status page normally
                if (result.paymentIntent.status === 'succeeded') {
                    // The payment is complete!
                    // webhook will NOT be taking care of this because orderid is not a thing, order is not created!
                    // could create a temp order then delete, but that's not the logic right now
                    updateOrdersClient()
                    setMessage(t('payment_successful'))
                    setIsLoadingPayment(false)
                    setPaymentSuccessful(true)
                }
            }
        })
    }

    console.assert(state.total === state.order.price, "total is not equal to order total")

    if (paymentSuccessful) {
        // wait for 5 seconds then redirect to account
        setTimeout(() => {
            setGoBack(true)
        }, 5000)
        if (goBack) {
            return (
                <div>
                    <Navigate to='/account' replace="true"/>
                </div>
            )
        }
    }

    return (
        <div>
            <AddressDetails address={state.address} />
            <CardDetails card={state.card} />
            <OrderDetails order={state.order} priceBool={true}/>
            <h2>{t('total')}: {state.total} USD</h2>
            <h2>{t('checkout')}</h2>
            <form id="payment-form">
                <button className='confirmorderbtn' disabled={isLoadingPayment || paymentSuccessful} onClick={handleSubmit} id="submit">
                    <span id="button-text">
                        {isLoadingPayment ? <div className="spinner" id="spinner"></div> : t('pay_now')}
                    </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
                {paymentSuccessful && <div>{t('taking_you_back')}</div>}
            </form>
            <button className='cobackbtn' disabled={isLoadingPayment || paymentSuccessful} onClick={prevStep}>{t('back')}</button>
        </div>
    )
}

export default Checkout