import React, {useState} from 'react'
import Select from "react-select"
import {useTranslation} from "react-i18next";
import './mpcomponents-css/chooseaddress.css'

const ChooseAddress = ({ prevStep, nextStep, handleChange, state, values,
                           addresses, displayAddresses }) => {

    const { t } = useTranslation()

    const [inputValueAddress, setInputValueAddress] = useState(null)

    const handleChangeSelectedAddress = (selected) => {
        setInputValueAddress(selected)
        handleChange('address', addresses[selected.value])
    }

    return (
        <div>
            <h4>{t('address')}</h4>
            <Select
                className="addresses"
                options={displayAddresses && displayAddresses}
                onChange={handleChangeSelectedAddress}
                isMulti={false}
                defaultValue={inputValueAddress}
                placeholder={t('select_delivery_address')}
            />
            {/*back and next buttons*/}
            <button className='caleftbtn'
                onClick={prevStep}
            >
                {t('back')}
            </button>
            <button className='carightbtn'
                disabled={!state.address}
                onClick={nextStep}
            >
                {t('continue')}
            </button>
        </div>
    )
}

export default ChooseAddress