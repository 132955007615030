import React, {useState} from "react"
import './SignupComponents-css/Signup.css'
import Navbar from '../Navbar'
// import validator from "validator"
import { isEmail, isStrongPassword } from "../../hooks/functions/RegexCheck";
import {useTranslation} from "react-i18next"
import logo_center from '../../assets/blacklogo.svg'
import {Link} from "react-router-dom";
import logo from '../../assets/logoblack.svg'
import logo_rtl from '../../assets/logoblackrtl.svg'
import LanguageMenu from "../LanguageMenu";
import {useLocaleContext} from "../../hooks/useLocaleContext";
import toast, {Toaster} from "react-hot-toast";
import signup_icon from '../../assets/signup_icon.svg'
import box_icon from '../../assets/box_logo.png'

const UserDetails = ({ nextStep, handleChange, values, state }) => {

    const [errors, setErrors] = useState({ email: "", password: "" })

    const { locale } = useLocaleContext()
    const { t } = useTranslation()

    // check entered fields before moving forward
    const Continue = e => {
        e.preventDefault()

        toast.dismiss()

        if (state.email === "" || state.password === "") {
            toast.error(t('fill_all_fields'))
            return
        }

        let error = {}
        const correctEmail = isEmail(state.email)
        const correctPassword = isStrongPassword(state.password)

        if (correctEmail && correctPassword) {
            nextStep()
        }
        else {
            if (!correctEmail) {
                error.email = "Please enter a valid email"
                toast.error(t('enter_valid_email'))
            }
            if (correctEmail && !correctPassword) {
                error.password = "Password is not strong enough"
                toast.error(t('password_not_strong_enough'))
            }
            setErrors(error)
        }
    }

    return (
        <>
            <div className='header-signup'>
                <div className='leftItems'>
                    <Link to="/">
                        <img className='logo-login-corner' src={locale === 'ar' ? logo_rtl : logo} />
                    </Link>
                </div>
                <div className='rightItems'>
                    <div className='language-menu-login'>
                        <LanguageMenu />
                    </div>
                    <p className='login-header-text'>{t('already_a_member')}
                        <a href="/login"> {t('log_in')}</a>
                    </p>
                </div>
            </div>
            <div className='signup-div'>
                <div className='login-page'>
                    {/*<img src={logo_center} alt="logo" className="logo-login"/>*/}
                    {/*<img src={signup_icon} alt="logo" className="logo-login"/>*/}
                    <img src={box_icon} alt="logo" className="logo-login"/>
                    <div className='form'>
                        <h5 className='toptext'>{t('create_an_account')}</h5>
                        <form className='login-form'>
                            {/* email address */}
                            <p>{t('email')}</p>
                            <input
                                placeholder={t("email_address")}
                                name="email"
                                onChange={handleChange('email')}
                                defaultValue={values.email}
                                type="email"
                            />
                            {/*<p className='error'>{errors.email}</p>*/}
                            <br />
                            {/* password */}
                            {/*<h6>*/}
                            {/*    Password must contain at least 8 characters,*/}
                            {/*    1 uppercase letter, 1 lowercase letter,*/}
                            {/*    1 number, and one special character.*/}
                            {/*</h6>*/}
                            <p>{t('password')}</p>
                            <input
                                placeholder={t("password")}
                                name="password"
                                onChange={handleChange('password')}
                                defaultValue={values.password}
                                type="password"
                            />
                            {/*<p className='error'>{errors.password}</p>*/}
                            <br />
                            <button
                                onClick={ Continue }
                                type="submit"
                            >
                                {t('next')}
                            </button>

                            <p className="message">{t('already_have_account')}
                            <a href="/login"> {t('log_in')}</a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
            <Toaster />
        </>
    )
}

export default UserDetails

