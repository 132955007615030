import React from "react";

import {AccNavbar} from "../../pages/Account/account.style";

// Icons
import HomeIcon from '@mui/icons-material/Home';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PaymentIcon from '@mui/icons-material/Payment';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';

// import { Link, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {useLogout} from "../../hooks/useLogout";
import {useTranslation} from "react-i18next";


// side menu in account page
const Row = () => {

    const { logout } = useLogout()

    const { t } = useTranslation()

    const handleLogout = () => {
        logout()
    }

    let navigate = useNavigate()

    const routeChange = (url) =>{
        navigate(url)
    }

    return(
        <div>
            <AccNavbar>
                <div className="acc-row" >
                    <div className="acc-main acc-home">
                        <HomeIcon
                            className="acc-logo"
                            onClick={() => routeChange('/account')}
                        />
                    </div>
                    <div className="acc-text acc-high" >
                        <Link to="/account"
                              className='dashboard-link'
                              id='dashboard'
                        >
                            {t('dashboard')}
                        </Link>
                    </div>
                </div>
                <nav>
                    <div className="acc-row" >
                        <div className="acc-main">
                            <PermIdentityIcon
                                className="acc-logo"
                                onClick={() => routeChange('/account/profile-settings')}
                            />
                        </div>
                        <div className="acc-text">
                            <Link to="/account/profile-settings"
                                  className='dashboard-link'
                                  id='profile-settings'
                            >
                                {t('profile_settings')}
                            </Link>
                        </div>
                    </div>
                    <div className="acc-row" >
                        <div className="acc-main">
                            <PaymentIcon
                                className="acc-logo"
                                onClick={() => routeChange('/account/payment-method')}
                            />
                        </div>
                        <div className="acc-text">
                            <Link to="/account/payment-method"
                                  className='dashboard-link'
                                  id='payment-method'
                            >
                                {t('payment_method')}
                            </Link>
                        </div>
                    </div>
                    <div className="acc-row" >
                        <div className="acc-main">
                            <HistoryIcon
                                className="acc-logo"
                                onClick={() => routeChange('/account/order-history')}
                            />
                        </div>
                        <div className="acc-text">
                            <Link to="/account/order-history"
                                  className='dashboard-link'
                                  id='order-history'
                            >
                                {t('order_history')}
                            </Link>
                        </div>
                    </div>
                    <div className="acc-row">
                        <div className="acc-main">
                            <LogoutIcon
                                className="acc-logo"
                                onClick={handleLogout}
                            />
                        </div>
                        <div className="acc-text">
                            <Link to="/"
                                  className='dashboard-link'
                                  id='log-out'
                                  onClick={handleLogout}
                            >
                                {t('log_out')}
                            </Link>
                        </div>
                    </div>
                </nav>
            </AccNavbar>
        </div>
    )
}

export default Row