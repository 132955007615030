import React from "react"
import OrderDetails from "../../components/AccountComponents/OrderDetails"
import {Link, Navigate} from "react-router-dom"
import {useAuthContext} from "../../hooks/useAuthContext"
import {useEffect, useState} from "react"

import { Wrapper,AccNavbar,Content } from "./account.style"
import Navbar from '../../components/Navbar'
import Row from '../../components/AccountComponents/Row'
import {useTranslation} from "react-i18next"

const OrderHistory = () => {

    const {user} = useAuthContext()

    const { t } = useTranslation()

    // get user data
    const [orders, setOrders] = useState(null)

    // gets past orders
    useEffect(() => {
        const fetchOrders = async () => {
            const response = await fetch(`https://www.luminous.delivery/api/user/orders/${user.id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                setOrders(json)
            }
        }

        if (user) {
            fetchOrders()
        }
    }, [user])

    if (!user) {
        return (
            <div>
                <Navigate to='/signup' replace="true"/>
            </div>
        )
    }

    return (
        <div>
         <Navbar/>
          <Wrapper className="acc">
           <Row/>
              <Content>
                    <h1>{t('order_history')}</h1>
                    <div className="order-history" >
                        {orders && orders.map((order, index) => (
                            <div>
                                <OrderDetails key={order._id} order={order} index={index+1} />
                            </div>
                        ))}
                    </div>
                    <Link to="/account" className='back-to-dashboard'>{t('back')}</Link>
                </Content>
            </Wrapper>
        </div>
    )
}

export default OrderHistory