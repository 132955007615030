import React from "react"
import {useTranslation} from "react-i18next"
import {useLocaleContext} from "../hooks/useLocaleContext"


// warehouse info display format
const WarehouseDetails = ({ warehouse, user=null, nameBool=null }) => {
    const { t } = useTranslation()
    const { locale } = useLocaleContext()
    const phoneNumberEn = "(267)-881-2913"
    const phoneNumberAr = "2913-881-(267)"

    let warehouseState = warehouse.state
    if (warehouseState == null) {
        warehouseState = "N/A"
    }
    // fields we want to show to the client
    return (
        <div className="warehouse-details">
            {/* <h4>{ warehouse.name }</h4> */}
            {nameBool && user && <p><strong>{t('name')}: </strong>{user.name} {user.surname}</p>}
            <p><strong>{t('country')}: </strong>{ warehouse.country }</p>
            <p><strong>{t('state')}: </strong>{ warehouseState }</p>
            <p><strong>{t('city')}: </strong>{ warehouse.city }</p>
            <p><strong>{t('street_address_1')}: </strong>{ warehouse.address1 }</p>
            <p><strong>{t('street_address_2')}: </strong>{ warehouse.address2 }</p>
            <p><strong>{t('zipcode')}: </strong>{ warehouse.postal }</p>
            <p><strong>{t('phone')}:</strong> { warehouse.mobile }</p>
            {/*<p><strong>{t('phone')}:</strong> { locale === 'en' ? phoneNumberEn : phoneNumberAr }</p>*/}
            {/* <p><strong>Priority: </strong>{ warehouse.prio }</p> */}
            {/* <p>{ warehouse.createdAt }</p> */}
        </div>
    )
}

export default WarehouseDetails

