import validator from 'validator'

const locales = [
    'en-US',
    'ar-SA',
    // 'ar-AE',
    // 'ar-KW',
    // 'ar-OM',
    // 'ar-QA',
    // 'ar-BH',
]

export function isAlpha(str) {
    for (let i = 0; i < locales.length; i++) {
        const locale = locales[i]
        if (validator.isAlpha(str, locale)) {
            return true
        }
    }
    return false
}

// alpha with spaces
export function isAlphaSpaces(str) {
    for (let i = 0; i < locales.length; i++) {
        const locale = locales[i]
        if (validator.isAlpha(str, [locale], { ignore: ' ' })) {
            return true
        }
    }
    return false
}

export function isAlphanumeric(str) {
    for (let i = 0; i < locales.length; i++) {
        const locale = locales[i]
        if (validator.isAlphanumeric(str, locale)) {
            return true
        }
    }
    return false
}

// alphanumeric with spaces
export function isAlphanumericSpaces(str) {
    for (let i = 0; i < locales.length; i++) {
        const locale = locales[i]
        if (validator.isAlphanumeric(str, [locale], { ignore: ' ' })) {
            return true
        }
    }
    return false
}

export function isAlphanumericSpecial(str) {
    const specialCharacters = ' !@#$%^&*()_+|~-=`{}[]:;<>?,./'
    for (let i = 0; i < locales.length; i++) {
        const locale = locales[i]
        if (validator.isAlphanumeric(str, [locale], { ignore: specialCharacters })) {
            return true
        }
    }
    return false
}

export function isDecimal(str) {
    for (let i = 0; i < locales.length; i++) {
        const locale = locales[i]
        if (validator.isDecimal(str, locale)) {
            return true
        }
    }
    return false
}

export function isNumeric(str) {
    for (let i = 0; i < locales.length; i++) {
        const locale = locales[i]
        if (validator.isNumeric(str, locale)) {
            return true
        }
    }
    return false
}

export function isEmail(str) {
    return validator.isEmail(str)
}

export function isStrongPassword(str) {
    return validator.isStrongPassword(str)
}

// should accept 10 digits as well
export function isMobilePhone(str) {
    return validator.isMobilePhone(str)
}